import React from "react";
import PropTypes from "prop-types";
import { addHours, format, formatISO, isAfter, isSameHour } from "date-fns";

const MeetingRoomsTimePicker = ({ startTime, setFrom, from, setTo, to }) => {
  return (
    <div className={"p-2"}>
      <p className={"m-0 mb-1"}>
        <small>Start time → End time</small>
      </p>
      <div className="row ">
        <div className="col-6">
          <select
            className={"form-select form-select-sm"}
            value={from}
            onChange={(e) => {
              setFrom(e.target.value);
              setTo("");
            }}
          >
            <option value="" disabled>
              Start time
            </option>
            {startTime &&
              [0, 1, 2, 3, 4, 5, 6, 7].map((count) => {
                const ct = addHours(startTime, count);
                return (
                  <option value={formatISO(ct)} key={count}>
                    {format(ct, "h:mm a")}
                  </option>
                );
              })}
          </select>
        </div>
        <div className="col-6">
          <select
            className={"form-select form-select-sm"}
            value={to}
            onChange={(e) => setTo(e.target.value)}
          >
            <option value="" disabled>
              End time
            </option>
            {startTime &&
              from &&
              [1, 2, 3, 4, 5, 6, 7, 8].map((count) => {
                const ct = addHours(startTime, count);
                return (
                  <option
                    value={formatISO(ct)}
                    key={count}
                    disabled={
                      isAfter(new Date(from), ct) ||
                      isSameHour(new Date(from), ct)
                    }
                  >
                    {format(ct, "h:mm a")}
                  </option>
                );
              })}
          </select>
        </div>
      </div>
    </div>
  );
};

export default MeetingRoomsTimePicker;

MeetingRoomsTimePicker.propTypes = {
  startTime: PropTypes.instanceOf(Date),
  setFrom: PropTypes.func,
  from: PropTypes.string,
  setTo: PropTypes.func,
  to: PropTypes.string,
};

MeetingRoomsTimePicker.defualtProps = {
  date: null,
  startTime: null,
  from: null,
  to: null,
};
