import { Navigate, Route, Routes } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import LoginPage from "./pages/LoginPage";
import UserAccountPage from "./pages/UserAccountPage";
import UserBookingsPage from "./pages/UserBookingsPage";
import UserPastBookingsPage from "./pages/UserBookingsPage/PastBookings";
import BudgetLimits from "./pages/BillingPage/BudgetLimits";
import PaymentMethod from "./pages/BillingPage/PaymentMethod";
import Members from "./pages/Members";
import DashboardPage from "./pages/DashboardPage";
import HomePage from "./pages/HomePage";
import LocationProfilePage from "./pages/LocationProfilePage";
import AddCompany from "./pages/Admin/AddCompany";
import AddUser from "./pages/Admin/AddUser";
import Reports from "./pages/Reports";
// import UserCompany from "./pages/UserCompany";
import Summary from "./pages/Admin/Summary";
import DataRoom from "./pages/Admin/DataRoom";
import PostalCodeModal from "./layout/PostalCodeModal";
import MeetingRoomProfilePage from "./pages/MeetingRoomProfilePage";
// import UserPoints from "./pages/UserPoints";
import UserRewards from "./pages/Admin/UserRewards";
import AuthenticateUser from "./pages/Slack/AuthenticateUser";
import IntegrationsPage from "./pages/IntegrationsPage";
import Invoices from "./pages/BillingPage/Invoices";
import AdminInvoices from "./pages/AdminInvoices";
import TestAppNotifications from "./pages/Admin/TestAppNotifications";

const options = {
  fonts: [
    {
      src: "url(https://use.typekit.net/vjc8ttv.css)",
      family: "futura-pt",
    },
  ],
};

function App() {
  const [stripePromise, setPromise] = useState(false);
  useEffect(() => {
    loadStripe(process.env.REACT_APP_STRIPE_PK).then((res) => {
      setPromise(res);
    });
  }, []);

  if (!stripePromise) {
    return null;
  }
  return (
    <>
      <Elements stripe={stripePromise} options={options}>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route exact path="/slack-auth" element={<AuthenticateUser />} />
          <Route path="/user/account" element={<UserAccountPage />} />
          <Route path="/user/integrations" element={<IntegrationsPage />} />
          {/*<Route exact path="/user/rewards" element={<UserPoints />} />*/}
          <Route
            exact
            path="/user/bookings"
            element={<Navigate to="/user/bookings/upcoming" />}
          />
          <Route
            exact
            path="/user/bookings/upcoming"
            element={<UserBookingsPage />}
          />
          <Route
            exact
            path="/user/bookings/past"
            element={<UserPastBookingsPage />}
          />
          <Route
            exact
            path="/user/billing/budget-limits"
            element={<BudgetLimits />}
          />
          <Route
            exact
            path="/user/billing/payment-method"
            element={<PaymentMethod />}
          />
          <Route exact path="/user/billing/invoices" element={<Invoices />} />
          <Route exact path="/user/dashboard" element={<DashboardPage />} />
          <Route exact path="/user/members" element={<Members />} />
          {/*<Route exact path="/user/office-settings" element={<UserCompany />} />*/}
          <Route exact path="/reports" element={<Reports />} />
          <Route
            exact
            path="/location/:profileId/"
            element={<LocationProfilePage />}
          />
          <Route
            exact
            path="/meeting-room/:meetingRoomId/:soId/:worksimplyLocationId"
            element={<MeetingRoomProfilePage />}
          />

          <Route exact path="/admin/companies/add" element={<AddCompany />} />
          <Route
            exact
            path="/admin/companies/edit/:companyId"
            element={<AddCompany />}
          />
          <Route exact path="/admin/users/add" element={<AddUser />} />
          <Route exact path="/admin/summary" element={<Summary />} />
          <Route exact path="/admin/data-room" element={<DataRoom />} />
          <Route exact path="/admin/invoices" element={<AdminInvoices />} />
          <Route
            exact
            path="/admin/app-notifications"
            element={<TestAppNotifications />}
          />
          <Route
            exact
            path="/admin/user-rewards/:uid"
            element={<UserRewards />}
          />

          <Route path="/" element={<HomePage />} />
        </Routes>
      </Elements>
      <PostalCodeModal />
    </>
  );
}

export default App;
