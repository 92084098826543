import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../contexts/authContext";
import get from "lodash/get";
import { updateCompany } from "../../services/firebase";

const slackDevAppId = "A03V9EYARV5";
const slackProdAppId = "";

const AuthenticateUser = () => {
  const navigate = useNavigate();
  const { mvpApi, currentUser, currentUserDoc } = useAuth();

  const [searchParams] = useSearchParams();

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const code = searchParams.get("code");
    if (!code) {
      return;
    }
    if (!get(currentUser, "uid", false)) {
      const redirectTo = `${window.location.pathname}${window.location.search}`;
      navigate(`/login?redirectTo=${btoa(redirectTo)}`);
      return;
    }
    mvpApi("slack/authenticate-workspace", { code })
      .then((res) => {
        updateCompany(currentUserDoc?.companyId, {
          slackBotToken: res?.access_token || "",
          slackTeamId: res?.team?.id || "",
        })
          .then(() => {
            setSuccess(true);
            // window.close();
            const appId =
              process.env.REACT_APP_STAGE === "production"
                ? slackProdAppId
                : slackDevAppId;
            if (res?.team?.id) {
              window.location.href = `slack://app?team=${res?.team?.id}&id=${appId}`;
            } else {
              window.location.href = `slack://app`;
            }
          })
          .catch((e) => {
            console.log(e);
            setError(true);
          });
      })
      .catch((e) => {
        console.log(e);
        setError(true);
      });
  }, []);

  if (error) {
    return (
      <div>
        <div className="container text-center mt-5">
          <p>Something went wrong. Please try again.</p>
        </div>
      </div>
    );
  }

  if (success) {
    return (
      <div>
        <div className="container text-center mt-5">
          <p>Success</p>
          <p>Please open Slack App to start booking.</p>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="container text-center mt-5">
        <p>Authenticating...</p>
      </div>
    </div>
  );
};

export default AuthenticateUser;
