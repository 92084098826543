import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import get from "lodash/get";
import Header from "./Header";
import Footer from "./Footer";
import s from "./index.module.scss";
import { useAuth } from "../contexts/authContext";
import Spinner from "../components/Spinner";
// import PostalCodeModal from './PostalCodeModal';

function Layout({
  children,
  noFooter,
  title,
  fullWidth,
  pageLinks,
  profilePage,
  subTitle,
  noFooterTopMargin,
  cityFilter,
  allowedRole,
  loading,
  message = "",
}) {
  const { currentUser, claims } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!get(currentUser, "uid", false)) {
      const redirectTo = `${window.location.pathname}${window.location.search}`;
      navigate(`/login?redirectTo=${btoa(redirectTo)}`);
      return;
    }
    if (!currentUser.displayName && !claims.admin) {
      navigate("/user/account");
      return;
    }
    if (pathname.includes("/admin/") && !claims.admin) {
      navigate("/");
      return;
    }
    if (allowedRole && !allowedRole.split(",").includes(claims.role)) {
      navigate("/");
    }
  }, [currentUser]);

  if (!currentUser || !currentUser.uid) {
    return null;
  }

  return (
    <div className="min-vh-100 d-flex flex-column">
      {message && (
        <div className={"bg-danger py-3"}>
          <div className="container">
            <p className={"m-0 text-white"}>{message}</p>
          </div>
        </div>
      )}
      <Header
        title={title}
        fullWidth={fullWidth}
        subTitle={subTitle}
        cityFilter={cityFilter}
      />
      <div className={`container${fullWidth ? "-fluid" : ""}`}>
        {pageLinks.length > 0 && (
          <div className={s.nav_header}>
            {pageLinks.map(({ link, label }) => (
              <NavLink
                key={link + label}
                className={(navData) => (navData.isActive ? s.active_link : "")}
                to={link}
              >
                {label}
              </NavLink>
            ))}
          </div>
        )}
      </div>
      <div
        className={`flex-grow-1 ${
          profilePage ? "" : `container${fullWidth ? "-fluid" : ""}`
        }`}
      >
        {loading ? (
          <div className={"position-relative py-5"}>
            <Spinner />
          </div>
        ) : (
          children
        )}
      </div>
      {!noFooter && (
        <Footer
          fullWidth={fullWidth}
          profilePage={profilePage}
          noFooterTopMargin={noFooterTopMargin}
        />
      )}
    </div>
  );
}

export default Layout;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  noFooter: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  fullWidth: PropTypes.bool,
  noFooterTopMargin: PropTypes.bool,
  profilePage: PropTypes.bool,
  cityFilter: PropTypes.bool,
  pageLinks: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }),
  ),
  allowedRole: PropTypes.string,
  message: PropTypes.string,
  loading: PropTypes.bool,
};

Layout.defaultProps = {
  noFooter: false,
  title: "",
  subTitle: null,
  fullWidth: false,
  noFooterTopMargin: false,
  pageLinks: [],
  profilePage: false,
  cityFilter: false,
  allowedRole: null,
  message: "",
  loading: false,
};
