import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEmail from 'validator/lib/isEmail';
import ProgressButton from '../../components/ProgressButton';
import { useAuth } from '../../contexts/authContext';

function PasswordResetForm({ setMessage = () => null }) {
  const { resetPassword } = useAuth();
  const [email, setEmail] = useState('');
  const [progress, setProgress] = useState(false);

  const handleSubmit = async () => {
    setProgress(true);
    setMessage('');
    await resetPassword(email);
    setMessage({
      type: 'success',
      text: 'You will receive an email to reset the password.',
    });
    setProgress(false);
  };

  return (
    <form
      action="#"
      onSubmit={async (e) => {
        e.preventDefault();
        await handleSubmit();
      }}
    >
      <div className="mb-3">
        <input
          type="email"
          className="form-control"
          placeholder="Email"
          required
          onChange={(e) => {
            setEmail(e.target.value);
            setMessage('');
          }}
        />
      </div>
      <ProgressButton
        progress={progress}
        disabled={!email || !isEmail(email)}
        buttonLabel="Request"
        onClick={handleSubmit}
        fullWidth
      />

    </form>
  );
}

export default PasswordResetForm;

PasswordResetForm.propTypes = {
  setMessage: PropTypes.func.isRequired,
};
