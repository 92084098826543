import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import s from "./index.module.scss";

function Spinner({ background, small, loadingText }) {
  const [text, setText] = useState("");

  useEffect(() => {
    if (!loadingText) return;
    setText("Fetching meeting rooms");
    const timer1 = setTimeout(() => setText("Still working"), 3000);
    const timer2 = setTimeout(() => setText("Almost there"), 6000);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, []);
  return (
    <span
      className={cx({
        [s.wrapper]: true,
        [s.progress]: true,
        [s.smaller]: small,
      })}
    >
      {text && <small>{text}</small>}
      <span style={{ background }} />
      <span style={{ background }} />
      <span style={{ background }} />
    </span>
  );
}

export default Spinner;

Spinner.propTypes = {
  background: PropTypes.string,
  small: PropTypes.bool,
  loadingText: PropTypes.bool,
};

Spinner.defaultProps = {
  background: "rgba(0, 0, 0, 0.5)",
  small: false,
  loadingText: false,
};
