import React, { useEffect, useState } from "react";
import { format, subMonths } from "date-fns";
import { useAuth } from "../../contexts/authContext";
import Layout from "../../layout";
import Spinner from "../../components/Spinner";
import formatCurrency from "../../utils/formatCurrency";

const userCutOff = 10;

function Summary() {
  const { claims, mvpApi } = useAuth();

  const [monthOffset, setMonthOffset] = useState(
    format(new Date(), "dd") < 5 ? 1 : 0
  );
  const [totalCollection, setTotalCollection] = useState(0);
  const [totalServiceFee, setTotalServiceFee] = useState(0);
  const [progress, setProgress] = useState(false);
  const [totalFromUsers, setTotalFromUsers] = useState(0);

  const [data, setData] = useState([]);

  const updateData = () => {
    setProgress(true);
    const date = subMonths(new Date(), monthOffset);
    mvpApi("payment-summary", { date }).then((res) => {
      setProgress(false);
      setData(res.totalSummary || []);
      let tempT = 0;
      let tempS = 0;
      res.summary?.spaceOperators.map((d) => {
        tempT += Number(d?.total || 0);
        tempS += Number(d?.serviceFee || 0);

        return null;
      });
      setTotalCollection(tempT);
      setTotalServiceFee(tempS);
      let temp = 0;
      res?.totalSummary.map(({ total, users }) => {
        const bookingTotal = total;

        if (users >= userCutOff && bookingTotal) {
          temp = users * 10;
        }
      });
      setTotalFromUsers(temp);
    });
  };

  useEffect(() => {
    updateData();
  }, [monthOffset]);

  if (!claims.admin) {
    return null;
  }
  return (
    <Layout title="Monthly Summary" onlyAdmin>
      <div className="row">
        <div className="col-sm-6 col-lg-4">
          <select
            name="date"
            id="date"
            className="form-select"
            value={monthOffset}
            onChange={(e) => setMonthOffset(e.target.value)}
          >
            {[0, 1, 2, 3, 4].map((number) => (
              <option value={number} key={number}>
                {format(subMonths(new Date(), number), "MMMM")}
              </option>
            ))}
          </select>
        </div>
        <div className="col-sm-1 position-relative">
          {progress ? <Spinner /> : ""}
        </div>

        <div className="col-sm-12">
          <div className="table-responsive">
            {data.length > 0 && (
              <>
                <table
                  className="table my-5 table-hover table-striped table-bordered"
                  style={{ maxWidth: "500px" }}
                >
                  <tbody>
                    <tr>
                      <td>Total Transaction Value:</td>
                      <td className={"text-end"}>
                        ${(totalFromUsers + totalCollection).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Total Transaction Value from Bookings:</td>
                      <td className={"text-end"}>
                        ${totalCollection.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Total Service Fee Collected:</td>
                      <td className={"text-end"}>
                        ${totalServiceFee.toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table">
                  <thead>
                    <tr>
                      <th> </th>
                      <th>Space Operator</th>
                      <th>Total</th>
                      <th>Service Fee</th>
                      <th>To be paid to SO</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map(({ company, spaceOperators, total }, idx) => {
                      const bookingTotal = total;

                      let totalFromUsers = 0;

                      const totalCollection = bookingTotal + totalFromUsers;

                      return (
                        <React.Fragment key={idx}>
                          <tr className="table-active">
                            <td colSpan={5}>
                              <strong
                                style={{
                                  opacity: totalCollection > 0 ? 1 : 0.5,
                                }}
                              >
                                {company.name} - $
                                {totalCollection > 0
                                  ? formatCurrency(totalCollection)
                                  : "0.00"}
                              </strong>
                            </td>
                          </tr>
                          {spaceOperators.map((so, index) => (
                            <tr key={so.id + index}>
                              <td> </td>
                              <td>{so.name}</td>
                              <td>${so.total.toFixed(2)}</td>
                              <td>${so.serviceFee.toFixed(2)}</td>
                              <td>${so.toBePaidToSo.toFixed(2)}</td>
                            </tr>
                          ))}
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Summary;
