import React from "react";
import { useAuth } from "../../../../contexts/authContext";
import useQueryParams from "../../../../utils/useQueryParams";
import AllIcon from "./all.svg";
import s from "./index.module.scss";
import classNames from "classnames";

const Categories = () => {
  const { searchCategories } = useAuth();

  const [category, setCategory] = useQueryParams("category", "all");

  const [type] = useQueryParams("type", "desk");

  return (
    <div className={s.categories_wrapper}>
      <button
        className={classNames({
          btn: true,
          [s.active]: !category || category === "all",
        })}
        onClick={() => setCategory("all")}
      >
        <img src={AllIcon} alt="All Categories" />
        <span>All</span>
      </button>
      {searchCategories
        .filter((cat) => belongsTo(type, cat))
        .map((cat) => (
          <button
            className={classNames({
              btn: true,
              [s.active]: !category || category === cat.slug,
            })}
            key={cat.id}
            onClick={() => setCategory(cat.slug)}
          >
            <img src={cat.icon_url} alt={cat.name} />
            <span>{cat.name}</span>
          </button>
        ))}
    </div>
  );
};

export default Categories;

const belongsTo = (type, cat) => {
  if (!type || type === "desk") {
    return cat?.for_desks;
  }
  if (type === "office") {
    return cat?.for_offices;
  }
  if (type === "meeting-room") {
    return cat?.for_meeting_rooms;
  }
  return false;
};
