import isUUID from "validator/es/lib/isUUID";
import { format } from "date-fns";
import worksimplyApi from "../../../../services/worksimplyApi";
import adminEmail from "../../../../utils/adminEmail";

const sendNotificationEmailForWorksimplyBooking = async (booking) => {
  if (!booking.meetingRoomId || !isUUID(booking.meetingRoomId, 4)) {
    return true;
  }

  // send email
  await worksimplyApi("handle-form", {
    to: adminEmail(),
    userEmail: booking?.user_email,
    sp_slug: "worksimply",
    replyTo: booking?.user_email,
    emailTitle: `User requested to cancel the booking for ${booking?.title}`,
    emailData: [
      {
        label: "Meeting Room Name",
        value: booking?.title,
      },
      {
        label: "ID",
        value: booking?.worksimplyResId,
      },
      {
        label: "From",
        value: `${format(new Date(booking?.start), "yyyy-MM-dd h:mm a")}`,
      },
      {
        label: "To",
        value: `${format(new Date(booking?.end), "yyyy-MM-dd h:mm a")}`,
      },
    ],
  });
};

export default sendNotificationEmailForWorksimplyBooking;
