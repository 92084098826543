import React from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

function TextInput({
  value, setValue, label, type,
}) {
  return (
    <FloatingLabel
      controlId="floatingInput"
      label={label}
      className="mb-3"
    >
      <Form.Control
        type={type}
        placeholder={label}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </FloatingLabel>
  );
}

export default TextInput;

TextInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  type: PropTypes.oneOf(['text', 'number', 'email']),
  setValue: PropTypes.func.isRequired,
};

TextInput.defaultProps = {
  label: '',
  type: 'text',
};
