import React from 'react';
import PropTypes from 'prop-types';

function NoBookingsMessage({ visible = false }) {
  if (!visible) return null;
  return <span>No bookings yet...</span>;
}

export default NoBookingsMessage;

NoBookingsMessage.propTypes = {
  visible: PropTypes.bool,
};

NoBookingsMessage.defaultProps = {
  visible: false,
};
