export const chartOptions = (title = '') => ({
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: 'top',
    },
    title: {
      display: true,
      text: title,
    },
  },
  scales: {
    yAxis: {
      suggestedMin: 0,
    },
  },
});

export const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const formattedData = (data, label = 'Value') => ({
  labels,
  datasets: [
    {
      label,
      data,
      borderColor: '#008060',
      backgroundColor: '#008060',
    },
  ],

});
