import React from "react";
import Layout from "../../layout";
import AddNewCreditCard from "./CreditCards/AddNew";

function PaymentMethod() {
  return (
    <Layout
      title="Billing"
      pageLinks={[
        {
          label: "Payment Method",
          link: "/user/billing/payment-method",
        },
        {
          label: "Budget Limits",
          link: "/user/billing/budget-limits",
        },
        {
          label: "Invoices",
          link: "/user/billing/invoices",
        },
      ]}
      allowedRole="admin"
    >
      <div className="row">
        <div className="col-md-8 col-lg-6">
          <AddNewCreditCard />
        </div>
      </div>
    </Layout>
  );
}

export default PaymentMethod;
