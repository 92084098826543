import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  format,
  isSameDay,
  isValid,
  parseISO,
  setHours,
  setMinutes,
  setSeconds,
} from "date-fns";
import cx from "classnames";
import { Button } from "react-bootstrap";
import { BsChevronLeft as BackIcon } from "react-icons/bs";
import get from "lodash/get";
import resizeImage from "../../../../services/resizeImage";
import s from "./index.module.scss";
import useQueryParams from "../../../../utils/useQueryParams";
import ProgressButton from "../../../../components/ProgressButton";
import BookingBlock from "../BookingBlock";
import { useAuth } from "../../../../contexts/authContext";
import { v4 as uuid } from "uuid";
import {
  cancelDeskBooking,
  newDeskReservation,
  updateDeskReservation,
} from "../../../../services/firebase";
import worksimplyApi from "../../../../services/worksimplyApi";
import formatData from "./formatData";
import slackNotify from "../../../../services/slackNotify";
import BookingInvitation from "./BookingInvitation";

import { searchTypes } from "../../../HomePage/Search";
import hasValidPaymentMethod from "../../../../utils/hasValidPaymentMethod";
import NoValidPaymentMethod from "../../../../components/ErrorMessages/NoValidPaymentMethod";

function ReservationPage({ data, back, office }) {
  const {
    company,
    currentUser,
    updateBookingsList,
    usedCredits,
    claims,
    currentUserDoc,
    mvpApi,
    companyDeskBookingsWithUser,
  } = useAuth();

  const [errorMessage, setErrorMessage] = useState("");

  const [date] = useQueryParams("date", "");
  const [invitation] = useQueryParams("invitation", "");
  const [type] = useQueryParams("type", "desk");
  const [teamSize] = useQueryParams("teamSize", "1");

  const selectedSearchType = searchTypes.find((t) => type === t.slug);

  const decodedInvitationStr = atob(invitation);

  const decodedInvitation = decodedInvitationStr
    ? JSON.parse(decodedInvitationStr)
    : {};

  const validInvitation = get(decodedInvitation, "intendedFor", []).find(
    (uid) => uid === currentUser.uid,
  );

  const isAlreadyBooked = !!companyDeskBookingsWithUser.find((res) => {
    const isMyBooking = res.userData.uid === currentUser.uid;
    const isSameLocation = res.locationId === data.id;
    const sameDay = isSameDay(new Date(date), new Date(res.start));
    return isMyBooking && isSameLocation && sameDay;
  });

  const expiredInvitation = validInvitation && isAlreadyBooked;

  const [consent, setConsent] = useState(false);
  const [forSomeone, setForSomeone] = useState(false);
  const [otherName, setOtherName] = useState("");
  const [progress, setProgress] = useState(false);
  const [success, setSuccess] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");

  let isBookable =
    get(company, "creditsPerEmployee", null) -
      usedCredits -
      data.price_per_day_pass >=
    0;

  const isInvalidOtherPerson = forSomeone ? !otherName : false;

  if (claims.role === "admin") {
    isBookable = true;
  }

  const handleReservation = async () => {
    if (!isBookable || !hasValidPaymentMethod(company)) return;
    setErrorMessage("");
    const parsedDate = isValid(parseISO(date)) ? parseISO(date) : new Date();
    const selectedDate = setMinutes(setHours(setSeconds(parsedDate, 0), 0), 0);
    setProgress(true);

    const { reservation } = formatData(
      currentUser,
      currentUserDoc,
      company,
      data,
      null,
      office,
      forSomeone ? otherName : "",
    );

    const reservationId = await newDeskReservation(
      reservation,
      claims?.role === "user",
    );

    const uuidForWsRes = uuid();

    const { mvpApiData } = formatData(
      currentUser,
      currentUserDoc,
      company,
      data,
      uuidForWsRes,
      office,
      forSomeone ? otherName : "",
      validInvitation
        ? {
            name: decodedInvitation.name,
            email: decodedInvitation.email,
            inviteeName: currentUser.displayName || currentUser.email,
            location: data.space_name,
            date: format(selectedDate, "MMMM dd, yyyy"),
          }
        : {},
    );

    const resData = await mvpApi("pdfs/create", mvpApiData).catch(() => null);

    const confirmationPdfUrl = get(resData, "response.url", "");

    const reservationFromWs = await worksimplyApi(
      "create-reservation-for-mvp-app",
      {
        id: uuidForWsRes,
        space_id: reservation.locationId,
        office_id: office.id,
        arrival_time: get(data, "so.opening_time", "9:00 PM"),
        departure_time: get(data, "so.closing_time", "5:00 PM"),
        mvpReservationId: reservationId,
        mvpCompanyId: company.id,
        user_name: currentUser.displayName || "N/A",
        user_email: currentUser.email,
        user_phone: currentUserDoc.phone || "N/A",
        date: format(selectedDate, "yyyy-MM-dd"),
        pdf_url: confirmationPdfUrl,
        type,
        team_size: teamSize,
      },
    );

    if (!reservationFromWs?.reservation?.id) {
      // failed reservation.
      setErrorMessage(
        reservationFromWs?.error ||
          "Failed to create the booking. Please contact administrator support",
      );
      // remove the booking from firebase
      await cancelDeskBooking(reservationId);
      setProgress(false);
      return;
    }

    await updateDeskReservation(reservationId, {
      confirmationPdfUrl,
      worksimplyResId: get(reservationFromWs, "reservation.id", "n/a"),
    });

    setPdfUrl(confirmationPdfUrl);
    setProgress(false);
    setSuccess(true);
    await updateBookingsList();
    try {
      await slackNotify(
        `<${confirmationPdfUrl}|${`New ${type} booking`}> from ${
          company.name
        } for ${data.space_name} via app for $${(
          reservationFromWs?.reservation?.total / 100
        ).toFixed(2)}`,
      );
    } catch (e) {
      console.log(e);
    }
  };

  if (success) {
    return (
      <div className="container my-5">
        <div className="row">
          <div className="col-md-10 col-lg-9 col-xl-8 col-xxl-7">
            <h1>Booking confirmed!</h1>
            <p className="my-3">
              Please download the confirmation PDF and show it to the community
              manager upon arrival. If you have any questions please send us an
              email to hello@worksimply.com
            </p>
            {pdfUrl && (
              <a
                href={pdfUrl}
                target="_blank"
                className="btn btn-primary my-2 me-2"
                rel="noreferrer"
              >
                Download confirmation PDF
              </a>
            )}
            <Link
              to="/user/bookings/upcoming"
              className="btn btn-secondary my-2"
            >
              View my bookings
            </Link>
          </div>
        </div>

        {type === "desk" && <BookingInvitation data={data} />}
      </div>
    );
  }

  return (
    <div className="container">
      <Button
        variant="default"
        className="p-0"
        onClick={back}
        style={{ marginLeft: "-8px" }}
      >
        <BackIcon size="30px" />
      </Button>

      <br />
      <br />

      <div className="row">
        <div className="col-lg-8 col-xl-7">
          <h1>Confirm and book</h1>

          {invitation && validInvitation && (
            <p
              className={cx("my-4 py-2", {
                "text-secondary": !isAlreadyBooked,
                "text-warning": isAlreadyBooked,
              })}
            >
              {isAlreadyBooked
                ? "You have already accepted this invitation"
                : `You have been invited by ${get(
                    decodedInvitation,
                    "name",
                    get(decodedInvitation, "email", "a colleague"),
                  )} to book this workspace`}
            </p>
          )}

          <div className="my-3 d-md-flex">
            <img
              className={s.thumbnail}
              src={resizeImage(data.hero_image_url, 300)}
              alt=""
            />
            <div className="py-3">
              <h4>{data.space_name}</h4>
              <p>{data.office_address.address}</p>
            </div>
          </div>

          <hr />

          <ul className={s.summary_list}>
            <li>
              <span>Type</span>
              <span>{get(selectedSearchType, "label", "Day Passes")}</span>
            </li>
            <li>
              <span>Date</span>
              <span>
                {date
                  ? format(new Date(date), "MMMM d, yyyy")
                  : "Please select"}
              </span>
            </li>
            <li>
              <span>Check-in time</span>
              <span>From {get(data, "so.opening_time", "9:00 AM")}</span>
            </li>
            <li>
              <span>Check-out time</span>
              <span>{get(data, "so.closing_time", "5:00 PM")}</span>
            </li>
          </ul>

          <hr />

          <div className="d-lg-none pt-4 pb-2">
            <BookingBlock noButton data={data} />
            <br />
          </div>

          <div className={s.cancellation_policy}>
            <h4>Cancellation policy</h4>
            <p>
              Cancel up to{" "}
              {get(data, "so.cancellation_deadline_in_hours", "48")} hours prior
              to the booking. You can cancel the bookings from your{" "}
              <Link to="/user/bookings/upcoming" target={"_blank"}>
                bookings
              </Link>{" "}
              page.
            </p>
          </div>

          {claims.role === "admin" && (
            <>
              <hr />

              <div className="mb-3">
                <p
                  role="presentation"
                  onClick={() => setForSomeone(!forSomeone)}
                  className="d-inline-block m-0"
                  style={{ cursor: "pointer" }}
                >
                  Booking on behalf of <u>someone else?</u>
                </p>
              </div>

              {forSomeone && (
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    value={otherName}
                    onChange={(e) => setOtherName(e.target.value)}
                    placeholder="Please enter the name of the other person"
                  />
                </div>
              )}
            </>
          )}

          <hr />

          <div className="form-check">
            <input
              type="checkbox"
              id="consent"
              className="form-check-input"
              checked={consent}
              onChange={() => setConsent(!consent)}
            />
            <label
              title="consent"
              htmlFor="consent"
              className="form-check-label"
            >
              By selecting the button below, I agree to the{" "}
              <a
                href="https://worksimply.com/terms-of-use"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Use
              </a>{" "}
              and Cancellation Policy.
            </label>
          </div>

          <br />

          {!isBookable && (
            <p className=" d-block mb-3 text-danger">
              You don&apos;t have enough credits for this booking.
            </p>
          )}

          {errorMessage && (
            <p className=" d-block mb-3 text-danger">{errorMessage}</p>
          )}
          <NoValidPaymentMethod />
          <ProgressButton
            buttonLabel="Confirm and book"
            progress={progress}
            disabled={
              !consent ||
              !isBookable ||
              isInvalidOtherPerson ||
              expiredInvitation ||
              !hasValidPaymentMethod(company)
            }
            onClick={handleReservation}
          />

          <br />
          <br />
          <br />
        </div>
        <div className="col-lg-4 offset-xl-1 d-none d-lg-block">
          <BookingBlock
            noButton
            data={data}
            readOnly
            showTentativeArrivalTime
            invited={validInvitation}
          />
        </div>
      </div>
    </div>
  );
}

export default ReservationPage;

ReservationPage.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    space_name: PropTypes.string.isRequired,
    hero_image_url: PropTypes.string.isRequired,
    space_description: PropTypes.string.isRequired,
    price_per_day_pass: PropTypes.number.isRequired,
    office_address: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
      address: PropTypes.string.isRequired,
    }),
    on_demand_instructions: PropTypes.string,
    so: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  back: PropTypes.func,
  office: PropTypes.shape({
    id: PropTypes.string,
    price_per_day: PropTypes.number,
    price_per_hour: PropTypes.number,
  }),
  isMeetingRoomCheckout: PropTypes.bool,
};

ReservationPage.defaultProps = {
  back: () => null,
  office: {
    id: "",
    price_per_day: 0,
    price_per_hour: 0,
  },
  isMeetingRoomCheckout: false,
};
