import { initializeApp } from "firebase/app";
import { getAuth, sendPasswordResetEmail, updateProfile } from "firebase/auth";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  endOfMonth,
  formatISO,
  isBefore,
  parseISO,
  startOfMonth,
} from "date-fns";
import getDefaultDate from "../../utils/getDefaultDate";
import sendToSlack from "../sendToSlack";
import { rewardPointsRate } from "../../config";

const app = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
});

export const auth = getAuth();

export default app;

export const db = getFirestore();

export const companyRef = collection(db, "companies");

export const addCompany = async (data) =>
  addDoc(companyRef, {
    ...data,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  })
    .then(() => null)
    .catch(() => null);

export const updateCompany = async (id, data) =>
  updateDoc(doc(db, "companies", id), data)
    .then(() => true)
    .catch((e) => {
      sendToSlack(`Error updating the company - ${e}`);
      return false;
    });

export const getCompanies = async () => {
  const companyQuery = query(companyRef, orderBy("name", "asc"));

  return getDocs(companyQuery).then((snapshot) => {
    const data = [];
    snapshot.docs.forEach((snapDocument) => {
      data.push({ ...snapDocument.data(), id: snapDocument.id });
    });
    return data;
  });
};

export const getCompany = async (id) => {
  const docRef = doc(db, "companies", id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return {
      ...docSnap.data(),
      id: docSnap.id,
    };
  }
  return {};
};

export const monthlySummaryRef = collection(db, "monthlySummary");

export const updateMonthlyData = async (id, data) =>
  updateDoc(doc(db, "monthlySummary", id), data)
    .then(() => null)
    .catch(() => null);

export const getAllMonthlyData = async () => {
  const customQuery = query(monthlySummaryRef);

  return getDocs(customQuery).then((snapshot) => {
    const data = [];
    snapshot.docs.forEach((snapDocument) => {
      data.push({ ...snapDocument.data(), id: snapDocument.id });
    });
    return data;
  });
};

export const userRef = collection(db, "users");
export const addUser = async (id, data) =>
  setDoc(doc(db, "users", id), data)
    .then(() => null)
    .catch(() => null);

export const getUserDoc = async (id) => {
  const docRef = doc(db, "users", id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  }
  return {};
};

export const deleteUser = async (userId) =>
  deleteDoc(doc(db, "users", userId))
    .then(() => true)
    .catch(() => true);

export const getUsersOfCompany = async (companyId) => {
  const userQuery = query(userRef, where("companyId", "==", companyId));

  return getDocs(userQuery).then((snapshot) => {
    const data = [];
    snapshot.docs.forEach((snapDocument) => {
      data.push({ ...snapDocument.data(), id: snapDocument.id });
    });
    return data;
  });
};

export const getUsers = async () => {
  const userQuery = query(userRef, orderBy("companyId", "asc"));

  return getDocs(userQuery).then((snapshot) => {
    const data = [];
    snapshot.docs.forEach((snapDocument) => {
      data.push({ ...snapDocument.data(), id: snapDocument.id });
    });
    return data;
  });
};

export const updateCustomUserData = async (uid, data) =>
  updateDoc(doc(db, "users", uid), data)
    .then(() => true)
    .catch(() => false);

export const updateUserProfile = async (data) =>
  updateProfile(auth.currentUser, data);

export const updateUserPassword = async (email) =>
  sendPasswordResetEmail(auth, email);

export const getCompanyDoc = async (id) => {
  const docRef = doc(db, "companies", id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return {
      ...docSnap.data(),
      id: docSnap.id,
    };
  }
  return {};
};

export const rewardPointsRef = collection(db, "rewardPoints");

export const deskReservationRef = collection(db, "reservations");

export const newDeskReservation = async (data, isEmployee = false) => {
  const reservationId = await addDoc(deskReservationRef, {
    ...data,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  })
    .then(async (docRef) => {
      return docRef.id;
    })
    .catch(() => false);

  if (reservationId && isEmployee) {
    try {
      await addDoc(rewardPointsRef, {
        userId: data?.userId,
        reservationId,
        points: data?.price * rewardPointsRate,
        effectiveDate: data?.start,
        isActive: true,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
    } catch (e) {
      console.log(e);
    }
  }

  return reservationId;
};

export const addRewardPointsToUser = async (
  userId,
  reservationId,
  points,
  effectiveDate
) => {
  await addDoc(rewardPointsRef, {
    userId,
    reservationId,
    points,
    effectiveDate,
    isActive: true,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });
};

export const updateDeskReservation = async (id, data) =>
  updateDoc(doc(db, "reservations", id), data)
    .then(() => null)
    .catch(() => null);

export const getMyBookings = async (userId) => {
  const customQuery = query(
    deskReservationRef,
    where("userId", "==", userId),
    orderBy("start")
  );

  return getDocs(customQuery).then((snapshot) => {
    const data = [];
    snapshot.docs.forEach((snapDocument) => {
      data.push({ ...snapDocument.data(), id: snapDocument.id });
    });
    return data;
  });
};

export const pointsRef = collection(db, "rewardPoints");
export const redeemsRef = collection(db, "rewardRedeems");

export const deleteRedeem = async (id) => {
  return await deleteDoc(doc(db, "rewardRedeems", id))
    .then(() => true)
    .catch(() => null);
};

export const addRedeem = async (userId, points) =>
  addDoc(redeemsRef, {
    userId,
    points,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  })
    .then(() => null)
    .catch(() => null);

export const getMyPoints = async (userId) => {
  let points = 0;
  let redeemablePoints = 0;
  let redeems = 0;

  const customPointsQuery = query(
    pointsRef,
    where("userId", "==", userId),
    where("isActive", "==", true)
  );

  await getDocs(customPointsQuery).then((snapshot) => {
    snapshot.docs.forEach((snapDocument) => {
      const effectiveDate = new Date(snapDocument.data().effectiveDate);
      if (isBefore(effectiveDate, new Date())) {
        redeemablePoints += snapDocument.data()?.points || 0;
      }
      points += snapDocument.data()?.points || 0;
    });
  });

  const customRedeemsQuery = query(redeemsRef, where("userId", "==", userId));

  await getDocs(customRedeemsQuery).then((snapshot) => {
    snapshot.docs.forEach((snapDocument) => {
      redeems += snapDocument.data()?.points || 0;
    });
  });

  return {
    allPoints: points - redeems,
    redeemablePoints: redeemablePoints - redeems,
  };
};

export const getUserRewardData = async (userId) => {
  let points = [];
  let redeems = [];

  const customPointsQuery = query(pointsRef, where("userId", "==", userId));

  await getDocs(customPointsQuery).then((snapshot) => {
    snapshot.docs.forEach((snapDocument) => {
      points.push({ ...snapDocument.data(), id: snapDocument.id });
    });
  });

  const customRedeemsQuery = query(redeemsRef, where("userId", "==", userId));

  await getDocs(customRedeemsQuery).then((snapshot) => {
    snapshot.docs.forEach((snapDocument) => {
      redeems.push({ ...snapDocument.data(), id: snapDocument.id });
    });
  });

  return { points, redeems };
};

export const cancelDeskBooking = async (bookingId) => {
  const customPointsQuery = query(
    pointsRef,
    where("reservationId", "==", bookingId)
  );

  let rewardId = null;

  await getDocs(customPointsQuery).then((snapshot) => {
    snapshot.docs.forEach((snapDocument) => {
      rewardId = snapDocument.id;
    });
  });

  await deleteDoc(doc(db, "reservations", bookingId))
    .then(() => true)
    .catch(() => null);

  if (rewardId) {
    await deleteDoc(doc(db, "rewardPoints", rewardId))
      .then(() => true)
      .catch(() => null);
  }
};

export const getAllRewardPoints = async () => {
  const userQuery = query(rewardPointsRef);

  return getDocs(userQuery).then((snapshot) => {
    const data = [];
    snapshot.docs.forEach((snapDocument) => {
      data.push({ ...snapDocument.data(), id: snapDocument.id });
    });
    return data;
  });
};

export const getAllCompanyDeskBookings = async () => {
  const userQuery = query(
    deskReservationRef,
    orderBy("start", "asc"),
    where("start", ">=", formatISO(new Date("2022-08-01")))
  );

  return getDocs(userQuery).then((snapshot) => {
    const data = [];
    snapshot.docs.forEach((snapDocument) => {
      data.push({ ...snapDocument.data(), id: snapDocument.id });
    });
    return data;
  });
};

export const getCompanyDeskBookings = async (companyId) => {
  if (!companyId) return [];
  const userQuery = query(
    deskReservationRef,
    where("companyId", "==", companyId),
    where("start", ">=", formatISO(getDefaultDate())),
    orderBy("start", "asc")
  );

  return getDocs(userQuery).then((snapshot) => {
    const data = [];
    snapshot.docs.forEach((snapDocument) => {
      data.push({ ...snapDocument.data(), id: snapDocument.id });
    });
    return data;
  });
};

export const getCompanyDeskBookingsForThisMonth = async (
  companyId,
  yearAndMonth
) => {
  if (!companyId) return [];
  const pickedDate = `${yearAndMonth}-15`;
  const monthStart = startOfMonth(new Date(pickedDate));
  const monthEnd = endOfMonth(new Date(pickedDate));
  const userQuery = query(
    deskReservationRef,
    where("companyId", "==", companyId),
    where("start", ">=", formatISO(monthStart)),
    orderBy("start", "asc")
  );

  return getDocs(userQuery).then((snapshot) => {
    const data = [];
    snapshot.docs.forEach((snapDocument) => {
      const d = snapDocument.data();
      if (isBefore(parseISO(d.start), monthEnd)) {
        data.push({ ...snapDocument.data(), id: snapDocument.id });
      }
    });
    return data;
  });
};
