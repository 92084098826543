import React, { useEffect } from "react";
import { BiSearch as SearchIcon } from "react-icons/bi";
import useQueryParams from "../../utils/useQueryParams";
import s from "./CityFilter.module.scss";
import { useAuth } from "../../contexts/authContext";

export const cities = [
  {
    label: "Toronto & GTA",
    value: "Toronto",
    tz: "America/Toronto",
  },
  { label: "Kitchener-Waterloo", value: "Kitchener", tz: "America/Toronto" },
  { label: "Ottawa", value: "Ottawa", tz: "America/Toronto" },
  { label: "Calgary", value: "Calgary", tz: "America/Edmonton" },
  { label: "Montreal", value: "Montreal", tz: "America/Toronto" },
  { label: "Kelowna", value: "Kelowna", tz: "America/Vancouver" },
  { label: "Vancouver", value: "Vancouver", tz: "America/Vancouver" },
];

function CityFilter() {
  const { currentUserDoc } = useAuth();

  const [city, setCity] = useQueryParams("city", "");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (!params.get("city")) return;
    if (!cities.find((c) => c.value === params.get("city"))) {
      window.location.href = "/";
    }
  }, [currentUserDoc, window.location.search]);

  useEffect(() => {
    if (
      !currentUserDoc ||
      !currentUserDoc.city ||
      typeof window === "undefined"
    )
      return;
    const params = new URLSearchParams(window.location.search);
    if (params.get("city")) return;
    setCity(currentUserDoc.city);
  }, [currentUserDoc, window.location.search]);
  return (
    <div className="mx-auto">
      <div className={s.wrapper}>
        <select
          name=""
          id=""
          value={city}
          onChange={(e) => setCity(e.target.value)}
        >
          {cities.map(({ label, value }) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </select>
        <button type="button" className={`btn btn-primary p-0 ${s.button}`}>
          <SearchIcon />
        </button>
      </div>
    </div>
  );
}

export default CityFilter;
