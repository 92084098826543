import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import get from 'lodash/get';
import Layout from '../../../layout';
import ManualData from './ManualData';
import { getAllMonthlyData } from '../../../services/firebase';
import { chartOptions, labels, formattedData } from './utils';

const categories = ['General', 'Demand SaaS', 'Supply SaaS'];

const chartHandles = [{
  label: 'Monthly Recurring Revenue MRR',
  id: 'mrr',
  category: 0,
}, {
  label: 'Forecasted MRR based on users and assumptions',
  id: 'forecastedMrr',
  category: 0,
}, {
  label: 'Monthly Revenue',
  id: 'monthlyRevenue',
  category: 0,
}, {
  label: 'Active users month over month',
  id: 'demandSaas.newUsers',
  category: 1,
}, {
  label: 'Total bookings month over month',
  id: 'demandSaas.totalBookings',
  category: 1,
}, {
  label: 'Transaction value month over month',
  id: 'demandSaas.totalEarnings',
  category: 1,
}, {
  label: 'Total bookings month over month',
  id: 'supplySaas.totalBookings',
  category: 2,
}, {
  label: 'Total transaction value month over month',
  id: 'supplySaas.totalEarnings',
  category: 2,
}];

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

// const data = {
//   labels,
//   datasets: [
//     {
//       label: 'Dataset 1',
//       data: [1, 2],
//       borderColor: '#008060',
//       backgroundColor: '#008060',
//     },
//   ],
// };

function DataRoom() {
  const [monthlySummaryData, setMsd] = useState([]);
  const [chartData, setChartData] = useState({});

  const fetchData = () => {
    getAllMonthlyData().then((res) => {
      setMsd(res);
      const tempSummaryData = {};
      let totalUsers = 0;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < labels.length; i++) {
        const item = res.find((summary) => i + 1 === Number(summary.id.split('_')[1]));
        // eslint-disable-next-line no-restricted-syntax
        for (const handle of chartHandles) {
          const isUsers = handle.id === 'demandSaas.newUsers';
          if (isUsers) {
            if (item) {
              totalUsers += get(item, handle.id, 0);
            }
          }
          tempSummaryData[handle.id] = [
            ...get(tempSummaryData, handle.id, []),
            isUsers && item ? totalUsers : get(item, handle.id, null),
          ];
        }
      }
      setChartData(tempSummaryData);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Layout title="Data Room">
      <ManualData data={monthlySummaryData} refresh={fetchData} />

      {categories.map((category, idx) => (
        <div className="row" key={category}>
          <div className="col-12"><h4 className="text-center mt-5 mb-0">{category}</h4></div>

          {chartHandles.map(({ id, label, category: cat }) => {
            if (cat !== idx) return null;
            return (
              <div className="col-sm-6 mt-5" key={id}>
                <Line options={chartOptions(label)} data={formattedData(chartData[id])} />
              </div>
            );
          })}
        </div>
      ))}

      <div className="row mb-5" />

    </Layout>
  );
}

export default DataRoom;
