import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import TagManager from "react-gtm-module";
import FullStory from "react-fullstory";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { createRoot } from "react-dom/client";

import { AuthProvider } from "./contexts/authContext";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "react-18-image-lightbox/style.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "./styles/bootstrap/index.scss";
import "./styles/react-datepicker.scss";
import "./styles/global-styles.scss";

const tagManagerArgs = {
  gtmId: "GTM-PWVM96V",
};

TagManager.initialize(tagManagerArgs);

Sentry.init({
  dsn: "https://270a68746da94cb6b0676929dccca490@o313230.ingest.sentry.io/6294529",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.5,
});

const container = document.getElementById("root");
const root = createRoot(container);

function AppContainer() {
  return (
    <>
      <FullStory org="NHXAH" />
      <HelmetProvider>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
          theme="colored"
        />
        <Router>
          <AuthProvider>
            <App />
          </AuthProvider>
        </Router>
      </HelmetProvider>
    </>
  );
}
root.render(<AppContainer />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
