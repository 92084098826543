import React from "react";
import {
  addBusinessDays,
  format,
  formatISO,
  isSameDay,
  isValid,
  parseISO,
} from "date-fns";
import { Button } from "react-bootstrap";
import groupBy from "lodash/groupBy";
import { useNavigate } from "react-router-dom";
import s from "../../index.module.scss";
import useQueryParams from "../../../../../utils/useQueryParams";
import getDefaultDate from "../../../../../utils/getDefaultDate";
import { useAuth } from "../../../../../contexts/authContext";
import BookingsForTheDay from "./BookingsForTheDay";
import NoBookingsMessage from "./NoBookingsMessage";
// import getProfileImage from '../../../../../utils/getProfileImage';

function DateView() {
  const navigate = useNavigate();
  const { companyDeskBookings: allBookings } = useAuth();
  const [date] = useQueryParams("date", formatISO(getDefaultDate(1)));
  return (
    <div>
      <div className="row my-5 pt-4">
        {[0, 1, 2, 3, 4].map((number) => {
          const dateFromFilter = parseISO(date);
          if (!isValid(dateFromFilter)) return null;

          const currentDate = addBusinessDays(dateFromFilter, number);

          const bookings = allBookings.filter((b) =>
            isSameDay(new Date(b.start), currentDate)
          );

          const groupedBookings = Object.values(
            groupBy(bookings, "locationId")
          );

          return (
            <div key={number} className="d-flex col-md-6 col-xl-4">
              <div className={s.main_office_block}>
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div>
                    <h4 className="m-0">
                      {format(currentDate, "EEEE ")}-
                      {format(currentDate, " MMMM d, yyyy")}
                    </h4>
                  </div>
                  <div className="ms-auto">
                    <Button
                      onClick={async () => {
                        const params = new URLSearchParams(
                          window.location.search
                        );
                        params.set("date", formatISO(currentDate));
                        params.delete("date_view");
                        await navigate(
                          `${window.location.pathname}?${params.toString()}`
                        );
                      }}
                      variant="secondary"
                    >
                      Book workspace
                    </Button>
                  </div>
                </div>

                <hr />

                <NoBookingsMessage visible={bookings.length === 0} />

                <BookingsForTheDay
                  bookings={groupedBookings}
                  date={currentDate}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default DateView;
