import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import Layout from '../../layout';
import { useAuth } from '../../contexts/authContext';
import ProgressButton from '../../components/ProgressButton';
import { addCompany, getCompany, updateCompany } from '../../services/firebase';
import Spinner from '../../components/Spinner';
import FileInput from '../../components/FileInput';
import s from './styles.module.scss';
import worksimplyApi from '../../services/worksimplyApi';

function AddCompany() {
  const navigate = useNavigate();
  const { companyId } = useParams();
  const { claims } = useAuth();

  const [progress, setProgress] = useState(false);
  const [company, setCompany] = useState({});
  const [sos, setSos] = useState([]);

  const [name, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [logo, setLogo] = useState('');
  const [chargeOnlyForBookings, setChargeOnlyForBookings] = useState(false);
  const [trialEndDate, setTrialEndDate] = useState('');
  const [allowedLocations, setAllowedLocations] = useState([]);

  const handleCompanyImage = async (newLogo) => {
    setLogo(newLogo);
    await updateCompany(companyId, { logo: newLogo });
  };

  const handleAdd = async () => {
    setProgress(true);
    if (companyId) {
      await updateCompany(companyId, {
        name, slug, allowedLocations, chargeOnlyForBookings, trialEndDate,
      });
    } else {
      await addCompany({
        name, slug, allowedLocations, chargeOnlyForBookings, trialEndDate,
      });
    }
    setProgress(false);
    navigate('/');
  };

  const toggleLocation = (selectedLocation) => {
    const currentAllowedLocations = allowedLocations || [];
    let updatedLocations = [];
    if (currentAllowedLocations.find((f) => f.id === selectedLocation.id)) {
      updatedLocations = currentAllowedLocations.filter((el) => el.id !== selectedLocation.id);
    } else {
      updatedLocations = [...currentAllowedLocations, selectedLocation];
    }
    setAllowedLocations([...updatedLocations]);
  };

  useEffect(() => {
    if (!companyId || company.id) {
      return;
    }
    worksimplyApi('list-space-operators').then((res) => {
      setSos(res.list.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }));
    });
    getCompany(companyId).then((res) => {
      setCompany(res);
      setName(res.name || '');
      setSlug(res.slug || '');
      setLogo(res.logo || '');
      setTrialEndDate(res.trialEndDate || '');
      setChargeOnlyForBookings(res.chargeOnlyForBookings || false);
      setAllowedLocations(res.allowedLocations || []);
    });
  }, [companyId]);

  if (!claims.admin) {
    return null;
  }

  if (companyId && !company.id) {
    return (
      <Layout title="Add Company" onlyAdmin>
        <div className="position-relative">
          <Spinner />
        </div>
      </Layout>
    );
  }

  return (
    <Layout
      title={companyId ? 'Update Company' : 'Add Company'}
    >
      <div className="row mb-5">
        <div className="col-lg-6">
          <div className="mb-3">
            <p className="mb-1">Company Name</p>
            <input
              type="text"
              className="form-control"
              placeholder="Company Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <p className="mb-1">
              Slug
              {' '}
              <small className="text-muted">(Lower case letters and hyphens only)</small>
            </p>
            <input
              type="text"
              className="form-control"
              placeholder="Slug"
              value={slug}
              onChange={(e) => setSlug(e.target.value)}
            />
          </div>
        </div>

        <div className="col-lg-6">
          <p className="mb-1">
            Trial Ends on:
          </p>
          <input
            type="date"
            className="form-control mb-3"
            placeholder=""
            value={trialEndDate}
            onChange={(e) => setTrialEndDate(e.target.value)}
          />
          <p className="mb-1">
            Charge only for Bookings?
          </p>
          <Form.Check
            type="switch"
            id="chargeOnlyForBookings"
            checked={chargeOnlyForBookings}
            onChange={(e) => {
              setChargeOnlyForBookings(e.target.checked);
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">

          {companyId && (
            <>
              <div className="mb-3">
                <p className="mb-1">
                  Company Logo
                </p>
                <p><small>Only PNG files are allowed. Dimensions: 240 x 100 px</small></p>
                <div className={s.company_logo}>
                  <FileInput
                    file={logo}
                    setFile={handleCompanyImage}
                    accept="image/png"
                    maxHeight={134}
                    maxWidth={240}
                  />
                </div>
              </div>

              <div className="mb-3">
                <p className="mb-1">Select the SOs</p>
                <Button
                  variant="light"
                  size="sm"
                  onClick={() => {
                    const locations = [];
                    sos.map((so) => so.spaces.map((space) => {
                      const temp = {
                        id: space.id,
                        space_name: space.space_name,
                      };
                      locations.push(temp);
                      return temp;
                    }));
                    setAllowedLocations(locations);
                  }}
                >
                  Select All
                </Button>
                <Button
                  variant="light"
                  size="sm"
                  onClick={() => {
                    setAllowedLocations([]);
                  }}
                >
                  Deselect All
                </Button>
                {sos.map((so) => (
                  <div key={so.id}>
                    <p className="mt-3 mb-1"><strong className="text-muted">{so.name}</strong></p>
                    {so.spaces.map((space) => (
                      <Form.Check
                        type="switch"
                        key={space.id}
                        id={`location_${space.id}`}
                        checked={!!(allowedLocations || []).find((location) => {
                          const { id: locationId } = location;
                          return locationId === space.id;
                        })}
                        label={space.space_name}
                        onChange={() => toggleLocation(space)}
                      />
                    ))}
                  </div>
                ))}

              </div>
            </>
          )}

          <ProgressButton
            buttonLabel={companyId ? 'Update' : 'Add'}
            progress={progress}
            onClick={handleAdd}
            disabled={!name || !slug}
          />
        </div>
      </div>
    </Layout>
  );
}

export default AddCompany;
