import React from "react";
import { useAuth } from "../../contexts/authContext";
import hasValidPaymentMethod from "../../utils/hasValidPaymentMethod";

const NoValidPaymentMethod = () => {
  const { company } = useAuth();

  if (hasValidPaymentMethod(company)) return null;

  return (
    <p className=" d-block mb-3 text-danger">
      It seems that your company doesn’t have a credit card on file. Please ask
      your administrator to add one and try again.
    </p>
  );
};

export default NoValidPaymentMethod;
