import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import { formatISO } from 'date-fns';
import { useAuth } from '../../../../../contexts/authContext';
import getProfileImage from '../../../../../utils/getProfileImage';
import s from './BookingsForTheDay.module.scss';

function BookingsForTheDay({ bookings, date }) {
  const { companyUsers, currentUser } = useAuth();
  if (!bookings || bookings.length === 0) return null;

  return (
    <>
      {bookings.map((items) => {
        const locationId = get(items, '[0].locationId', '');
        const locationTitle = get(items, '[0].title', 'N/A');
        const uniqueUsers = uniqBy(items, 'userId');
        const image = get(items, '[0].image', '');
        const allowedDayPassesPerDay = get(items, '[0].allowedDayPassesPerDay', 0) || 4;
        let checkoutEnabled = false;
        if (allowedDayPassesPerDay === 0) {
          checkoutEnabled = true;
        } else if (allowedDayPassesPerDay > items.length) {
          checkoutEnabled = true;
        }
        return (
          <div key={locationId}>
            <small>Teammates working at</small>
            <div className="d-flex align-items-center justify-content-between mt-1 mb-2">
              <h6 className="m-0">{locationTitle}</h6>
              {checkoutEnabled ? (
                <Link
                  to={`/location/${locationId}?date=${
                    encodeURIComponent(formatISO(date))}&expressCheckout=✓`}
                  className="text-secondary"
                >
                  Express Checkout
                </Link>
              ) : <span className="text-muted">Maxed out</span>}
            </div>
            <div className="row">
              <div className="col-4">
                <img
                  className="d-block w-100"
                  src={image}
                  alt=""
                />
              </div>
              <div className="col-8">
                <div className="row">
                  {uniqueUsers.map((booking) => {
                    const { userId } = booking;
                    const isMe = userId === get(currentUser, 'uid', '');
                    const user = companyUsers.find((u) => u.uid === get(booking, 'userId', ''));
                    const name = get(user, 'name', '').split(' ')[0];
                    return (
                      <div className="col-6" key={userId}>
                        <div className={s.userName}>
                          {getProfileImage(user || {})}
                          <small>
                            {isMe ? 'Me' : name || 'Not provided'}
                          </small>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default BookingsForTheDay;

BookingsForTheDay.propTypes = {
  bookings: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
    locationId: PropTypes.string,
  }))),
  date: PropTypes.instanceOf(Date).isRequired,
};

BookingsForTheDay.defaultProps = {
  bookings: [],
};
