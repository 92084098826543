import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { formatISO } from "date-fns";
import mapStyles from "./mapStyles";
import MapMarker from "./map-marker.svg";
import { useAuth } from "../../contexts/authContext";
import s from "./index.module.scss";
import useQueryParams from "../../utils/useQueryParams";
import getDefaultDate from "../../utils/getDefaultDate";
import filterBookingsForTheLocation from "../../utils/FilterBookingsForTheLocation";
import HomeMarker from "./home_marker.png";

const K_CIRCLE_SIZE = 200;
const K_STICK_SIZE = 220;

function mapOptionsCreator(map) {
  return {
    zoomControlOptions: {
      position: map.ControlPosition.LEFT_TOP,
    },
    styles: mapStyles,
  };
}

const defaultCenter = { lat: 43.6508797, lng: -79.3834781 };

function GoogleMap({ children, center, zoom }) {
  const distanceToMouse = (markerPos, mousePos, markerProps) => {
    const { x } = markerPos;
    const y = markerPos.y - K_STICK_SIZE - K_CIRCLE_SIZE / 2;

    const distanceKoef = markerProps.text !== "A" ? 1.5 : 1;

    return (
      distanceKoef *
      Math.sqrt(
        (x - mousePos.x) * (x - mousePos.x) +
          (y - mousePos.y) * (y - mousePos.y)
      )
    );
  };

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
      hoverDistance={30}
      distanceToMouse={distanceToMouse}
      options={mapOptionsCreator}
      defaultCenter={defaultCenter}
      defaultZoom={11}
      center={center}
      zoom={zoom}
    >
      {children}
    </GoogleMapReact>
  );
}

function WrapWithUrl({ link, children, onMouseEnter, onMouseLeave }) {
  if (link) {
    return (
      <Link to={link} onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}>
        {children}
      </Link>
    );
  }
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <React.Fragment onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}>
      {children}
    </React.Fragment>
  );
}

WrapWithUrl.propTypes = {
  link: PropTypes.string,
  children: PropTypes.node.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};
WrapWithUrl.defaultProps = {
  link: "",
  onMouseEnter: () => null,
  onMouseLeave: () => null,
};

function Marker({ name, isActive, id, link }) {
  const isHome = id === "home";
  const { companyDeskBookingsWithUser, currentUser } = useAuth();

  const [date] = useQueryParams("date", formatISO(getDefaultDate(1)));

  const [userImages, setUserImages] = useState([]);

  useEffect(() => {
    setUserImages(
      filterBookingsForTheLocation(currentUser, companyDeskBookingsWithUser, id)
    );
  }, [companyDeskBookingsWithUser, date]);

  const [show, setShow] = useState(false);
  const target = useRef(null);
  if (name) {
    return (
      <OverlayTrigger
        placement="top"
        target={target.current}
        show={isActive || show}
        overlay={
          <Tooltip id={name}>
            {name}
            {userImages.map((usr, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <span className={s.userInMap} key={idx}>
                {usr.image} {usr.name || usr.email}
              </span>
            ))}
          </Tooltip>
        }
      >
        <Link
          to={link}
          className={s.link}
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
        >
          {userImages.length > 0 ? (
            <ul className={s.userImageList}>
              {/* eslint-disable-next-line react/no-array-index-key */}
              {userImages.map((usr, idx) => (
                <li key={idx}>{usr.image}</li>
              ))}
            </ul>
          ) : (
            <img src={isHome ? HomeMarker : MapMarker} alt={name} />
          )}
        </Link>
      </OverlayTrigger>
    );
  }
  return (
    <img
      src={isHome ? HomeMarker : MapMarker}
      alt={name}
      className={s.plain_marker}
    />
  );
}

export { GoogleMap, Marker };

GoogleMap.propTypes = {
  children: PropTypes.node,
  center: PropTypes.exact({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
  zoom: PropTypes.number,
};

GoogleMap.defaultProps = {
  children: "",
  zoom: 11,
};

Marker.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  link: PropTypes.string,
  isActive: PropTypes.bool,
};
Marker.defaultProps = {
  name: "",
  isActive: false,
  id: "",
  link: "",
};
