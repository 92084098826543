import React, { useEffect, useState } from "react";
// import { useNavigate } from 'react-router-dom';
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import Logo from "../../images/logo-black.svg";
import { useAuth } from "../../contexts/authContext";
import LoginForm from "./LoginForm";
import PasswordResetForm from "./PasswordResetForm";
import useQueryParams from "../../utils/useQueryParams";

function LoginPage() {
  const navigate = useNavigate();
  const [message, setMessage] = useState({
    type: "danger",
    text: "",
  });
  const [forgotPassword, setForgotPassword] = useState(false);

  const [redirectTo] = useQueryParams("redirectTo");

  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser && currentUser.uid) {
      if (redirectTo) {
        const redirectUrl = atob(redirectTo);
        window.location.href = redirectUrl;
      } else {
        navigate("/");
      }
    }
  }, [currentUser]);

  return (
    <div className="min-vh-100 d-flex flex-column align-items-center justify-content-center">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 text-center mt-5 pt-2">
            <img src={Logo} alt="Welcome to Worksimply" width={178} />
          </div>
        </div>
      </div>
      <div className="container my-auto">
        <div className="row justify-content-center">
          <div className="col-sm-10 col-md-8 col-lg-6 col-xl-4 text-center">
            <h1 className="h2 mt-0 mb-5">
              {forgotPassword ? "Reset Password" : "Sign In"}
            </h1>
            <div className="my-3">
              {forgotPassword ? (
                <>
                  <PasswordResetForm setMessage={setMessage} />
                  <p className="text-center">
                    <button
                      type="button"
                      className="btn btn-link text-muted"
                      onClick={() => setForgotPassword(false)}
                    >
                      Back to Login
                    </button>
                  </p>
                </>
              ) : (
                <>
                  <LoginForm setMessage={setMessage} />
                  <p className="text-center mt-1 mb-0">
                    <button
                      type="button"
                      className="btn btn-link text-muted mt-4"
                      onClick={() => setForgotPassword(true)}
                    >
                      Forgot your password?
                    </button>
                  </p>
                </>
              )}

              {message.text && (
                <div
                  className={`alert alert-${
                    message.type || "danger"
                  } position-fixed m-0 p-2 top-0 w-100 start-0`}
                >
                  <p className="m-0">
                    <small>{message.text}</small>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container text-center">
        <p>
          <small>
            <span className="d-block d-md-inline-block">
              &copy; {format(new Date(), "yyyy")} Worksimply Inc. All rights
              reserved.
            </span>
            <a
              href="https://worksimply.com/terms-of-use"
              target="_blank"
              rel="noreferrer"
              className="d-inline-block text-dark mt-2 me-3 mx-md-3"
            >
              Terms of Use
            </a>
            <a
              href="https://worksimply.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
              className="d-inline-block text-dark mt-2"
            >
              Privacy Policy
            </a>
          </small>
        </p>
      </div>
    </div>
  );
}

export default LoginPage;
