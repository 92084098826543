import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import DayPassesBookingBlock from "./DayPasses";
import OfficeBookingBlock from "./OfficeBookingBlock";
import s from "./index.module.scss";
import useQueryParams from "../../../../utils/useQueryParams";
import Message from "./Message";
import get from "lodash/get";
import MeetingRoomsSearchBlock from "../../../MeetingRoomProfilePage/Profile/SearchBlock";

function BookingBlock({
  data,
  showReservation,
  noButton,
  readOnly,
  office,
  setOffice,
  showTentativeArrivalTime,
  invited,
  withMessage,
  mrData,
}) {
  const email = get(data, "on_demand_emails", "");
  const spaceName = get(data, "space_name", "");

  const [searchType] = useQueryParams("type", "desk");
  if (searchType === "office") {
    return (
      <>
        <div className={s.wrapper}>
          <div>
            <OfficeBookingBlock
              data={data}
              noButton={noButton}
              showReservation={showReservation}
              readOnly={readOnly}
              office={office}
              setOffice={setOffice}
              showTentativeArrivalTime={showTentativeArrivalTime}
            />
          </div>
          {withMessage && <Message email={email} spaceName={spaceName} />}
        </div>
      </>
    );
  }
  if (searchType === "meeting-room") {
    return (
      <>
        <div className={s.wrapper}>
          <div>
            <MeetingRoomsSearchBlock readOnly mrData={mrData} />
          </div>
          {withMessage && <Message email={email} spaceName={spaceName} />}
        </div>
      </>
    );
  }
  return (
    <>
      <div className={s.wrapper}>
        <div>
          <DayPassesBookingBlock
            data={data}
            showTentativeArrivalTime={showTentativeArrivalTime}
            readOnly={invited}
          />
          {!noButton && (
            <>
              <br />
              <Button
                variant="primary"
                className="w-100"
                onClick={showReservation}
              >
                Check-Out
              </Button>
            </>
          )}
        </div>
        {withMessage && <Message email={email} spaceName={spaceName} />}
      </div>
    </>
  );
}

export default BookingBlock;

BookingBlock.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  showReservation: PropTypes.func,
  noButton: PropTypes.bool,
  readOnly: PropTypes.bool,
  setOffice: PropTypes.func,
  office: PropTypes.shape({
    id: PropTypes.string,
  }),
  showTentativeArrivalTime: PropTypes.bool,
  invited: PropTypes.bool,
  withMessage: PropTypes.bool,
  mrData: PropTypes.shape({
    _id: PropTypes.string,
  }),
};

BookingBlock.defaultProps = {
  showReservation: () => null,
  noButton: false,
  readOnly: false,
  setOffice: () => null,
  office: {
    id: "",
  },
  showTentativeArrivalTime: false,
  invited: false,
  withMessage: false,
  mrData: null,
};
