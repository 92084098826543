import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import getAmenities from '../../../utils/getAmenities';

function Amenities({ data }) {
  const [amenities, setAmenities] = useState([]);

  useEffect(() => {
    if (!data.id || !data.space_title) return;
    setAmenities(getAmenities(data));
  }, [data]);
  return (
    <>
      <h2 className="mt-5 mb-5 ">Amenities include</h2>
      <div className="row">
        {amenities.map((item) => {
          if (!item.text) return null;
          return (
            <div
              key={item.id}
              className="col-sm-6 col-xl-4 mb-4 mb-lg-5"
            >
              <p className="my-0">{item.text}</p>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default Amenities;

Amenities.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    space_title: PropTypes.string.isRequired,
    space_description: PropTypes.string.isRequired,
    office_address: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
      address: PropTypes.string.isRequired,
    }),
    wifi: PropTypes.bool,
    furnished: PropTypes.bool,
    pet_friendly: PropTypes.bool,
    full_kitchen_access: PropTypes.bool,
    coffee: PropTypes.bool,
    high_speed_internet: PropTypes.bool,
    printing: PropTypes.bool,
    mail_box: PropTypes.bool,
    common_areas: PropTypes.bool,
    breakout_rooms: PropTypes.number,
    meeting_rooms: PropTypes.number,
    phone_booths: PropTypes.number,
    ergonomic_chairs: PropTypes.bool,
  }).isRequired,
};
