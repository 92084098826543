import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { GrClose as CloseIcon } from "react-icons/gr";
import { format, isSameDay, isValid, parseISO } from "date-fns";
import PropTypes from "prop-types";
import uniq from "lodash/uniq";
import { useAuth } from "../../../../../contexts/authContext";
import ProgressButton from "../../../../../components/ProgressButton";
import useGenerateInvitationUrl from "./utils/useGenerateInvitationUrl";
import useQueryParams from "../../../../../utils/useQueryParams";

function BookingInvitation({ data = {} }) {
  const { companyUsers, currentUser, mvpApi, companyDeskBookingsWithUser } =
    useAuth();

  const [date] = useQueryParams("date", "");

  const [selectedUserUid, setSelectedUserUid] = useState("");
  const [selectedUsersList, setSelectedUsersList] = useState([]);

  /**
   * TODO:
   * Filter the users already booked this location
   *   -> mark them as already booked and disable the option
   *
   * Filter the users that are already in the list
   *   -> mark them as added to the list and disable the option
   *
   */

  useEffect(() => {
    if (!selectedUserUid) return;
    const user = companyUsers.find((u) => u.uid === selectedUserUid);
    if (!user) return;
    if (selectedUsersList.find((u) => u.uid === selectedUserUid)) {
      // display error message and return
      setSelectedUserUid("");
      return;
    }
    setSelectedUsersList([...selectedUsersList, user]);
    setSelectedUserUid("");
  }, [selectedUserUid]);

  const removeElement = (uid) => {
    setSelectedUsersList(selectedUsersList.filter((u) => u.uid !== uid));
  };

  const [progress, setProgress] = useState(false);
  const [success, setSuccess] = useState(false);

  const invitationUrl = useGenerateInvitationUrl(selectedUsersList);

  const existingReservations = companyDeskBookingsWithUser.filter((res) => {
    const isSameLocation = res.locationId === data.id;
    const sameDay = isSameDay(new Date(res.start), new Date(date));
    return isSameLocation && sameDay;
  });

  const handleInvite = async () => {
    setProgress(true);

    const resDate = parseISO(date);

    if (!isValid(resDate)) return;

    const extResUserNames = existingReservations.map((res) => {
      const { otherPersonName, userData } = res;
      return otherPersonName || userData.name || userData.email;
    });

    extResUserNames.push(currentUser.displayName || currentUser.email);

    await mvpApi("booking-invitation", {
      invitationUrl,
      currentUser: {
        name: currentUser.displayName,
        email: currentUser.email,
      },
      selectedUsersList,
      reservationData: {
        location: `Desk at ${data.space_name}`,
        date: format(resDate, "MMMM dd, yyyy"),
        attendees: uniq(extResUserNames).join(", "),
      },
    });

    setProgress(false);
    if (process.env.NODE_ENV !== "development") {
      setSuccess(true);
    }
  };

  return (
    <div>
      <hr className="my-5" />
      <h3>Invite your teammates</h3>
      <p className="my-4">
        Want your teammates to join you? Send them an invite and collaborate
        face-to-face.{" "}
      </p>
      <div className="row">
        <div className="col-sm-10 col-md-8 col-lg-6 col-xl-4">
          <select
            name=""
            id=""
            className="form-select mb-3"
            value={selectedUserUid}
            onChange={(e) => setSelectedUserUid(e.target.value)}
          >
            <option value="">Please Select</option>
            {companyUsers
              .filter((u) => u.uid !== currentUser.uid)
              .map((user) => {
                const { name, email, uid } = user;
                const alreadyReserved = !!existingReservations.find(
                  (r) => r.userData.uid === uid
                );
                const inTheList = !!selectedUsersList.find(
                  (r) => r.uid === uid
                );
                return (
                  <option
                    value={uid}
                    key={uid}
                    disabled={alreadyReserved || inTheList}
                  >
                    {name || email}
                    {alreadyReserved ? " (Already reserved)" : ""}
                    {inTheList ? " (Added to the list)" : ""}
                  </option>
                );
              })}
          </select>

          {selectedUsersList.map((user) => (
            <span
              key={user.uid}
              className="mb-3 me-3 bg-light d-inline-block p-2"
            >
              <span className="d-flex align-items-center">
                {user.name || user.email}
                <Button
                  onClick={() => removeElement(user.uid)}
                  variant="link"
                  className="p-0 text-dark d-inline ms-2"
                >
                  <CloseIcon size="10px" />
                </Button>
              </span>
            </span>
          ))}
          <div>
            <ProgressButton
              onClick={handleInvite}
              disabled={selectedUsersList.length === 0}
              progress={progress}
              buttonLabel="Send"
              successLabel="Invited"
              success={success}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingInvitation;

BookingInvitation.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    space_name: PropTypes.string.isRequired,
    hero_image_url: PropTypes.string.isRequired,
    space_description: PropTypes.string.isRequired,
    price_per_day_pass: PropTypes.number.isRequired,
    office_address: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
      address: PropTypes.string.isRequired,
    }),
    on_demand_instructions: PropTypes.string,
    so: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};
