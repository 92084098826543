import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Lightbox from "react-18-image-lightbox";
import PropTypes from "prop-types";

import s from "../Images.module.scss";
import resizeImage from "../../../services/resizeImage";
import useQueryParams from "../../../utils/useQueryParams";

function Images({ data, office }) {
  const [type] = useQueryParams("type", "desk");

  const [imageIndex, setImageIndex] = useState(0);
  const [galleryOpen, setGalleryOpen] = useState(false);

  const [images, setImages] = useState([]);

  useEffect(() => {
    if (!data.hero_image_url) return;
    if (type === "office" && !office?.id) {
      return;
    }
    let defaultOfficeImage = data?.default_office_image_url || null;

    const imageSet = [];

    if (type === "office") {
      if (office?.images?.[0]?.url) {
        imageSet.push(resizeImage(office?.images?.[0]?.url, 600, 960, 1200));
      } else if (defaultOfficeImage) {
        imageSet.push(resizeImage(defaultOfficeImage, 600, 960, 1200));
      }
    }
    imageSet.push(
      ...[
        resizeImage(data.hero_image_url, 600, 960, 1200),
        ...data.common_images.map((item) =>
          resizeImage(item.url, 600, 960, 1200)
        ),
      ]
    );
    setImages(imageSet);
  }, [data, office]);
  if (images.length === 0) {
    return null;
  }
  return (
    <div className="mt-4">
      <div className="row image-gallery-grid mb-3">
        <div className="col-lg-6">
          <div className={s.image_zoom_container}>
            <button
              type="button"
              className={`btn ${s.first_image}`}
              onClick={() => {
                setGalleryOpen(true);
                setImageIndex(0);
              }}
            >
              <img src={images?.[0]} alt="" />
            </button>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="row image-gallery-grid">
            {images.slice(1, 5).map((image, idx) => (
              <div className="col-6" key={image}>
                <div className={s.image_zoom_container}>
                  <button
                    type="button"
                    className="btn"
                    onClick={() => {
                      setGalleryOpen(true);
                      setImageIndex(idx + 1);
                    }}
                  >
                    <img src={image} alt="" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Button
        variant="secondary"
        onClick={() => {
          setImageIndex(0);
          setGalleryOpen(true);
        }}
      >
        Show all photos
      </Button>
      {galleryOpen && (
        <Lightbox
          enableZoom={false}
          mainSrc={images[imageIndex]}
          nextSrc={images[(imageIndex + 1) % images.length]}
          prevSrc={images[(imageIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setGalleryOpen(false)}
          onMovePrevRequest={() =>
            setImageIndex((imageIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setImageIndex((imageIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
}

export default Images;

Images.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    hero_image_url: PropTypes.string.isRequired,
    default_office_image_url: PropTypes.string,
    common_images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        url: PropTypes.string,
      })
    ),
  }).isRequired,
  office: PropTypes.shape({
    id: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
      })
    ),
  }),
};
