import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { useAuth } from "../../contexts/authContext";
import Spinner from "../../components/Spinner";
import { format, parseISO } from "date-fns";
import PropTypes from "prop-types";

const Invoices = () => {
  const { mvpApi } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    mvpApi("company/invoices")
      .then((res) => {
        setInvoices(res?.invoices || []);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  return (
    <Layout
      title="Billing"
      pageLinks={[
        {
          label: "Payment Method",
          link: "/user/billing/payment-method",
        },
        {
          label: "Budget Limits",
          link: "/user/billing/budget-limits",
        },
        {
          label: "Invoices",
          link: "/user/billing/invoices",
        },
      ]}
      allowedRole="admin"
    >
      <div className="row">
        <div className="col-md-8 col-lg-6">
          <p>
            <strong>Invoice history</strong>
          </p>
          {isLoading ? (
            <div className={"position-relative py-5"}>
              <Spinner />
            </div>
          ) : (
            <InvoicesList invoices={invoices} />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Invoices;

const InvoicesList = ({ invoices = [] }) => {
  if (invoices.length === 0) {
    return <p className={"mt-4"}>No invoices yet</p>;
  }

  return (
    <div className={"mt-4"}>
      <ul className="list-unstyled">
        {invoices.map((inv) => {
          const createdAt = inv?.created_at;
          const date = parseISO(createdAt);
          const month = format(date, "MMMM yyyy");
          return (
            <li key={inv?.id}>
              <a
                href={inv?.url}
                target={"_blank"}
                rel={"noreferrer"}
                className={"text-dark"}
              >
                {month}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

InvoicesList.propTypes = {
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      created_at: PropTypes.string,
    })
  ),
};

InvoicesList.defaultProps = {
  invoices: [],
};
