import get from "lodash/get";

const usePdfForMrIntegration = async (
  reservationData = {},
  location,
  mvpApi
) => {
  const pdfData = {
    pdfData: {
      list1: [
        { value: location.space_name },
        { value: get(location, "office_address.address", "") },
        { value: "Meeting Room" },
        {
          value: "1 person",
        },
      ],
      list2: [
        {
          label: "Full name",
          value:
            get(reservationData, "user.name", "Not provided") || "Not provided",
        },
        { label: "Email", value: get(reservationData, "user.email", "") },
        {
          label: "Phone number",
          value:
            get(reservationData, "user.phone", "Not provided") ||
            "Not provided",
        },
        { label: "From", value: reservationData.fromDate },
        { label: "To", value: reservationData.toDate },
        {
          label: "Arrival time",
          value: reservationData.fromTime,
        },
        {
          label: "Checkout time",
          value: reservationData.toTime,
        },
      ],
      instructions: location?.on_demand_instructions || null,
      reservationId: reservationData.id,
    },
    reservationData: {
      type: "meeting-room",
    },
    invitation: null,
    disableSoEmail: true,
    disableUserEmail: true,
    onlyPdf: true,
  };
  const pdfRef = await mvpApi("pdfs/create", pdfData);
  return pdfRef?.response?.url || null;
};

export default usePdfForMrIntegration;
