import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Logo from "../../images/logo-black.svg";
import s from "./index.module.scss";
import UserMenu from "./UserMenu";
import Metadata from "./Metadata";
import { useAuth } from "../../contexts/authContext";
import CityFilter from "./CityFilter";

function Header({ title, fullWidth, subTitle, cityFilter }) {
  const { claims, company } = useAuth();

  return (
    <>
      <Metadata title={title || "Worksimply"} />
      <header className={`mb-3 pb-1 ${s.header}`}>
        <div className={fullWidth ? "container-fluid py-3" : "container py-3"}>
          <div className="w-100 d-flex align-items-center">
            <div className="text-nowrap d-flex">
              <Link to="/" className={s.main_logo}>
                <img src={Logo} alt="Worksimply" />
              </Link>
              {!claims.admin && company.logo && (
                <div className={`${s.client_logo} ms-3 ps-3 ms-ms-4 ps-ms-4`}>
                  <img src={company.logo} alt="" />
                </div>
              )}
            </div>
            <div className="ms-auto d-flex align-items-center">
              <UserMenu />
            </div>
          </div>
        </div>
        {cityFilter && <CityFilter />}
        {title && (
          <div
            className={fullWidth ? "container-fluid py-3" : "container py-3"}
          >
            <h1>{title}</h1>
            {subTitle && (
              <strong
                className="text-muted"
                dangerouslySetInnerHTML={{ __html: subTitle }}
              />
            )}
          </div>
        )}
      </header>
    </>
  );
}

export default Header;

Header.propTypes = {
  title: PropTypes.string,
  fullWidth: PropTypes.bool,
  subTitle: PropTypes.string,
  cityFilter: PropTypes.bool,
};

Header.defaultProps = {
  title: "",
  subTitle: null,
  fullWidth: false,
  cityFilter: false,
};
