import React from "react";
import { AiOutlineUser as UserPortrait } from "react-icons/ai";
import { FiMenu as MenuLogo } from "react-icons/fi";
import { Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import get from "lodash/get";
import { useAuth } from "../../contexts/authContext";
import s from "./index.module.scss";
import Points from "./points.svg";

// eslint-disable-next-line react/display-name
const CustomToggle = React.forwardRef(({ onClick }, ref) => {
  const { currentUser } = useAuth();
  const photoURL = currentUser.photoURL || null;
  return (
    <button
      className={` ${s.user_menu}`}
      type="button"
      onClick={onClick}
      ref={ref}
    >
      <MenuLogo />
      {photoURL ? <img src={photoURL} alt="" /> : <UserPortrait />}
    </button>
  );
});

function UserMenu() {
  const navigate = useNavigate();

  const { logout, currentUser, claims } = useAuth();

  const goTo = (url = "/") => {
    navigate(url);
  };

  return (
    <Dropdown align="end">
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        Custom toggle
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Header>
          {currentUser.displayName && (
            <>
              {currentUser.displayName}
              <br />
            </>
          )}
          {currentUser.email}
        </Dropdown.Header>
        <Dropdown.Divider />
        {claims.admin ? (
          <WsAdminMenu goTo={goTo} logout={logout} />
        ) : (
          <>
            {claims.role === "admin" && (
              <CompanyAdminMenu goTo={goTo} logout={logout} />
            )}
            {claims.role === "user" && (
              <CompanyUserMenu goTo={goTo} logout={logout} />
            )}
            <Dropdown.Divider />
            <Dropdown.Item onClick={() => goTo("/")}>
              Find Workspace
            </Dropdown.Item>
          </>
        )}
        <Dropdown.Divider />
        <Dropdown.Item eventKey="10" onClick={() => logout()}>
          Logout
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default UserMenu;

CustomToggle.propTypes = {
  onClick: PropTypes.func.isRequired,
};

function WsAdminMenu() {
  return (
    <>
      <Dropdown.Item as={"li"}>
        <Link to={"/admin/companies/add"} className={"dropdown-item p-0"}>
          Add Company
        </Link>
      </Dropdown.Item>
      <Dropdown.Item as={"li"}>
        <Link to={"/admin/users/add"} className={"dropdown-item p-0"}>
          Add User
        </Link>
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item as={"li"}>
        <Link to={"/admin/summary"} className={"dropdown-item p-0"}>
          Monthly Summary
        </Link>
      </Dropdown.Item>
      <Dropdown.Item as={"li"}>
        <Link to={"/admin/data-room"} className={"dropdown-item p-0"}>
          Data Room
        </Link>
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item as={"li"}>
        <Link className={"dropdown-item p-0"} to={"/admin/app-notifications"}>
          Test App Notifications
        </Link>
      </Dropdown.Item>
    </>
  );
}

WsAdminMenu.propTypes = {
  goTo: PropTypes.func.isRequired,
};

function CompanyAdminMenu() {
  return (
    <>
      <Dropdown.Item as={"li"}>
        <Link className={"dropdown-item p-0"} to={"/user/members"}>
          Members
        </Link>
      </Dropdown.Item>
      <Dropdown.Item as={"li"}>
        <Link
          className={"dropdown-item p-0"}
          to={"/user/billing/payment-method"}
        >
          Billing
        </Link>
      </Dropdown.Item>
      {/*<Dropdown.Item onClick={() => goTo("/user/office-settings")}>*/}
      {/*  Office Settings*/}
      {/*</Dropdown.Item>*/}
      <Dropdown.Divider />
      <Dropdown.Item as={"li"}>
        <Link to="/reports" className={"dropdown-item p-0"}>
          Reports
        </Link>
      </Dropdown.Item>

      <Dropdown.Divider />

      <Dropdown.Item as={"li"}>
        <Link className={"dropdown-item p-0"} to={"/user/account"}>
          Account
        </Link>
      </Dropdown.Item>
      <Dropdown.Item>
        <Link className={"dropdown-item p-0"} to={"/user/bookings/upcoming"}>
          Bookings
        </Link>
      </Dropdown.Item>
    </>
  );
}

CompanyAdminMenu.propTypes = {
  goTo: PropTypes.func.isRequired,
};

function CompanyUserMenu({ goTo }) {
  const { company, usedCredits, claims, points } = useAuth();

  const isUser = get(claims, "role", "") === "user";

  const credits = get(company, "creditsPerEmployee", null);

  const availableCredits =
    credits && usedCredits
      ? Number(credits) - Number(usedCredits)
      : Number(credits);

  const noCreditsYet = credits === false;

  return (
    <>
      {isUser && (
        <>
          <Dropdown.ItemText className="text-green">
            {noCreditsYet ? (
              <strong>No credits yet</strong>
            ) : (
              <strong>{availableCredits || "0"} Credits left</strong>
            )}
          </Dropdown.ItemText>
          <Dropdown.Divider />
          <Dropdown.Item
            onClick={() => goTo("/user/bookings/upcoming")}
            className="d-sm-none"
          >
            <img
              src={Points}
              alt="Points"
              className={`me-1 position-relative animate-flash`}
              style={{ marginTop: "-8px" }}
            />{" "}
            {points.toLocaleString()} points
          </Dropdown.Item>
          <Dropdown.Divider className={"d-sm-none"} />
        </>
      )}

      <Dropdown.Item onClick={() => goTo("/user/bookings/upcoming")}>
        Bookings
      </Dropdown.Item>
      {/*<Dropdown.Item onClick={() => goTo("/user/rewards")}>*/}
      {/*  Rewards*/}
      {/*</Dropdown.Item>*/}
      <Dropdown.Item onClick={() => goTo("/user/account")}>
        My Account
      </Dropdown.Item>
    </>
  );
}

CompanyUserMenu.propTypes = {
  goTo: PropTypes.func.isRequired,
};
