import React from "react";
import { Dropdown } from "react-bootstrap";
import cx from "classnames";
import PropTypes from "prop-types";
import s from "./index.module.scss";
import useQueryParams from "../../../../../../utils/useQueryParams";

function TeamSizePicker({ readOnly, tsArray }) {
  const isMobile = window.innerWidth < 992;
  const [teamSize, setTeamSize] = useQueryParams("teamSize", "0");
  return (
    <div className={cx({ [s.ts_main_wrapper]: true, [s.readOnly]: readOnly })}>
      <Dropdown
        className={s.filter_wrapper}
        drop={isMobile ? "bottom" : "start"}
      >
        <Dropdown.Toggle variant="default" id="team-size-picker">
          <small className="d-block">Team size</small>
          {Number(teamSize) === 0
            ? "Please select"
            : `${teamSize} ${Number(teamSize) === 1 ? "person" : "people"}`}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <ul className={s.ts_array}>
            {tsArray.map((ts) => (
              <li key={ts}>
                <Dropdown.Item
                  as="button"
                  className={Number(teamSize) === ts ? s.active : ""}
                  onClick={() => setTeamSize(ts)}
                >
                  {ts}
                </Dropdown.Item>
              </li>
            ))}
          </ul>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default TeamSizePicker;

TeamSizePicker.propTypes = {
  readOnly: PropTypes.bool,
  tsArray: PropTypes.arrayOf(PropTypes.number),
};

TeamSizePicker.defaultProps = {
  readOnly: false,
  tsArray: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
};
