import React from "react";
import PropTypes from "prop-types";
import BookingBlock from "./BookingBlock";

function Bookings({ bookings, isUpcomingBookings }) {
  return (
    <div className="mb-5">
      <div className="row">
        {bookings.map((booking) => (
          <BookingBlock
            data={booking}
            key={booking.id}
            isUpcomingBookings={isUpcomingBookings}
          />
        ))}
      </div>
    </div>
  );
}

export default Bookings;

Bookings.propTypes = {
  bookings: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ),
  isUpcomingBookings: PropTypes.bool,
};

Bookings.defaultProps = {
  bookings: [],
  isUpcomingBookings: true,
};
