import get from 'lodash/get';

const getAmenities = (data) => {
  if (!data || !data.id) return [];

  const additionalIncludes = get(data, 'additional_includes', []) || [];

  const amenities = [{
    id: 'wifi',
    value: data.wifi,
    text: data.wifi ? 'Wifi available' : '',
  },
  {
    id: 'furnished',
    value: data.furnished,
    text: data.furnished ? 'Fully furnished' : '',
  },
  {
    id: 'pet_friendly',
    value: data.pet_friendly,
    text: data.pet_friendly ? 'Pet friendly' : '',
  },
  {
    id: 'full_kitchen_access',
    value: data.full_kitchen_access,
    text: data.full_kitchen_access ? 'Full kitchen access' : '',
  },
  {
    id: 'coffee',
    value: data.coffee,
    text: data.coffee ? 'Coffee & Tea' : '',
  },
  {
    id: 'printing',
    value: data.printing,
    text: data.printing ? 'Printing' : '',
  },
  {
    id: 'common_area',
    value: data.common_areas,
    text: data.common_areas ? 'Common areas' : '',
  },
  {
    id: 'mail_box',
    value: data.mail_box,
    text: data.mail_box ? 'Mail box' : '',
  },
  {
    id: 'high_speed_internet',
    value: data.high_speed_internet,
    text: data.high_speed_internet ? 'High Speed Internet' : '',
  },
  {
    id: 'breakout_rooms',
    value: data.breakout_rooms,
    text: data.breakout_rooms
      ? `Access to ${data.breakout_rooms} phone booths`
      : '',
  },
  {
    id: 'meeting_rooms',
    value: data.meeting_rooms,
    text: data.meeting_rooms
      ? `Access to ${data.meeting_rooms} meeting rooms`
      : '',
  },
  {
    id: 'phone_booths',
    value: data.phone_booths,
    text: data.phone_booths
      ? `Access to ${data.phone_booths} phone booths`
      : '',
  },
  {
    id: 'ergonomic_chairs',
    value: data.ergonomic_chairs,
    text: data.ergonomic_chairs ? 'Ergonomic chairs' : '',
  }];

  if (additionalIncludes && additionalIncludes.length > 0) {
    amenities.push(...additionalIncludes.map((item, idx) => ({
      id: `additional_include_${idx}`,
      value: '',
      text: item,
    })));
  }

  return amenities;
};

export default getAmenities;
