import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import get from "lodash/get";
import { format, formatISO, isSameDay, isValid, parseISO } from "date-fns";
import { Button } from "react-bootstrap";
import cx from "classnames";
import { ImMap2 as MapIcon } from "react-icons/im";
import Spinner from "../../../../components/Spinner";
import s from "../index.module.scss";
import { GoogleMap, Marker } from "../../../../components/GoogleMap";
import worksimplyApi from "../../../../services/worksimplyApi";
import resizeImage from "../../../../services/resizeImage";
import useQueryParams from "../../../../utils/useQueryParams";
import { useAuth } from "../../../../contexts/authContext";
import getDefaultDate from "../../../../utils/getDefaultDate";
import filterBookingsForTheLocation from "../../../../utils/FilterBookingsForTheLocation";
import UsersList from "../UsersList";
import TypeBadge from "../TypeBadge";

const defaultMapCenter = { lat: 43.6508797, lng: -79.3834781 };

function DeskSearch() {
  const {
    company,
    companyDeskBookingsWithUser,
    currentUser,
    currentUserDoc,
    companyDeskBookings: allBookings,
  } = useAuth();

  const lat = get(currentUserDoc, "lat", null);
  const lng = get(currentUserDoc, "lng", null);

  const [hoveredLocationId, setHoveredLocationId] = useState(null);

  const [showMapOnMobile, setShowMapOnMobile] = useState(false);

  const [center, setCenter] = useState();

  const [list, setList] = useState([]);

  const [filteredList, setFilteredList] = useState([]);

  const [inProgress, setInProgress] = useState(true);
  const [city] = useQueryParams("city", currentUserDoc?.city || "");

  const [date] = useQueryParams("date", formatISO(getDefaultDate(1)));

  const [category] = useQueryParams("category", "all");

  useEffect(() => {
    setCenter({
      lat: currentUserDoc.lat || defaultMapCenter.lat,
      lng: currentUserDoc.lng || defaultMapCenter.lng,
    });
  }, [currentUserDoc.lat, currentUserDoc.lng]);

  useEffect(() => {
    // fetch results
    setInProgress(true);
    let formattedDate = null;
    const parsedDate = parseISO(date);
    if (date && isValid(parsedDate)) {
      formattedDate = format(parsedDate, "yyyy-MM-dd");
    }
    worksimplyApi("search/day-passes", {
      params: {
        city: city || "Toronto",
        date: formattedDate,
        category,
      },
      lat,
      lng,
    })
      .then((res) => {
        const pickedCity = city || "Toronto";
        const { scores = [] } = res || {};

        const results = pickedCity
          ? scores.filter(({ location, city: locationsCity }) => {
              const locationCity = locationsCity || location.city;
              if (!locationCity) {
                return false;
              }
              return locationCity
                .toLowerCase()
                .includes(pickedCity.toLowerCase());
            })
          : scores;

        setList(
          results.filter((location) => {
            const bookings = allBookings.filter((b) => {
              const bookingDate = new Date(b.start);
              return (
                isSameDay(bookingDate, parsedDate) &&
                location.space_id === b.locationId
              );
            });
            if (
              !bookings.length ||
              bookings.length === 0 ||
              !location.day_passes_per_day
            ) {
              return true;
            }
            return bookings.length < location.day_passes_per_day;
          }),
        );

        if (lat && lng && pickedCity === get(currentUserDoc, "city", "")) {
          setCenter({
            lat: currentUserDoc?.lat || defaultMapCenter.lat,
            lng: currentUserDoc?.lng || defaultMapCenter?.lng,
          });
        } else if (results.length > 0) {
          setCenter({
            lat: get(results, "[0].location.lat", ""),
            lng: get(results, "[0].location.lng", ""),
          });
        }
        setInProgress(false);
      })
      .catch(() => {
        setInProgress(false);
      });
  }, [city, date, category]);

  useEffect(() => {
    if (
      !company ||
      !company.allowedLocations ||
      company.allowedLocations.length === 0
    ) {
      return;
    }

    if (process.env.NODE_ENV === "development") {
      setFilteredList(list);
      return;
    }
    const filtered = list.filter((location) => {
      const spaceId = location.space_id;
      return company.allowedLocations.find(({ id }) => id === spaceId);
    });
    setFilteredList(filtered);
  }, [company, list]);

  return (
    <>
      <div
        className={cx("col-md-7 col-lg-6 p-0 d-flex order-md-2", {
          "d-none d-md-block": !showMapOnMobile,
        })}
      >
        <div className={`w-100 sticky-top ${s.map_wrapper}`}>
          {center && (
            <GoogleMap center={center} className="something">
              {!inProgress &&
                filteredList.map((location) => (
                  <Marker
                    key={location.space_id}
                    lat={get(location, "location.lat", "")}
                    lng={get(location, "location.lng", "")}
                    isActive={hoveredLocationId === location.space_id}
                    id={location.space_id}
                    name={location.name}
                    link={`/location/${
                      location.space_id
                    }?date=${encodeURIComponent(date)}`}
                  />
                ))}
              {lat && lng && <Marker lat={lat} lng={lng} id="home" />}
            </GoogleMap>
          )}
        </div>
      </div>
      <div className="col-md-5 col-lg-6 p-0 order-md-1  results-list-container-fix-for-filters">
        {inProgress && (
          <div className="position-relative py-5">
            <Spinner />
          </div>
        )}

        {!inProgress && filteredList && filteredList.length === 0 && (
          <NoResultsMessage />
        )}

        {!inProgress && filteredList && filteredList.length > 0 && (
          <ul className="list-styled p-3">
            {filteredList.map((location) => {
              const usersList = filterBookingsForTheLocation(
                currentUser,
                companyDeskBookingsWithUser,
                location.id,
              );

              return (
                <li
                  className={s.location_summary_block}
                  key={location.space_id}
                >
                  <Link
                    to={`/location/${
                      location.space_id
                    }?date=${encodeURIComponent(date)}`}
                    onMouseEnter={() => {
                      if (window.innerWidth > 1200) {
                        setHoveredLocationId(location.space_id);
                      }
                    }}
                    onMouseLeave={() => setHoveredLocationId(null)}
                  >
                    <div>
                      <div>
                        <img
                          src={
                            location.cover_image
                              ? resizeImage(
                                  location.cover_image,
                                  400,
                                  400,
                                  400,
                                  600,
                                )
                              : ""
                          }
                          alt={location.name}
                        />
                        <TypeBadge />
                      </div>
                    </div>
                    <div>
                      <span>{get(location, "location.short_address", "")}</span>
                      <h4>{location.name}</h4>
                    </div>
                    <UsersList list={usersList} />
                  </Link>
                  <div
                    className={`${s.price_block} ${
                      usersList.length === 0 ? "" : s.active_results
                    }`}
                  >
                    <p className="m-0">
                      {location.price_per_day_pass} credits /day
                    </p>
                    <Link
                      to={`/location/${
                        location.space_id
                      }?date=${encodeURIComponent(date)}&expressCheckout=✓`}
                    >
                      Express Checkout
                    </Link>
                  </div>
                </li>
              );
            })}
          </ul>
        )}

        {!inProgress && filteredList && filteredList.length > 0 && (
          <div
            className={`position-sticky bottom-0 d-md-none text-center py-3 ${s.sticky_map_button}`}
          >
            <Button
              onClick={() => {
                setShowMapOnMobile(!showMapOnMobile);
                if (!showMapOnMobile) {
                  window.scroll(0, 0);
                }
              }}
              variant="default"
            >
              {showMapOnMobile ? "Hide" : "Map"}
              <MapIcon />
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

export default DeskSearch;

function NoResultsMessage() {
  return (
    <div className="p-3">
      <p>No results available. Please change your filters and try again.</p>
    </div>
  );
}
