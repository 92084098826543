import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import NoBookingsImage from './no-bookings.jpg';

function NoBookings({ title, image }) {
  return (
    <div>
      <p className="mt-0 mb-4">{title}</p>
      <Link to="/" className="btn btn-primary">Find Workspace</Link>
      <img
        src={image || NoBookingsImage}
        alt="No Bookings"
        className="w-100 d-block mx-auto mt-5"
        style={{ maxWidth: '600px' }}
      />
    </div>
  );
}

export default NoBookings;

NoBookings.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
};

NoBookings.defaultProps = {
  title: 'You don’t have any bookings.',
  image: null,
};
