import React from 'react';
import Layout from '../../layout';

function DashboardPage() {
  return (
    <Layout
      title="Bookings"
      pageLinks={[{
        label: 'Analytics',
        link: '/user/dashboard',
      }]}
    >

      <div className="row">
        <div className="col-md-8 col-lg-6" />
      </div>
    </Layout>
  );
}

export default DashboardPage;
