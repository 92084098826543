import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Button } from "react-bootstrap";
import Spinner from "../Spinner";

function ProgressButton({
  progress,
  disabled,
  buttonLabel,
  fullWidth,
  onClick,
  variant,
  size,
  successLabel,
  success,
  className,
}) {
  const [background, setBg] = useState("rgba(0, 0, 0, 0.5)");
  useEffect(() => {
    switch (variant) {
      case "secondary":
        setBg("#008060");
        break;
      default:
        setBg("rgba(0, 0, 0, 0.5)");
        break;
    }
  }, [variant]);
  return (
    <Button
      variant={variant}
      type="submit"
      size={size}
      className={classnames(className, {
        "position-relative": true,
        "w-100": fullWidth,
      })}
      disabled={progress || disabled || success}
      onClick={onClick}
    >
      {progress && <Spinner background={background} small={size === "sm"} />}
      <span className={progress ? "opacity-0" : ""}>
        {success ? successLabel || buttonLabel : buttonLabel}
      </span>
    </Button>
  );
}

export default ProgressButton;

ProgressButton.propTypes = {
  progress: PropTypes.bool,
  disabled: PropTypes.bool,
  success: PropTypes.bool,
  buttonLabel: PropTypes.string,
  successLabel: PropTypes.string,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.oneOf(["sm", "md"]),
  variant: PropTypes.oneOf(["primary", "secondary", "danger"]),
};

ProgressButton.defaultProps = {
  progress: false,
  disabled: false,
  buttonLabel: "Submit",
  fullWidth: false,
  variant: "primary",
  size: "md",
  successLabel: "Submit",
  success: false,
  onClick: () => null,
  className: "",
};
