import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import get from "lodash/get";
import { format, formatISO, isValid, parseISO } from "date-fns";
import { Button } from "react-bootstrap";
import cx from "classnames";
import { ImMap2 as MapIcon } from "react-icons/im";
import Spinner from "../../../components/Spinner";
import s from "./index.module.scss";
import { GoogleMap, Marker } from "../../../components/GoogleMap";
import worksimplyApi from "../../../services/worksimplyApi";
import resizeImage from "../../../services/resizeImage";
import useQueryParams from "../../../utils/useQueryParams";
import { useAuth } from "../../../contexts/authContext";
import getDefaultDate from "../../../utils/getDefaultDate";
import filterBookingsForTheLocation from "../../../utils/FilterBookingsForTheLocation";
import UsersList from "./UsersList";
import TypeBadge from "./TypeBadge";

const defaultMapCenter = { lat: 43.6508797, lng: -79.3834781 };

function OfficeSearch() {
  const { company, companyDeskBookingsWithUser, currentUser, currentUserDoc } =
    useAuth();

  const lat = get(currentUserDoc, "lat", null);
  const lng = get(currentUserDoc, "lng", null);

  const [hoveredLocationId, setHoveredLocationId] = useState(null);

  const [showMapOnMobile, setShowMapOnMobile] = useState(false);

  const [center, setCenter] = useState(defaultMapCenter);

  const [category] = useQueryParams("category", "all");

  useEffect(() => {
    if (!lat || !lng) {
      return;
    }
    setCenter({ lat: currentUserDoc.lat, lng: currentUserDoc.lng });
  }, [currentUserDoc]);

  const [list, setList] = useState([]);

  const [filteredList, setFilteredList] = useState([]);

  const [inProgress, setInProgress] = useState(true);
  const [city] = useQueryParams("city", currentUserDoc?.city || "Toronto");

  const [date] = useQueryParams("date", formatISO(getDefaultDate(1)));

  const [teamSize] = useQueryParams("teamSize", "1");

  useEffect(() => {
    // fetch results
    setInProgress(true);
    const params = {
      location: city,
      category,
    };
    if (Number(teamSize) > 0) {
      params.team_size = teamSize;
    }
    if (date && isValid(parseISO(date))) {
      params.start_date = format(parseISO(date), "yyyy-MM-dd");
      params.end_date = format(parseISO(date), "yyyy-MM-dd");
    }
    worksimplyApi("search/on-demand-spaces", {
      results_count: 1,
      params,
      lat,
      lng,
    })
      .then((res) => {
        const pickedCity = city || "Toronto";
        const { scores = [] } = res || {};

        const results = pickedCity
          ? scores.filter(({ location, city: locationsCity }) => {
              const locationCity = locationsCity || location.city;
              if (!locationCity) {
                return false;
              }
              return locationCity
                .toLowerCase()
                .includes(pickedCity.toLowerCase());
            })
          : scores;

        // link the users to the location
        if (lat && lng) {
          setList(results);
        } else {
          setList(results.sort((a, b) => a.price_per_day - b.price_per_day));
        }

        if (lat && lng && pickedCity === get(currentUserDoc, "city", "")) {
          setCenter({
            lat: currentUserDoc?.lat || defaultMapCenter.let,
            lng: currentUserDoc?.lng || defaultMapCenter?.lng,
          });
        } else if (results.length > 0) {
          setCenter({
            lat: get(results, "[0].location.lat", ""),
            lng: get(results, "[0].location.lng", ""),
          });
        }
        setInProgress(false);
      })
      .catch(() => {
        setInProgress(false);
      });
  }, [city, teamSize, date, category]);

  useEffect(() => {
    if (
      !company ||
      !company.allowedLocations ||
      company.allowedLocations.length === 0
    ) {
      return;
    }
    const filtered = list.filter((location) => {
      const spaceId = location.space_id;
      return company.allowedLocations.find(({ id }) => id === spaceId);
    });
    setFilteredList(filtered);
  }, [company, list]);

  return (
    <>
      <div
        className={cx("col-md-7 col-lg-6 p-0 d-flex order-md-2", {
          "d-none d-md-block": !showMapOnMobile,
        })}
      >
        <div className={`w-100 sticky-top ${s.map_wrapper}`}>
          <GoogleMap center={center} className="something">
            {!inProgress &&
              filteredList.map((location) => (
                <Marker
                  key={location.space_id}
                  lat={get(location, "location.lat", "")}
                  lng={get(location, "location.lng", "")}
                  isActive={hoveredLocationId === location.space_id}
                  id={location.space_id}
                  name={location.name}
                  link={`/location/${
                    location.space_id
                  }?date=${encodeURIComponent(
                    date
                  )}&teamSize=${teamSize}&type=office`}
                />
              ))}
            {lat && lng && <Marker lat={lat} lng={lng} id="home" />}
          </GoogleMap>
        </div>
      </div>
      <div className="col-md-5 col-lg-6 p-0 order-md-1 results-list-container-fix-for-filters">
        {inProgress && (
          <div className="position-relative py-5">
            <Spinner />
          </div>
        )}

        {!inProgress && filteredList && filteredList.length === 0 && (
          <NoResultsMessage />
        )}

        {!inProgress && filteredList && filteredList.length > 0 && (
          <ul className="list-styled p-3">
            {filteredList.map((location) => {
              const usersList = filterBookingsForTheLocation(
                currentUser,
                companyDeskBookingsWithUser,
                location.id
              );

              return (
                <li
                  className={s.location_summary_block}
                  key={location.space_id}
                >
                  <Link
                    to={`/location/${
                      location.space_id
                    }?date=${encodeURIComponent(
                      date
                    )}&teamSize=${teamSize}&type=office`}
                    onMouseEnter={() => {
                      if (window.innerWidth > 1200) {
                        setHoveredLocationId(location.space_id);
                      }
                    }}
                    onMouseLeave={() => setHoveredLocationId(null)}
                  >
                    <div>
                      <div>
                        <img
                          src={
                            location.cover_image
                              ? resizeImage(
                                  location.cover_image,
                                  400,
                                  400,
                                  400,
                                  600
                                )
                              : ""
                          }
                          alt={location.name}
                        />
                        <TypeBadge />
                      </div>
                    </div>
                    <div>
                      <span>{get(location, "location.short_address", "")}</span>
                      <h4>{location.name}</h4>
                    </div>
                    <UsersList list={usersList} />
                  </Link>
                  <div
                    className={`${s.price_block} ${
                      usersList.length === 0 ? "" : s.active_results
                    }`}
                  >
                    <p className="m-0">{location.price_per_day} credits /day</p>
                  </div>
                </li>
              );
            })}
          </ul>
        )}

        {!inProgress && filteredList && filteredList.length > 0 && (
          <div
            className={`position-sticky bottom-0 d-md-none text-center py-3 ${s.sticky_map_button}`}
          >
            <Button
              onClick={() => {
                setShowMapOnMobile(!showMapOnMobile);
                if (!showMapOnMobile) {
                  window.scroll(0, 0);
                }
              }}
              variant="default"
            >
              {showMapOnMobile ? "Hide" : "Map"}
              <MapIcon />
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

export default OfficeSearch;

function NoResultsMessage() {
  return (
    <div className="p-3">
      <p>No results available. Please change your filters and try again.</p>
    </div>
  );
}
