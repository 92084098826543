import React, { useEffect } from "react";
import get from "lodash/get";
import { Button } from "react-bootstrap";
import cx from "classnames";
import { useAuth } from "../../../contexts/authContext";
import s from "./index.module.scss";
import DateFilter from "../DateFilter";
import TeamSizeFilter from "../TeamSizeFilter";
import DeskSearch from "./DeskSearch";
import DateView from "./DeskSearch/DateView";
// import MainOffice from "./MainOffice";
import OfficeSearch from "./OfficeSearch";
import useQueryParams from "../../../utils/useQueryParams";
import DateViewToggle from "./DateViewToggle";
import MeetingRoomsSearch from "./MeetingRoomsSearch";
import DateFilterMeetingRooms from "../MeetingRoomsDateFilter";
import Categories from "./Categories";

// const MAIN_OFFICE = {
//   slug: "mainOffice",
//   label: "Main Office",
// };

const DESK = {
  slug: "desk",
  label: "Desks",
};

const OFFICE = {
  slug: "office",
  label: "Day Offices",
};

const MEETING_ROOM = {
  slug: "meeting-room",
  label: "Meeting Spaces",
};

export const searchTypes = [DESK, OFFICE, MEETING_ROOM];

function Search() {
  const { company } = useAuth();

  const [searchType, setSearchType] = useQueryParams("type", DESK.slug);

  const [, setCategory] = useQueryParams("category", "all");

  const [dateView, setDateView] = useQueryParams("date_view", "");

  const isDeskSearch = searchType === DESK.slug;

  useEffect(() => {
    if (!get(company, "id", false)) return;
    if (!searchType) {
      setSearchType(DESK.slug);
    }
  }, [window.location.search]);

  if (!company || !company.id) return null;
  return (
    <div className={s.z_index_fix}>
      <div className="row position-relative">
        <div className={`col-sm-12 ${s.page_header}`}>
          <ul
            className={cx({
              [s.search_menu]: true,
            })}
          >
            {searchTypes.map((type) => (
              <li key={type.slug}>
                <Button
                  variant="default"
                  className={cx({ [s.active]: searchType === type.slug })}
                  onClick={() => {
                    setCategory("all");
                    setSearchType(type.slug);
                  }}
                >
                  {type.label}
                </Button>
              </li>
            ))}
          </ul>

          <Categories />

          <div
            className={`d-flex align-items-center ${s.search_filters_wrapper}`}
          >
            {searchType === OFFICE.slug && <TeamSizeFilter />}
            {searchType === MEETING_ROOM.slug && (
              <TeamSizeFilter
                noDefaultTs
                tsArray={[
                  2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
                  19, 20,
                ]}
                defaultLabel={"Attendees"}
              />
            )}
            {searchType === MEETING_ROOM.slug ? (
              <DateFilterMeetingRooms hasHourly hasDaily />
            ) : (
              <DateFilter />
            )}

            {isDeskSearch && (
              <DateViewToggle isActive={!!dateView} toggle={setDateView} />
            )}
          </div>
        </div>
      </div>
      <div className="row flex-grow-1 position-relative">
        {searchType === DESK.slug && !dateView && <DeskSearch />}
        {searchType === DESK.slug && !!dateView && (
          <DateView mapView={() => setDateView("")} />
        )}
        {/*{searchType === MAIN_OFFICE.slug && <MainOffice />}*/}
        {searchType === OFFICE.slug && <OfficeSearch />}
        {searchType === MEETING_ROOM.slug && <MeetingRoomsSearch />}
      </div>
    </div>
  );
}

export default Search;
