import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import { formatISO } from "date-fns";
import s from "./index.module.scss";
import useQueryParams from "../../../../../../utils/useQueryParams";
import getDefaultDate from "../../../../../../utils/getDefaultDate";

const isMobile = window.innerWidth < 992;

function Datepicker({ readOnly, isLast, excludeDates }) {
  const [date, setDate] = useQueryParams("date", formatISO(getDefaultDate(1)));

  const placement = isMobile ? "bottom" : "left-start";

  // eslint-disable-next-line react/display-name,react/prop-types
  const DateFilterButton = forwardRef(({ value, onClick }, ref) => (
    <button
      type="button"
      className={`btn ${s.filter_button} ${isLast ? s.isLast : ""}`}
      onClick={onClick}
      ref={ref}
    >
      <small>Drop-in Date</small>
      {value}
    </button>
  ));

  return (
    <div className={`${readOnly ? s.readOnly : ""}`}>
      <DatePicker
        selected={date ? new Date(date) : getDefaultDate(1)}
        onChange={(newDate) => setDate(formatISO(newDate))}
        dateFormat="MMMM do"
        popperPlacement={placement}
        minDate={getDefaultDate(1)}
        customInput={<DateFilterButton />}
        excludeDates={excludeDates}
      />
    </div>
  );
}

export default Datepicker;

// DateFilterButton.propTypes = {
//   value: PropTypes.string,
//   onClick: PropTypes.func,
// };
//
// DateFilterButton.defaultProps = {
//   value: '',
//   onClick: () => null,
// };

Datepicker.propTypes = {
  readOnly: PropTypes.bool,
  isLast: PropTypes.bool,
  excludeDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
};

Datepicker.defaultProps = {
  readOnly: false,
  isLast: false,
  excludeDates: [],
};
