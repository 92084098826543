import { format, setMinutes, setHours, parseISO } from "date-fns";
import get from "lodash/get";
import convertDateTimeToUtc from "../../../../utils/convertDateTimeToUtc";
import getQueryParams from "../../../../utils/getQueryParams";
import { v4 as uuid } from "uuid";

const getMeetingRoomReservationData = (
  user = {
    name: "N/A",
    email: "",
  },
  profile,
  mrData,
  quantity
) => {
  const params = getQueryParams();

  const start = params.get("date");
  const end = params.get("endDate");

  const isDaily = params.get("isDaily");

  const timezone =
    get(mrData, "location.timezone", "America/Toronto") || "America/Toronto";

  const fromStr = parseISO(start);
  const toStr = parseISO(end);

  const from = fromStr ? new Date(fromStr) : null;
  const to = toStr ? new Date(toStr) : null;

  let startDateTime = convertDateTimeToUtc(from, timezone).toISOString();
  let endDateTime = convertDateTimeToUtc(to, timezone).toISOString();

  if (Number(isDaily) === 1) {
    startDateTime = convertDateTimeToUtc(
      setHours(setMinutes(from, 0), 9),
      timezone
    ).toISOString();
    endDateTime = convertDateTimeToUtc(
      setHours(setMinutes(to, 0), 17),
      timezone
    ).toISOString();
  }
  return {
    id: uuid(),
    soId: get(profile, "space_operator_id", ""),
    spaceId: get(profile, "id", ""),
    user: {
      name: user.name || null,
      email: user.email || null,
      uid: user.uid || null,
      companyId: user.companyId || null,
      phone: user.phone || null,
    },
    startDateTime,
    endDateTime,
    resourceId: mrData?._id || mrData?.id,
    resourceName: get(mrData, "name", ""),
    locationId: mrData?.location?.locationId || mrData?.location?.id,
    locationName: mrData?.location?.name || mrData?.location?.space_name,
    timezone,
    fromDate: format(from, "yyyy-MM-dd"),
    toDate: format(to, "yyyy-MM-dd"),
    fromTime: format(from, "hh:mm a"),
    toTime: format(to, "hh:mm a"),
    source: "app.worksimply.com",
    taxRate: get(mrData, "rateDetails[0].tax.taxRate", 0),
    quantity,
    isDaily: Number(isDaily) === 1,
    teamSize: params.get("teamSize"),
  };
};

export default getMeetingRoomReservationData;
