import React from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import s from './index.module.scss';
import resizeImage from './resizeImage';
import { useAuth } from '../../contexts/authContext';

function FileInput({
  file, setFile, accept, maxWidth, maxHeight,
}) {
  const { mvpApi } = useAuth();

  const onChange = async (e) => {
    const images = e.target.files || e.dataTransfer.files;
    if (!images) return;

    const image = images[0];

    const fileNameArr = image.name.split('.');
    const ext = fileNameArr[fileNameArr.length - 1];

    const imageBase64 = await resizeImage(image, maxWidth, maxHeight);

    if (imageBase64.length < 10) {
      return;
    }

    const blobData = await fetch(imageBase64).then((res) => res.blob());

    const response = await mvpApi(
      'get-signed-url',
      {
        Key: `companies/${uuid()}.${ext}`,
        ContentType: image.type,
      },
    );
    await fetch(response.uploadUrl, {
      method: 'PUT',
      body: blobData,
      headers: {
        'Content-Type': image.type,
        'x-amz-acl': 'public-read',
      },
    }).then(() => null).catch(() => null);
    setFile(response.imageUrl);
  };
  return (
    <>
      <input type="file" className={s.input} onChange={onChange} accept={accept} />
      {file && <img src={file} alt="" />}
      <div className={s.message}>Click here to select an image</div>
    </>
  );
}

export default FileInput;

FileInput.propTypes = {
  file: PropTypes.string,
  setFile: PropTypes.func,
  accept: PropTypes.string.isRequired,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
};

FileInput.defaultProps = {
  file: null,
  setFile: () => null,
  maxWidth: 240,
  maxHeight: 100,
};
