import React, { useEffect, useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import get from "lodash/get";
import { Button } from "react-bootstrap";
import { updateCompany } from "../../../services/firebase";
import { useAuth } from "../../../contexts/authContext";
import ProgressButton from "../../../components/ProgressButton";
import CcInput from "./CcInput";

function AddNewCreditCard() {
  const stripe = useStripe();
  const elements = useElements();

  const { company, fetchAndSetUsersCompany, currentUser, mvpApi } = useAuth();

  const [isUpdate, setIsUpdate] = useState(false);

  const [showForm, setShowForm] = useState(false);

  const [billingEmail, setBillingEmail] = useState(company?.billingEmail || "");

  useEffect(() => {
    setIsUpdate(!!company.stripeCardLastFour);
    setShowForm(!company.stripeCardLastFour);
  }, [company]);

  const [progress, setProgress] = useState(false);

  const manageCard = async () => {
    if (!company || !company.id) return;
    setProgress(true);
    const res = await mvpApi("stripe/credit-cards/add", {
      user: {
        name: company?.name || currentUser.displayName,
        email: billingEmail,
      },
      company: isUpdate ? company : {},
    });

    const { stripeCustomerId, clientSecret } = res;

    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: currentUser.displayName,
          email: currentUser.email,
        },
      },
    });
    if (result.error) {
      setProgress(false);
      return;
    }

    const paymentMethods = await mvpApi(
      "stripe/customers/list-payment-methods",
      { customerId: stripeCustomerId },
    );

    const stripePaymentMethodId = get(paymentMethods, "cards.data[0].id", "");
    const stripeCardLastFour = get(
      paymentMethods,
      "cards.data[0].card.last4",
      "",
    );
    const stripeCardType = get(paymentMethods, "cards.data[0].card.brand", "");

    await updateCompany(company.id, {
      stripeCustomerId,
      stripePaymentMethodId,
      stripeCardLastFour,
      stripeCardType,
      billingEmail,
    });

    await fetchAndSetUsersCompany(company.id);
    setProgress(false);
  };
  return (
    <div>
      {isUpdate ? (
        <>
          <h5>Manage Credit Card</h5>
          <p>
            Your card will not be charged at this time. It will be kept on file
            and will be used as the default for future charges.
          </p>

          <input
            type="text"
            readOnly
            className="form-control"
            placeholder={`**** **** **** ${company.stripeCardLastFour} (${company.stripeCardType})`}
          />
          <p className={"my-2"}>
            <small>
              Billing email: {company.billingEmail || currentUser?.email}
              <br />
              To change the email, please change the credit card.
            </small>
          </p>

          <br />
          <hr />
          <br />
        </>
      ) : (
        <>
          <h5>Add New Card</h5>
          <p>
            Your card will not be charged at this time. It will be kept on file
            and will be used as the default for future charges.
          </p>
        </>
      )}

      {!showForm ? (
        <Button onClick={() => setShowForm(true)}>Change credit card</Button>
      ) : (
        <>
          <p>New card details</p>
          <CcInput />
          <br />
          <input
            type="email"
            className="form-control"
            placeholder={"Billing Email"}
            value={billingEmail}
            onChange={(e) => setBillingEmail(e.target.value)}
          />
          <br />
          <ProgressButton
            onClick={manageCard}
            progress={progress}
            buttonLabel={isUpdate ? "Change" : "Add card"}
          />
          {isUpdate && (
            <Button
              onClick={() => setShowForm(false)}
              variant="light"
              className="ms-2"
            >
              Cancel
            </Button>
          )}
        </>
      )}
    </div>
  );
}

export default AddNewCreditCard;
