import axios from 'axios';

const sendToSlack = async (text = '') => {
  // if (process.env.NODE_ENV === 'development') return false;
  const url = 'https://hooks.slack.com/services/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';

  axios
    .post(url, JSON.stringify({ text }), {
      withCredentials: false,
    })
    .then(() => null).catch(() => null);
  return true;
};

export default sendToSlack;
