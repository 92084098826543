import axios from "axios";

const url =
  "https://hooks.slack.com/services/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";

const slackNotify = async (text = "") => {
  if (
    !text ||
    process.env.REACT_APP_STAGE === "demo" ||
    process.env.NODE_ENV === "development" ||
    window.location.hostname !== "app.worksimply.com"
  ) {
    return true;
  }
  return axios
    .post(url, JSON.stringify({ text }), {
      withCredentials: false,
    })
    .then(() => true)
    .catch(() => false);
};

export default slackNotify;
