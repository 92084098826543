import React from "react";
import PropTypes from "prop-types";
import s from "./index.module.scss";

const Message = ({ email, spaceName }) => {
  if (!email || !spaceName) return null;
  return (
    <div className={s.message_box}>
      <p>
        <strong> Have questions about this space? 💡</strong>
        <br />
        <a
          href={`mailto:${email}?subject=Inquiry for ${spaceName} (Worksimply)`}
          target={"_blank"}
          rel="noreferrer"
        >
          Get in touch
        </a>{" "}
        with the space operator
      </p>
    </div>
  );
};

export default Message;

Message.propTypes = {
  email: PropTypes.string,
  spaceName: PropTypes.string.isRequired,
};

Message.defaultProps = {
  email: "",
};
