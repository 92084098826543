const BASE_URL = 'https://d331na0x6n3vy8.cloudfront.net/';

const SM = 640;
const MD = 1200;
const LG = 1920;

const resize = (url, width) => {
  if (!url || !width) {
    return '';
  }
  const URL_CONSTANT = 'https://images.worksimply.ca/';
  const key = url.replace(URL_CONSTANT, '').trim();

  const payload = {
    bucket: 'images.worksimply.ca',
    key,
    edits: {
      resize: {
        width,
        fit: 'cover',
      },
    },
  };

  return BASE_URL + btoa(JSON.stringify(payload));
};

const resizeImage = (image, sm = null, md = null, lg = null, xlg = null) => {
  if (!image) return '';

  const screenWidth = typeof window !== 'undefined' ? window.innerWidth : SM;

  if (screenWidth <= SM || !SM) {
    return resize(image, sm);
  }
  if (screenWidth <= MD) {
    return resize(image, md || sm);
  }
  if (screenWidth <= LG) {
    return resize(image, lg || md || sm);
  }
  return resize(image, xlg || lg || md || sm);
};

export default resizeImage;
