import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { format, formatISO, parseISO } from 'date-fns';
import s from './BookingBlock.module.scss';
import { useAuth } from '../../../contexts/authContext';
import getProfileImage from '../../../utils/getProfileImage';

function UsersList({ list = [], data }) {
  const { companyUsers } = useAuth();
  const [show, setShow] = useState(false);

  const [activeUsers, setActiveUsers] = useState([]);

  useEffect(() => {
    if (!list || !companyUsers || companyUsers.length === 0 || list.length === 0) return;
    const u = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < list.length; i++) {
      const reservation = list[i];
      const user = companyUsers.find((usr) => usr.uid === reservation.userId);
      if (user) {
        u.push(user);
      }
    }
    setActiveUsers(u);
  }, [list, companyUsers]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <ul className="list-unstyled" onClick={handleShow} role="presentation">
        {activeUsers.map((user) => <li key={user.uid}>{getProfileImage(user)}</li>)}
      </ul>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        fullscreen={window.innerWidth < 768}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <h5>
            Colleagues going to
            {' '}
            {data.title}
          </h5>
          <p className="text-muted">
            {data.address}
            {' '}
            |
            {' '}
            {format(parseISO(data.start), 'MMMM d, yyyy')}
          </p>

          <ul className={`${s.users_list} list-unstyled d-flex flex-wrap mt-5`}>
            {activeUsers.map((user) => (
              <li key={user.uid} className="d-flex align-items-center justify-content-start w-50 mt-2">
                {getProfileImage(user)}
                <p className="my-0 ms-2">{user.name || user.email}</p>
              </li>
            ))}

          </ul>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UsersList;

UsersList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
  })),
  data: PropTypes.shape({
    title: PropTypes.string,
    address: PropTypes.string,
    start: PropTypes.string,
  }),
};

UsersList.defaultProps = {
  list: [],
  data: {
    title: '',
    address: '',
    start: formatISO(new Date()),
  },
};
