import React from "react";
import PropTypes from "prop-types";
import {
  IoIosStar as FullStar,
  IoIosStarHalf as HalfStar,
} from "react-icons/io";
import s from "./index.module.scss";

const GoogleReviews = ({ data }) => {
  if (!data?.google_reviews?.score || !data?.google_reviews?.reviews) {
    return null;
  }
  const score = data?.google_reviews?.score || "";
  return (
    <div>
      <h2>Google Reviews</h2>
      <p className={"d-flex align-items-center justify-content-start my-2"}>
        <span className={"d-block me-2"}>{score.toFixed(1)}</span>
        <Stars score={data?.google_reviews?.score} />
      </p>

      <p className={" my-2"}>
        Based on {data?.google_reviews?.user_ratings_total} reviews
      </p>
      <a
        href={`https://search.google.com/local/reviews?placeid=${data?.office_address?.place_id}`}
        target={"_blank"}
        rel={"noreferrer"}
        className={"d-inline-block me-2 text-secondary text-decoration-none"}
      >
        See all reviews
      </a>
      <a
        href={`https://search.google.com/local/writereview?placeid=${data?.office_address?.place_id}`}
        target={"_blank"}
        rel={"noreferrer"}
        className={"d-inline-block me-2 text-secondary text-decoration-none"}
      >
        Write a review
      </a>
      <div className="row">
        {data?.google_reviews?.reviews
          .filter((review) => review.rating > 3)
          .slice(0, 3)
          .map((review, idx) => (
            <div className={"col-sm-6 col-md-4"} key={idx}>
              <div className={s.review_block}>
                <p>{review?.name}</p>
                <Stars score={review?.rating} />
                <small>{review?.time_description}</small>
                <p>{review?.review}</p>
              </div>
            </div>
          ))}
      </div>
      <div className="py-5" />
    </div>
  );
};

export default GoogleReviews;

GoogleReviews.propTypes = {
  data: PropTypes.shape({
    office_address: PropTypes.shape({
      place_id: PropTypes.string,
    }),
    google_reviews: PropTypes.shape({
      score: PropTypes.number.isRequired,
      user_ratings_total: PropTypes.number,
      reviews: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          rating: PropTypes.number,
          time_description: PropTypes.string,
          review: PropTypes.string,
          profile_photo_url: PropTypes.string,
        })
      ),
    }),
  }).isRequired,
};

const Stars = ({ score }) => {
  const isFraction = Math.ceil(score) !== score;
  const fullStars = Math.floor(score);
  return (
    <span className={s.stars}>
      {[...new Array(fullStars).keys()].map((num) => (
        <FullStar key={num} />
      ))}
      {isFraction && <HalfStar />}
    </span>
  );
};

Stars.propTypes = {
  score: PropTypes.number.isRequired,
};
