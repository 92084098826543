import React, { useEffect, useState } from "react";
// import s from "./index.module.scss";
import Message from "../../../LocationProfilePage/Profile/BookingBlock/Message";
import PropTypes from "prop-types";
import get from "lodash/get";
import ProgressButton from "../../../../components/ProgressButton";
import TeamSizePicker from "../../../LocationProfilePage/Profile/BookingBlock/components/TeamSizePicker";
import MeetingRoomsDateFilter from "../../../HomePage/MeetingRoomsDateFilter";
import useQueryParams from "../../../../utils/useQueryParams";
import s from "./index.module.scss";
import { differenceInHours, isValid } from "date-fns";
import formatCurrency from "../../../../utils/formatCurrency";
import modifyHolidays from "../../../../utils/modifyHolidays";

const MeetingRoomsSearchBlock = ({
  mrData,
  showReservation,
  data,
  isAvailable,
  pendingSearch,
  search,
  inProgress,
  readOnly,
  setQuantity,
}) => {
  const [from] = useQueryParams("date", "");
  const [to] = useQueryParams("endDate", "");

  const [isWs] = useQueryParams("isWs", "");

  const isWorksimply = Number(isWs) === 1;

  const [daily] = useQueryParams("isDaily", "1");

  const [hasDaily, setHasSDaily] = useState(false);
  const [hasHourly, setHasHourly] = useState(false);

  const isHourly = Number(daily) === 0;

  const [count, setCount] = useState(1);
  const [frequency, setFreq] = useState("Day");

  const [rate, setRate] = useState(0);

  const [dailyRate, setDailyRate] = useState(0);
  const [hourlyRate, setHourlyRate] = useState(0);

  useEffect(() => {
    setHasSDaily(!!dailyRate);
    setHasHourly(!!hourlyRate);
  }, [dailyRate, hourlyRate]);

  useEffect(() => {
    if (isWorksimply) {
      setDailyRate(mrData?.price_per_day);
      setHourlyRate(mrData?.price_per_hour);
    } else {
      setHourlyRate(get(mrData, "rateDetails[0].hourlyRate.price", 0));
      setDailyRate(get(mrData, "rateDetails[0].dailyRate.price", 0));
    }
  }, [mrData, isWorksimply]);

  useEffect(() => {
    setCount(1);
    setFreq("Day");
    if (!isHourly) {
      setCount(1);
      return;
    }
    if (isValid(new Date(from)) && isValid(new Date(to))) {
      const gap = differenceInHours(new Date(to), new Date(from));
      if (gap * hourlyRate >= dailyRate && dailyRate) {
        setCount(1);
      } else {
        setCount(gap);
        setFreq("Hour");
      }
    }
  }, [isHourly, from, to, dailyRate, hourlyRate]);

  useEffect(() => {
    setQuantity(count);
  }, [count]);

  useEffect(() => {
    if (!isHourly) {
      setRate(dailyRate);
    } else {
      setRate(hourlyRate);
    }
  }, [isHourly, dailyRate, hourlyRate]);

  const email = get(data, "on_demand_emails", "");
  const spaceName = get(data, "space_name", "");

  const meetingRoomCapacity = mrData?.size || mrData?.capacity || 5;

  return (
    <>
      <div className={s.wrapper}>
        <div>
          <h4>
            {readOnly ? (
              `${Math.ceil(rate)} Credits / ${isHourly ? "Hour" : "Day"}`
            ) : (
              <>
                {hourlyRate
                  ? `${formatCurrency(Math.ceil(hourlyRate))} Credits/hour`
                  : null}
                {dailyRate && hourlyRate ? <br /> : null}
                {dailyRate
                  ? `${formatCurrency(Math.ceil(dailyRate))} Credits/day`
                  : null}
              </>
            )}
          </h4>

          <TeamSizePicker
            tsArray={Array.from(
              { length: meetingRoomCapacity - 1 },
              (_, i) => i + 2
            )}
            readOnly={readOnly}
          />
          <MeetingRoomsDateFilter
            isProfile
            readOnly={readOnly}
            hasHourly={hasHourly}
            hasDaily={hasDaily}
            excludeDates={modifyHolidays(data?.customHolidays || [])}
          />

          <>
            {!readOnly &&
              !pendingSearch &&
              !isAvailable &&
              !inProgress &&
              !isWorksimply && (
                <>
                  <hr />
                  <p className="text-center">
                    <small>
                      {!from || !to
                        ? "Please select the date to check availability"
                        : "Not available for this date. Try adjusting your dates."}
                    </small>
                  </p>
                  <hr className={"mb-4"} />
                </>
              )}
            {isAvailable && rate ? (
              <>
                <hr />
                <p className="d-flex">
                  <span>
                    {formatCurrency(Math.ceil(rate))} Credits x {count}{" "}
                    {frequency}
                    {count === 1 ? "" : "s"}
                  </span>
                  <span className="ms-auto">
                    {formatCurrency(Math.ceil(rate) * count)} Credits
                  </span>
                </p>
                <hr />
                <p className="h5 d-flex mb-4">
                  <span>Total</span>
                  <span className="ms-auto">
                    {formatCurrency(Math.ceil(rate) * count)} Credits
                  </span>
                </p>
              </>
            ) : null}
            {/*<div className={"py-2"} />*/}
            {!readOnly && (
              <ProgressButton
                progress={inProgress}
                fullWidth
                disabled={!from || !to}
                onClick={isAvailable || isWorksimply ? showReservation : search}
                buttonLabel={
                  isWorksimply
                    ? "Request to book"
                    : isAvailable
                    ? "Reserve"
                    : "Search Availability"
                  // isAvailable || isWorksimply
                  //   ? "Reserve"
                  //   : "Search Availability"
                }
              />
            )}
          </>
        </div>
        {!readOnly && <Message email={email} spaceName={spaceName} />}

        {isWorksimply && readOnly && <WorksimplyMeetingRoomMessage />}
      </div>
    </>
  );
};

export default MeetingRoomsSearchBlock;

MeetingRoomsSearchBlock.propTypes = {
  mrData: PropTypes.shape({
    meetingRoomId: PropTypes.string,
    price_per_day: PropTypes.number,
    price_per_hour: PropTypes.number,
    capacity: PropTypes.number,
    size: PropTypes.number,
  }),
  showReservation: PropTypes.func,
  data: PropTypes.shape({
    id: PropTypes.string,
    customHolidays: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
      })
    ),
  }),
  isAvailable: PropTypes.bool,
  search: PropTypes.func,
  inProgress: PropTypes.bool,
  pendingSearch: PropTypes.bool,
  readOnly: PropTypes.bool,
  setQuantity: PropTypes.func,
};

MeetingRoomsSearchBlock.defaultProps = {
  data: null,
  isAvailable: false,
  inProgress: false,
  pendingSearch: false,
  readOnly: false,
  search: () => null,
  showReservation: () => null,
  setQuantity: () => null,
};

const WorksimplyMeetingRoomMessage = () => {
  return (
    <div className={"message-box"}>
      <p className={"m-0"}>
        ☝ We will have to confirm the availability of this reservation after
        you request to book.{" "}
      </p>
    </div>
  );
};
