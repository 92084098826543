import get from "lodash/get";
import {
  format,
  formatISO,
  isValid,
  parseISO,
  setHours,
  setMinutes,
  setSeconds,
  parse,
} from "date-fns";

const formatData = (
  currentUser,
  currentUserDoc,
  company,
  data,
  reservationId = null,
  office = {},
  otherPersonName = "",
  invitation = {}
) => {
  const params = new URLSearchParams(window.location.search);
  const instructions = get(data, "on_demand_instructions", "") || "";
  const date = params.get("date");
  const type = params.get("type") || "desk";
  const teamSize = params.get("teamSize");
  const arrivalTime = params.get("arrivalTime");

  const openingTime = get(data, "so.opening_time", "9:00 AM");
  const closingTime = get(data, "so.closing_time", "5:00 PM");

  const price =
    type === "desk"
      ? get(data, "price_per_day_pass", 0)
      : get(office, "price_per_day", 0);

  const parsedDate = isValid(parseISO(date)) ? parseISO(date) : new Date();
  const selectedDate = setMinutes(setHours(setSeconds(parsedDate, 0), 0), 0);

  const formattedSelectedDate = format(selectedDate, "yyyy-MM-dd");
  const start = parse(
    `${formattedSelectedDate} ${arrivalTime || openingTime}`,
    "yyyy-MM-dd h:m a",
    new Date()
  );
  const end = parse(
    `${formattedSelectedDate} ${closingTime}`,
    "yyyy-MM-dd h:m a",
    new Date()
  );

  const reservation = {
    userId: currentUser.uid,
    companyId: company.id,
    locationId: get(data, "id", ""),
    start: formatISO(start),
    end: formatISO(end),
    price,
    type,
    image: get(data, "hero_image_url", ""),
    title: get(data, "space_name", ""),
    address: get(data, "office_address.short_address", ""),
    otherPersonName,
    arrivalTime: arrivalTime || get(data, "so.opening_time", "9:00 AM"),
    allowedDayPassesPerDay: get(data, "day_passes_per_day", 0),
  };
  if (type === "office") {
    reservation.officeId = office.id;
  }
  const mvpApiData = {
    pdfData: {
      logo: company.logo,
      reservationId,
      list1: [
        { value: data.space_name },
        { value: get(data, "office_address.address", "") },
        { value: type === "desk" ? "Day Pass (Desk)" : "Office" },
        {
          value:
            !teamSize || Number(teamSize) === 1
              ? "1 person"
              : `${teamSize} people`,
        },
      ],
      list2: [
        {
          label: "Full name",
          value:
            otherPersonName ||
            get(currentUser, "displayName", "Not provided") ||
            "Not provided",
        },
        { label: "Email", value: get(currentUser, "email", "") },
        {
          label: "Phone number",
          value:
            get(currentUserDoc, "phoneNumber", "Not provided") ||
            "Not provided",
        },
        { label: "Date", value: format(selectedDate, "MMMM d, yyyy") },
        {
          label: "Arrival time",
          value: arrivalTime || get(data, "so.opening_time", "9:00 AM"),
        },
        {
          label: "Checkout time",
          value: get(data, "so.closing_time", "5:00 PM"),
        },
      ],
      instructions: instructions.replace(/(\r\n|\n|\r)/gm, "<br>"),
      hasInstructions: !!instructions,
    },
    reservationData: {
      id: reservationId,
      date: format(selectedDate, "MMMM d, yyyy"),
      phone:
        get(currentUserDoc, "phoneNumber", "Not provided") || "Not provided",
      name: get(currentUser, "displayName", "Not provided") || "Not provided",
      email: get(currentUser, "email", ""),
      total: data.price_per_day_pass,
      otherPersonName,
      type,
    },
    userEmail: currentUser.email,
    location: data,
    office,
    price,
    invitation,
  };

  return { reservation, mvpApiData };
};

export default formatData;
