import React, { useEffect, useState } from 'react';
import isEmail from 'validator/lib/isEmail';
import get from 'lodash/get';
import Layout from '../../layout';
import { useAuth } from '../../contexts/authContext';
import ProgressButton from '../../components/ProgressButton';
import { addUser, getCompanies } from '../../services/firebase';

export const userTypes = [
  {
    slug: 'user',
    name: 'Employee',
  },
  {
    slug: 'admin',
    name: 'Administrator',
  },
];

function AddUser() {
  const { claims, mvpApi } = useAuth();

  const [progress, setProgress] = useState(false);

  const [companies, setCompanies] = useState([]);

  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');

  const [email, setEmail] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [type, setType] = useState('user');

  useEffect(() => {
    getCompanies().then((res) => {
      setCompanies(res);
    });
  }, []);

  const handleAdd = async () => {
    setProgress(true);
    setMessage('');
    const res = await mvpApi('users/add', { email, type });
    const newUserId = get(res, 'user.uid', null);
    // updateUserPassword(email).then(() => null).catch(() => null);
    if (newUserId) {
      await addUser(newUserId, {
        phoneNumber: '',
        companyId,
        userId: res.user.uid,
      });
    }
    setMessage(get(res, 'message', 'Success') || 'Success');
    setMessageType(get(res, 'success', false) ? 'success' : 'danger');
    setProgress(false);
  };

  if (!claims.admin) {
    return null;
  }

  return (
    <Layout title="Add User" onlyAdmin>
      <div className="row">
        <div className="col-lg-6">
          <div className="mb-3">
            <p className="mb-1">Company</p>
            <select
              id="company"
              name="company"
              className="form-select"
              value={companyId}
              onChange={(e) => setCompanyId(e.target.value)}
            >
              <option value="">Please select</option>
              {companies.map((company) => (
                <option
                  key={company.id}
                  value={company.id}
                >
                  {company.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <p className="mb-1">User Type</p>
            <select
              className="form-select"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="">Please select</option>
              {userTypes.map((userType) => (
                <option
                  value={userType.slug}
                  key={userType.slug}
                >
                  {userType.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <p className="mb-1">Email</p>
            <input
              type="text"
              className="form-control"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <ProgressButton
            buttonLabel="Add"
            progress={progress}
            onClick={handleAdd}
            disabled={!companyId || !type || !email || !isEmail(email)}
          />

          {message && (
            <div className={`alert alert-${messageType} mt-4`} role="alert">
              {message}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default AddUser;
