import React, { useEffect, useState } from "react";
import Layout from "../../../layout";
import { useParams } from "react-router-dom";
import {
  addRedeem,
  deleteRedeem,
  getMyPoints,
  getUserRewardData,
} from "../../../services/firebase";
import { format } from "date-fns";
import { Button } from "react-bootstrap";
import { useAuth } from "../../../contexts/authContext";

const Rewards = () => {
  const { uid } = useParams();

  const { mvpApi } = useAuth();

  const [points, setPoints] = useState(0);

  const [user, setUser] = useState({});

  const [rewardPoints, setRewardPoints] = useState([]);
  const [redeems, setRedeems] = useState([]);

  const [currentRedeem, setCr] = useState(0);

  const [progress, setProgress] = useState(false);

  useEffect(() => {
    if (!uid) return;
    mvpApi("users/get", { uid }).then((res) => {
      setUser(res.users);
    });
    updateData();
  }, [uid]);

  const updateData = async () => {
    await getUserRewardData(uid).then((res) => {
      setRewardPoints(res.points || []);
      setRedeems(res.redeems || []);
    });
    await getMyPoints(uid).then((res) => {
      setPoints(res.redeemablePoints);
      setCr(res.redeemablePoints);
    });
  };

  const handleRedeem = async () => {
    setProgress(true);
    await addRedeem(uid, Number(currentRedeem));
    await updateData();
    setProgress(false);
  };

  const handleDelete = async (id) => {
    setProgress(true);
    await deleteRedeem(id);
    await updateData();
    setProgress(false);
  };

  return (
    <Layout title="Manage Rewards">
      <h4>Remaining Points: {points}</h4>
      {user?.uid && (
        <small>
          {user?.displayName || "N/A"} of {user?.company?.name}
          <br />
          {user?.email}
        </small>
      )}

      <br />

      <hr />
      <div className="d-flex align-items-center">
        <div className={"me-2"}>
          <input
            type="number"
            className="form-control"
            value={currentRedeem}
            onChange={(e) => setCr(e.target.value)}
          />
        </div>
        <Button onClick={handleRedeem} disabled={!currentRedeem || progress}>
          Redeem
        </Button>
      </div>
      <hr />

      {redeems.length > 0 && (
        <>
          <h5>Redeem History</h5>
          <table className="table">
            <thead>
              <tr>
                <th>Created At</th>
                <th>Points</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {redeems.map((reward) => (
                <tr key={reward.id}>
                  <td>
                    {format(
                      new Date(reward?.createdAt?.seconds * 1000),
                      "MMM dd, yyyy h:mm a"
                    )}
                  </td>
                  <td>{reward.points}</td>
                  <td>
                    <Button
                      variant={"link"}
                      className={"text-danger p-0"}
                      onClick={() => handleDelete(reward.id)}
                      disabled={progress}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}

      <br />

      {rewardPoints.length > 0 && (
        <>
          <h5>Point History</h5>
          <table className="table">
            <thead>
              <tr>
                <th>Booking Date</th>
                <th>Points</th>
              </tr>
            </thead>
            <tbody>
              {rewardPoints.map((reward) => (
                <tr key={reward.id}>
                  <td>
                    {format(new Date(reward.effectiveDate), "MMM dd, yyyy")}
                  </td>
                  <td>{reward.points}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </Layout>
  );
};

export default Rewards;
