import worksimplyApi from "../../../../../services/worksimplyApi";
import formatCurrency from "../../../../../utils/formatCurrency";
import adminEmail from "../../../../../utils/adminEmail";
import { formatTime } from "./sendNotificationToUser";

const sendNotificationToJaime = async (res, company) => {
  const fromDate = res.from.split("T")[0];
  const toDate = res.to.split("T")[0];
  await worksimplyApi("handle-form", {
    to: adminEmail(),
    userEmail: res?.user_email,
    sp_slug: "worksimply",
    replyTo: res?.user_email,
    emailTitle: `New Meeting Room Booking Request`,
    emailData: [
      {
        label: "Meeting Room Name",
        value: res.meeting_room_name,
      },
      {
        label: "Location Name",
        value: res.location_name,
      },
      {
        label: "From",
        value: `${fromDate} ${formatTime(res.arrival_time)}`,
      },
      {
        label: "To",
        value: `${toDate} ${formatTime(res.departure_time)}`,
      },
      {
        label: "Price",
        value: `$${formatCurrency(res.price / 100)}`,
      },
      {
        label: "Applied Tax",
        value: `$${formatCurrency(res.tax / 100)}`,
      },
      {
        label: "Total with Tax",
        value: `$${formatCurrency(res.total / 100)}`,
      },
      {
        label: "Service Fee",
        value: `$${formatCurrency(res.service_fee / 100)}`,
      },
      {
        label: "User's Name",
        value: res?.user_name || "N/A",
      },
      {
        label: "User's Email",
        value: res?.user_email || "N/A",
      },
      {
        label: "User's Company",
        value: company?.name || "N/A",
      },
    ],
  });
  return true;
};

export default sendNotificationToJaime;
