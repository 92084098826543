import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import WorksimplyProfileData from "./WorksimplyProfileData";
import MeetingRoomsSearchBlock from "./SearchBlock";
import { Button, Modal } from "react-bootstrap";
import useQueryParams from "../../../utils/useQueryParams";
import getQueryParams from "../../../utils/getQueryParams";
import convertDateTimeToUtc from "../../../utils/convertDateTimeToUtc";
import worksimplyApi from "../../../services/worksimplyApi";
import ReservationPage from "./ReservationPage";
import s from "./indes.module.scss";

const MeetingRoomProfile = ({ data, mrData }) => {
  const { name, image, amenities, cover_image_url: coverImageUrl } = mrData;

  const [checkoutPage, setCheckoutPage] = useState(false);

  const [isWs] = useQueryParams("isWs", "0");

  const isWorksimply = Number(isWs) === 1;

  const [daily] = useQueryParams("isDaily", "1");

  const [isAvailable, setIsAvailable] = useState(true);
  const [pendingSearch, setPendingSearch] = useState(false);

  const [inProgress, setInProgress] = useState(false);

  const [resModal, setResModal] = useState(false);

  const handleModalClose = () => setResModal(false);

  const soName = get(data, "so.name", null);

  const [from] = useQueryParams("date", "");
  const [to] = useQueryParams("endDate", "");

  useEffect(() => {
    setIsAvailable(false);
    setPendingSearch(true);
  }, [from, to]);

  useEffect(() => {
    search();
  }, []);

  const search = async () => {
    if (!from || !to || isWorksimply) return;
    setIsAvailable(false);
    setInProgress(true);
    const params = getQueryParams();

    const tz =
      get(mrData, "location.timezone", "America/Toronto") || "America/Toronto";

    const reqData = {
      name: name || params.get("meeting_room_name") || null,
      locationId: get(mrData, "location.locationId") || null,
      teamSize: params.get("teamSize") || 1,
      from: from ? convertDateTimeToUtc(from, tz).toISOString() : null,
      to: to ? convertDateTimeToUtc(to, tz).toISOString() : null,
      soId: get(data, "space_operator_id", null),
    };

    const res = await worksimplyApi("meeting-rooms/search", reqData)
      .then((res) => res)
      .catch(() => null);

    const rates = ["hourlyRate", "dailyRate"];

    setIsAvailable(
      get(res, "meetingRooms[0].meeting_rooms[0].meetingRoomId", "na1") ===
        get(mrData, "_id", "na2") &&
        get(res, `meetingRooms[0].meeting_rooms[0].rates[${rates[daily]}]`, 0)
    );
    setPendingSearch(false);
    setInProgress(false);
  };

  if (checkoutPage) {
    return (
      <ReservationPage
        data={data}
        back={() => setCheckoutPage(false)}
        office={{}}
        mrData={mrData}
        isMeetingRoomCheckout
      />
    );
  }

  return (
    <div className={"container"}>
      <h1 className={"mb-4 pb-3"}>
        {name} {soName ? `at ${soName}` : ""}
      </h1>
      <div className="row">
        <div className="col-lg-8">
          <div className={s.meeting_room_hero_image}>
            <img src={image || coverImageUrl} alt={name} className={"w-100"} />
          </div>

          {amenities.length > 0 && (
            <div>
              <h2 className={"mt-5 mb-5"}>Meeting room amenities include</h2>
              <div className="row">
                {amenities.map((item) => {
                  return (
                    <div
                      key={item._id || item.title}
                      className="col-sm-6 col-xl-4 mb-4 mb-lg-5"
                    >
                      <p className="my-0">{item.title}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {data.id && <WorksimplyProfileData data={data} />}
        </div>

        <div className="col-lg-4 d-none d-lg-block">
          <MeetingRoomsSearchBlock
            mrData={mrData}
            showReservation={() => setCheckoutPage(true)}
            isAvailable={isAvailable}
            search={search}
            inProgress={inProgress}
            pendingSearch={pendingSearch}
            data={data}
          />
        </div>
      </div>

      <div className="position-sticky bottom-0 bg-white py-2 border-top">
        <div className="container">
          <div className="d-flex align-items-center">
            <div></div>
            <Button
              variant="primary"
              className="ms-auto"
              onClick={() => setResModal(true)}
            >
              Reserve
            </Button>
          </div>
        </div>
      </div>

      <Modal show={resModal} onHide={handleModalClose} centered fullscreen>
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="container mt-5">
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6">
                <MeetingRoomsSearchBlock
                  mrData={mrData}
                  showReservation={() => setCheckoutPage(true)}
                  isAvailable={isAvailable}
                  search={search}
                  inProgress={inProgress}
                  pendingSearch={pendingSearch}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MeetingRoomProfile;

MeetingRoomProfile.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
  }),
  mrData: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string.isRequired,
    image: PropTypes.string,
    cover_image_url: PropTypes.string,
    amenities: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        title: PropTypes.string,
        icon: PropTypes.string,
      })
    ),
  }).isRequired,
};

MeetingRoomProfile.defaultProps = {
  data: PropTypes.shape({
    id: null,
  }),
};
