import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Layout from "../../layout";
import worksimplyApi from "../../services/worksimplyApi";
import Profile from "./Profile";
import Spinner from "../../components/Spinner";
import isUUID from "validator/es/lib/isUUID";

function LocationProfilePage() {
  const { profileId } = useParams();

  const invalidProfile = !profileId || !isUUID(profileId, 4);

  const [profile, setProfile] = useState({});
  const [inProgress, setInProgress] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (invalidProfile) {
      return;
    }
    worksimplyApi("view-sp", {
      id: profileId,
    })
      .then((res) => {
        setProfile(res.space);
        setInProgress(false);
      })
      .catch(() => {
        setError(true);
        setInProgress(false);
      });
  }, [profileId]);

  if (invalidProfile || error) {
    return (
      <div className="container text-center mt-5">
        <h5>Invalid Profile</h5>
        <br />
        <Link to="/" className="btn btn-primary btn-sm">
          Back to home
        </Link>
      </div>
    );
  }

  return (
    <Layout title="" profilePage>
      {inProgress ? <Spinner /> : <Profile data={profile} error={error} />}
    </Layout>
  );
}

export default LocationProfilePage;
