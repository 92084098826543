import React from "react";
import PropTypes from "prop-types";
import Datepicker from "../components/Datepicker";
import TentativeArrivalTime from "../components/TentativeArrivalTime";
import modifyHolidays from "../../../../../utils/modifyHolidays";

function DayPassesBookingBlock({ data, showTentativeArrivalTime, readOnly }) {
  return (
    <div>
      <h4>{data.price_per_day_pass} Credits/day</h4>
      <Datepicker
        isLast={!showTentativeArrivalTime}
        readOnly={readOnly}
        excludeDates={modifyHolidays(data?.customHolidays || [])}
      />
      {showTentativeArrivalTime && <TentativeArrivalTime data={data} />}
      <hr />
      <p className="d-flex">
        <span>{data.price_per_day_pass} Credits x 1 Day</span>
        <span className="ms-auto">{data.price_per_day_pass} Credits</span>
      </p>
      <hr />
      <p className="h5 d-flex">
        <span>Total</span>
        <span className="ms-auto">{data.price_per_day_pass} Credits</span>
      </p>
    </div>
  );
}

export default DayPassesBookingBlock;

DayPassesBookingBlock.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    price_per_day_pass: PropTypes.number.isRequired,
    customHolidays: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  showTentativeArrivalTime: PropTypes.bool,
  readOnly: PropTypes.bool,
};

DayPassesBookingBlock.defaultProps = {
  showTentativeArrivalTime: false,
  readOnly: false,
};
