import React from 'react';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { Dropdown } from 'react-bootstrap';
import { HiOutlineDotsHorizontal as ActionsIcon } from 'react-icons/hi';
import getProfileImage from '../../utils/getProfileImage';
import s from './index.module.scss';
import { useAuth } from '../../contexts/authContext';
import { deleteUser } from '../../services/firebase';

function UserTable() {
  const {
    companyUsers, resetPassword, fetchCompanyUsers, currentUser, mvpApi,
  } = useAuth();

  const admins = companyUsers.filter(({ role }) => role === 'admin').length;
  const users = companyUsers.filter(({ role }) => role === 'user').length;

  if (companyUsers.length === 0) return null;

  return (
    <>
      <h5 className="mt-5">
        Total:
        {' '}
        {companyUsers.length}
        {' '}
        members
      </h5>
      <p>
        (
        {admins}
        {' '}
        administrator
        {admins === 1 ? '' : 's'}
        {' '}
        ,
        {' '}
        {users}
        {' '}
        employee
        {users === 1 ? '' : 's'}
        )
      </p>
      <div className="table-responsive pb-5">
        <table className="table mt-4">
          <thead>
            <tr>
              <th>Name</th>
              <th className="text-center">Account Type</th>
              <th className="text-center">Last Sign-in Time</th>
              <th className="text-center"><span className="opacity-0">Actions</span></th>
            </tr>
          </thead>
          <tbody>
            {companyUsers.map((user) => {
              const {
                uid, email: userEmail, role, name, lastSignInTime,
              } = user || {};
              const isAdmin = role === 'admin';
              const sendPasswordResetEmail = async (e) => {
                e.preventDefault();
                // toast('Sending Email', { type: 'info', autoClose: 2000 });
                await resetPassword();
                toast(`Password reset email was sent to  ${userEmail}`, { type: 'success' });
              };
              const deleteUsr = async (e) => {
                e.preventDefault();
                if (currentUser.uid === uid) return;
                toast(`Deleting user ${userEmail}`, { type: 'info', autoClose: 1500 });
                await deleteUser(uid);
                await mvpApi('users/delete', { uid });
                await fetchCompanyUsers();
                toast(`Deleted user ${userEmail}`, { type: 'warning' });
              };
              const changeRole = async (e) => {
                e.preventDefault();
                if (currentUser.uid === uid) return;
                const newRole = isAdmin ? 'user' : 'admin';
                toast(`Changing user role of ${userEmail}`, { type: 'info', autoClose: 1500 });
                await mvpApi('users/update', { uid, newRole });
                toast.success('User role changed');
                window.location.reload();
              };
              return (
                <tr key={uid}>
                  <td className="align-middle">
                    <div className={s.name}>
                      <div>
                        {getProfileImage(user)}
                      </div>
                      <div>
                        {name || 'N/A'}
                        <br />
                        <small>{userEmail}</small>
                      </div>
                    </div>
                  </td>
                  <td className="align-middle text-center">{role === 'admin' ? 'Administrator' : 'Employee'}</td>
                  <td
                    className="align-middle text-center"
                  >
                    {lastSignInTime ? format(new Date(lastSignInTime), 'MMM dd, hh:mm a') : 'N/A'}
                  </td>
                  <td className="align-middle text-center">
                    {currentUser.uid !== uid ? (
                      <Dropdown align="start" drop="start">
                        <Dropdown.Toggle variant="default" id="dropdown-basic" className={s.no_caret}>
                          <ActionsIcon />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>

                          <Dropdown.Item href="#/action-1" onClick={changeRole}>
                            Change role to
                            {' '}
                            {isAdmin ? 'Employee' : 'Administrator'}
                          </Dropdown.Item>

                          <Dropdown.Item href="#/action-1" onClick={sendPasswordResetEmail}>
                            Send password reset
                            email
                          </Dropdown.Item>
                          {currentUser.uid !== uid
                          && <Dropdown.Item href="#/action-2" onClick={deleteUsr}>Delete</Dropdown.Item>}
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : 'N/A'}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default UserTable;
