import { addDays, startOfDay, getDay } from 'date-fns';

const getDefaultDate = (count = 0) => {
  const today = new Date();

  let excludeWeekends = 0;

  const day = getDay(today);

  if (day === 6) {
    // Saturday
    excludeWeekends = count === 0 ? 2 : 1;
  }
  if (day === 5) {
    // Friday
    excludeWeekends = count === 0 ? 3 : 2;
  }

  const selectedDefaultDate = addDays(today, count + excludeWeekends);
  return startOfDay(selectedDefaultDate);
};

export default getDefaultDate;
