import React, { useEffect, useState } from "react";
import sumBy from "lodash/sumBy";
import PropTypes from "prop-types";
import { addMonths, format, parseISO } from "date-fns";
import get from "lodash/get";
import { CSVLink } from "react-csv";
import Layout from "../../layout";
import { useAuth } from "../../contexts/authContext";
import { getCompanyDeskBookingsForThisMonth } from "../../services/firebase";
import Spinner from "../../components/Spinner";

const headers = [
  { label: "ID", key: "id" },
  { label: "Name", key: "name" },
  { label: "Date", key: "date" },
  { label: "Location", key: "location" },
  { label: "Type", key: "type" },
  { label: "Total Spent", key: "price" },
];

function Reports() {
  const { company, companyUsers } = useAuth();
  const [progress, setProgress] = useState(false);
  const [details, setDetails] = useState({
    totalBookingsThisMonth: 0,
    totalSpentThisMonth: 0,
  });
  const [bookings, setBookings] = useState([]);
  const [month, setMonth] = useState(format(new Date(), "yyyy-MM"));
  const [formattedBookings, setFormattedBookings] = useState([]);

  useEffect(() => {
    if (!company || !company.id) return;
    setBookings([]);
    setProgress(true);
    getCompanyDeskBookingsForThisMonth(company.id, month).then((res) => {
      setDetails({
        totalBookingsThisMonth: res.length,
        totalSpentThisMonth: sumBy(res, (n) => n.total || n.price || 0),
      });
      setBookings(res);
      setProgress(false);
    });
  }, [company, month]);

  useEffect(() => {
    if (companyUsers.length === 0) return;
    setFormattedBookings(
      bookings.map((booking) => {
        const user = companyUsers.find((u) => u.uid === booking.userId);
        let type = "Day Office";
        if (booking?.type === "desk") {
          type = "Desk";
        } else if (booking?.type === "meeting-room") {
          type = "Meeting Room";
        }
        return {
          id: booking.id,
          name: get(user, "name", null) || get(user, "email", "N/A"),
          date: format(parseISO(booking.start), "MMMM d, yyyy"),
          location: booking.title,
          type,
          price: `$${booking?.total || booking.price || 0}`,
        };
      })
    );
  }, [bookings, companyUsers]);

  return (
    <Layout
      title="Reports"
      pageLinks={[
        {
          label: "Monthly Summary",
          link: "/reports",
        },
      ]}
      allowedRole="admin"
    >
      <div className="row">
        <div className="col-sm-8 col-md-6 col-lg-5 col-xl-4 mb-3">
          <select
            disabled={progress}
            className="form-select"
            onChange={(e) => setMonth(e.target.value)}
          >
            {[0, -1, -2, -3, -4].map((number) => (
              <option
                value={format(addMonths(new Date(), number), "yyyy-MM")}
                key={number}
              >
                {format(addMonths(new Date(), number), "MMM, yyyy")}
              </option>
            ))}
          </select>
        </div>
      </div>
      {!progress && companyUsers && companyUsers.length > 0 ? (
        <>
          <div className="row justify-content-center">
            <Card
              label="Bookings this month"
              value={`${details.totalBookingsThisMonth}`}
            />
            <Card
              label="Total spent this month"
              value={`$${Number(details.totalSpentThisMonth).toLocaleString(
                "en-US"
              )}`}
            />
            <Card
              label="Average bookings per employee"
              value={`${(
                details.totalBookingsThisMonth / companyUsers.length
              ).toFixed(1)}`}
            />
            <Card
              label="Average budget spent per employee"
              value={`$${Number(
                (details.totalSpentThisMonth / companyUsers.length).toFixed(2)
              ).toLocaleString("en-US")}`}
            />
          </div>
          {formattedBookings && formattedBookings.length > 0 && (
            <>
              <CSVLink
                headers={headers}
                data={formattedBookings}
                filename={`${format(new Date(), "yyyy-MMMM")}.csv`}
                className="btn btn-primary btn-sm ml-auto"
              >
                Download CSV
              </CSVLink>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      {headers.map(({ key, label }) => {
                        if (key === "id") return null;
                        return <th key={key}>{label}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {formattedBookings.map((booking) => {
                      return (
                        <tr key={booking.id}>
                          <td>{booking.name}</td>
                          <td>{booking.date}</td>
                          <td>{booking.location}</td>
                          <td>{booking.type}</td>
                          <td>{booking.price}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </>
      ) : (
        <div className="position-relative py-5">
          <Spinner />
        </div>
      )}
    </Layout>
  );
}

export default Reports;

function Card({ label = "", value = "" }) {
  return (
    <div className="col-sm-6 col-md-4 col-xl-3 mb-4">
      <div className="card text-center">
        <div className="card-body">
          <h5 className="h1 card-title">{value}</h5>
        </div>
        <div className="card-footer text-muted">{label}</div>
      </div>
    </div>
  );
}

Card.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
