import React from 'react';

function ErrorMessage() {
  return (
    <div>
      Error message
    </div>
  );
}

export default ErrorMessage;
