import worksimplyApi from "../../../../../services/worksimplyApi";
import adminEmail from "../../../../../utils/adminEmail";

const sendNotificationToUser = async (res) => {
  const fromDate = res.from.split("T")[0];
  const toDate = res.to.split("T")[0];
  await worksimplyApi("handle-form", {
    to: res?.user_email,
    userEmail: res?.user_email,
    sp_slug: "worksimply",
    replyTo: adminEmail(),
    emailTitle: "Meeting Room Booking Request",
    emailBodyDescription:
      "We got your request. We will confirm the status of your booking within 24 hours.",
    emailData: [
      {
        label: "Meeting Room Name",
        value: res.meeting_room_name,
      },
      {
        label: "Location Name",
        value: res.location_name,
      },
      {
        label: "From",
        value: `${fromDate} ${formatTime(res.arrival_time)}`,
      },
      {
        label: "To",
        value: `${toDate} ${formatTime(res.departure_time)}`,
      },
    ],
  });
  return true;
};

export default sendNotificationToUser;

export const formatTime = (time) => {
  if (!time) return "";
  const timeArray = time.split(":");
  if (timeArray.length === 3) {
    return `${timeArray[0]}:${timeArray[1]}`;
  }
  return time;
};
