import React from 'react';

const nl2br = (str = '') => str.split('\n').map((line, idx) => (
  // eslint-disable-next-line react/no-array-index-key
  <React.Fragment key={idx}>
    {idx !== 0 ? <br /> : ''}
    {line}
  </React.Fragment>
));

export default nl2br;
