import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { ImFacebook2 as FacebookIcon, ImInstagram as InstagramIcon } from 'react-icons/im';
import { FaLinkedinIn as LinkedInIcon } from 'react-icons/fa';

function Footer({ fullWidth, profilePage, noFooterTopMargin }) {
  return (
    <div className={`container${fullWidth ? '-fluid' : ''}`}>
      <hr className={profilePage || noFooterTopMargin ? 'mt-0' : ''} />
      <div className="d-sm-flex">
        <p>
          <small>
            <span className="d-block d-md-inline-block">
              &copy;
              {' '}
              {format(new Date(), 'yyyy')}
              {' '}
              Worksimply Inc. All rights reserved.
            </span>
            <a
              href="https://worksimply.com/terms-of-use"
              target="_blank"
              rel="noreferrer"
              className="d-inline-block text-dark mt-2 me-3 mx-md-3"
            >
              Terms of Use
            </a>
            <a
              href="https://worksimply.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
              className="d-inline-block text-dark mt-2"
            >
              Privacy Policy
            </a>
          </small>
        </p>
        <div className="ms-sm-auto">
          <ul className="list-unstyled d-flex">
            <li className="me-2">
              <a
                className="text-dark"
                href="https://ca.linkedin.com/company/worksimply"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedInIcon />
              </a>
            </li>
            <li className="mx-2">
              <a
                className="text-dark"
                href="https://www.instagram.com/worksimplyhq/"
                target="_blank"
                rel="noreferrer"
              >
                <InstagramIcon />
              </a>
            </li>
            <li className="mx-2">
              <a
                className="text-dark"
                href="https://www.facebook.com/worksimply"
                target="_blank"
                rel="noreferrer"
              >
                <FacebookIcon />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Footer;

Footer.propTypes = {
  fullWidth: PropTypes.bool,
  profilePage: PropTypes.bool,
  noFooterTopMargin: PropTypes.bool,
};

Footer.defaultProps = {
  fullWidth: false,
  profilePage: false,
  noFooterTopMargin: false,
};
