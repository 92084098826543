import React from "react";
import get from "lodash/get";
import { GoogleMap, Marker } from "../../../components/GoogleMap";
import ProfileDescription from "../../LocationProfilePage/Profile/ProfileDescription";
import Amenities from "../../LocationProfilePage/Profile/Amenities";
import PropTypes from "prop-types";
import Images from "../../LocationProfilePage/Profile/Images";
import GoogleReviews from "../../LocationProfilePage/Profile/GoogleReviews";

const WorksimplyProfileData = ({ data }) => {
  return (
    <div>
      <hr className={"mt-5 mb-0"} />

      <div className="py-5" />

      <ProfileDescription data={data} />

      <div className="py-5" />

      <Images data={data} />

      <div className="py-5" />

      <Amenities data={data} />

      <div className="py-5" />

      <GoogleReviews data={data} />

      <h2 className="mt-5">Location</h2>
      <p>{get(data, "office_address.address", null)}</p>
      <div className="position-relative w-100 mb-5" style={{ height: "350px" }}>
        {process.env.NODE_ENV === "production" && (
          <GoogleMap
            center={{
              lat: get(data, "office_address.lat", null),
              lng: get(data, "office_address.lng", null),
            }}
          >
            <Marker
              lat={get(data, "office_address.lat", null)}
              lng={get(data, "office_address.lng", null)}
            />
          </GoogleMap>
        )}
      </div>
      <div className="py-5" />
    </div>
  );
};

export default WorksimplyProfileData;

WorksimplyProfileData.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    space_title: PropTypes.string.isRequired,
    space_description: PropTypes.string.isRequired,
    office_address: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
      address: PropTypes.string.isRequired,
    }),
    wifi: PropTypes.bool,
    furnished: PropTypes.bool,
    pet_friendly: PropTypes.bool,
    full_kitchen_access: PropTypes.bool,
    coffee: PropTypes.bool,
    high_speed_internet: PropTypes.bool,
    printing: PropTypes.bool,
    mail_box: PropTypes.bool,
    common_areas: PropTypes.bool,
    breakout_rooms: PropTypes.number,
    meeting_rooms: PropTypes.number,
    phone_booths: PropTypes.number,
    ergonomic_chairs: PropTypes.bool,
  }).isRequired,
};
