import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

function Metadata({
  description, lang, meta, title,
}) {
  const metaDescription = description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ].concat(meta)}
    />
  );
}

Metadata.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
};

Metadata.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    content: PropTypes.string,
  })),
  title: PropTypes.string.isRequired,
};

export default Metadata;
