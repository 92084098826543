import React, { useState } from "react";
import get from "lodash/get";
import axios from "axios";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Layout from "../../layout";
import s from "./index.module.scss";
import { useAuth } from "../../contexts/authContext";
import getProfileImage from "../../utils/getProfileImage";
import ProgressButton from "../../components/ProgressButton";
import FileInput from "../../components/FileInput";
// import PostalCode from './PostalCode';
import {
  updateCustomUserData,
  updateUserPassword,
  updateUserProfile,
} from "../../services/firebase";
import { cities } from "../../layout/Header/CityFilter";

function UserProfilePage() {
  const { currentUser, currentUserDoc, claims } = useAuth();

  // const [metadataProgress, setMetadataProgress] = useState(false);

  const [displayName, setDisplayName] = useState(
    get(currentUser, "displayName", "") || ""
  );
  const [phoneNumber, setPhoneNumber] = useState(
    get(currentUserDoc, "phoneNumber", "") || ""
  );

  const [userImage, setUserImage] = useState("");

  const [passwordProgress, setPasswordProgress] = useState(false);

  const updatePassword = async () => {
    setPasswordProgress(true);
    await updateUserPassword(currentUser.email);
    setPasswordProgress(false);
  };

  const handleProfileImage = async (photoURL) => {
    await updateUserProfile({ photoURL });
    setUserImage(photoURL);
  };

  const incompleteProfile = !claims.admin && !currentUser.displayName;

  const isAdmin = claims?.role === "admin" || false;

  const pageLinks = [
    {
      label: "Profile",
      link: "/user/account",
    },
  ];

  if (isAdmin) {
    pageLinks.push({
      label: "Integrations",
      link: "/user/integrations",
    });
  }

  return (
    <Layout
      title="Account"
      subTitle={currentUser.email}
      pageLinks={pageLinks}
      message={
        incompleteProfile
          ? "Please add your name (mandatory), phone number, city and postal code to proceed to bookings."
          : ""
      }
    >
      <div className="row">
        <div className="col-md-8 col-lg-6">
          <h5 className="mt-0 mb-2">Profile photo</h5>

          <div>
            <div className={s.user_image}>
              {getProfileImage(currentUser, userImage)}
              <FileInput
                file={currentUser.photoURL || ""}
                setFile={handleProfileImage}
                accept="image/png, image/jpeg"
              />
            </div>
          </div>

          <h5 className="mt-5 mb-2">Name</h5>
          <p>The name that appears in your bookings</p>
          <input
            type="text"
            className="form-control"
            placeholder="First Name & Last Name"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
          />

          <h5 className="mt-5 mb-2">Phone number</h5>
          <p>The phone number that appears in your bookings</p>
          <input
            type="text"
            className="form-control mb-4"
            placeholder="Phone number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />

          <PostalCode
            displayName={displayName}
            phoneNumber={phoneNumber}
            disabled={!displayName}
          />

          <hr className="my-5" />

          <h5 className="mb-2">Password</h5>

          <ProgressButton
            variant="primary"
            onClick={updatePassword}
            progress={passwordProgress}
            buttonLabel="Send password reset email"
          />
          <br />
          <br />
          <br />
        </div>
      </div>
    </Layout>
  );
}

export default UserProfilePage;

function PostalCode({ title, displayName, phoneNumber, disabled }) {
  const { currentUserDoc } = useAuth();
  const [checkingPostalCode, setCpc] = useState(false);
  const [postalCode, setPostalCode] = useState(
    get(currentUserDoc, "postalCode", "") || ""
  );
  const [city, setCity] = useState(get(currentUserDoc, "city", "") || "");
  const getDataForPostalCode = async () => {
    setCpc(true);

    if (displayName) {
      await updateUserProfile({ displayName });
    }
    if (currentUserDoc.userId && phoneNumber) {
      await updateCustomUserData(currentUserDoc.userId, { phoneNumber });
    }

    let formattedPostalCode = postalCode.toUpperCase();

    if (formattedPostalCode.length === 6) {
      formattedPostalCode = `${formattedPostalCode.substring(
        0,
        3
      )} ${formattedPostalCode.substring(3)}`;
    }

    const data = await axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&components=postal_code:${formattedPostalCode}`
      )
      .then((res) => {
        const lat = get(res, "data.results[0].geometry.location.lat", null);
        const lng = get(res, "data.results[0].geometry.location.lng", null);

        if (!lat || !lng) {
          return null;
        }
        return { lat, lng };
      })
      .catch(() => null);
    if (!get(data, "lat", null) || !get(data, "lng", null)) {
      setCpc(false);
      toast(
        "Couldn't fecth details for the postal code. Please check your postal code again." +
          " If this persists, please contact administrator.",
        { type: "info" }
      );
      return;
    }
    await updateCustomUserData(currentUserDoc.userId, {
      lat: data.lat,
      lng: data.lng,
      city,
      postalCode,
    });
    toast("Updated", { type: "success" });
    setCpc(false);
    window.location.reload();
  };

  return (
    <>
      <h5 className="mt-5 mb-2">{title}</h5>
      <p>
        The city and the postal code is used to display the locations that are
        closest to you.
      </p>
      <select
        className="form-select mb-3"
        value={city}
        onChange={(e) => setCity(e.target.value)}
      >
        <option value="" disabled>
          Please select
        </option>
        {cities.map(({ label, value }) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </select>
      <input
        type="text"
        className="form-control mb-4 text-uppercase"
        placeholder="Postal code"
        value={postalCode}
        onChange={(e) => setPostalCode(e.target.value)}
      />

      <ProgressButton
        buttonLabel="Save"
        onClick={getDataForPostalCode}
        progress={checkingPostalCode}
        disabled={!postalCode || !city || disabled}
      />
    </>
  );
}

PostalCode.propTypes = {
  title: PropTypes.string,
  disabled: PropTypes.bool,
  displayName: PropTypes.string,
  phoneNumber: PropTypes.string,
};

PostalCode.defaultProps = {
  title: "My Location",
  disabled: false,
  displayName: "",
  phoneNumber: "",
};
