import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

function GetProfileImage(user = {}, image = '') {
  if (!get(user, 'uid', false)) return '';
  const {
    photoURL, email, displayName, name,
  } = user;
  const userName = displayName || name;
  if (photoURL || image) return <img src={photoURL || image} alt={displayName || ''} />;
  if (userName) {
    const names = userName.split(' ');

    if (names.length === 1) {
      return <p>{userName.substring(0, 2).toUpperCase()}</p>;
    }
    if (names.length > 1) {
      return (
        <p>{`${names[0].substring(0, 1)}${names[1].substring(0, 1)}`.toUpperCase()}</p>
      );
    }
    return (
      <p>{userName.substring(0, 2).toUpperCase()}</p>
    );
  }
  return (
    <p>{email.substring(0, 2).toUpperCase()}</p>
  );
}

export default GetProfileImage;

GetProfileImage.propTypes = {
  user: PropTypes.shape({
    photoURL: PropTypes.string,
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string,
  }),
};

GetProfileImage.defaultProps = {
  user: {
    photoURL: null,
    displayName: null,
  },
};
