import React from 'react';
import { isBefore, isSameDay, parseISO } from 'date-fns';
import Layout from '../../layout';
import NoBookings from './NoBookings';
import Bookings from './Bookings';
import NoPastBookings from './no-past-bookings.png';
import getDefaultDate from '../../utils/getDefaultDate';
import { useAuth } from '../../contexts/authContext';

function UserBookingsPage() {
  const { bookings } = useAuth();

  const pastBookings = bookings.filter((booking) => {
    const bookingDate = parseISO(booking.start);
    return isBefore(
      bookingDate,
      getDefaultDate(),
    )
      && !isSameDay(bookingDate, getDefaultDate());
  });

  return (
    <Layout
      title="Bookings"
      pageLinks={[{
        label: 'Upcoming',
        link: '/user/bookings/upcoming',
      }, {
        label: 'Past',
        link: '/user/bookings/past',
      }]}
    >
      {pastBookings.length === 0
        ? <NoBookings title="You haven’t booked any workspaces...yet." image={NoPastBookings} /> : (
          <Bookings
            bookings={pastBookings}
            isUpcomingBookings={false}
          />
        )}
    </Layout>
  );
}

export default UserBookingsPage;
