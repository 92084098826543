import React from "react";
import PropTypes from "prop-types";
import { format, isSameDay, isValid, parseISO } from "date-fns";
import s from "./BookingBlock.module.scss";
import UsersList from "./UsersList";
import CancelBooking from "./CancelBooking";
import { useAuth } from "../../../contexts/authContext";
import { AiFillInfoCircle } from "react-icons/ai";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function BookingBlock({ data, isUpcomingBookings }) {
  const { companyDeskBookings, currentUser } = useAuth();
  const {
    image,
    title,
    address,
    start,
    confirmationPdfUrl = null,
    id,
    worksimplyResId = null,
    type = "desk",
    price = 0,
  } = data;

  const isDesk = type === "desk";

  const date = isValid(parseISO(start))
    ? format(parseISO(start), "MMMM d, yyyy")
    : "N/A";

  return (
    <div className="col-sm-6 col-lg-4 mb-5">
      <div className={s.booking_block}>
        <div className={s.image_wrapper}>
          <img src={image} alt="" />
        </div>
        <div className={s.details_wrapper}>
          {isDesk && isUpcomingBookings && (
            <UsersList
              list={companyDeskBookings.filter(
                (b) =>
                  b.userId !== currentUser.uid &&
                  isSameDay(parseISO(b.start), new Date()),
              )}
              data={data}
            />
          )}

          <div className={"d-flex align-items-center"}>
            <small className="d-inline-block my-2">{date}</small>
            <span className={"ms-auto "}>
              <OverlayTrigger
                placement={"top-end"}
                overlay={
                  <Tooltip id={id}>
                    {price} Credits = ${price}
                  </Tooltip>
                }
              >
                <span className={"d-flex align-items-center"}>
                  {price} Credits{" "}
                  <AiFillInfoCircle style={{ marginLeft: "5px" }} />
                </span>
              </OverlayTrigger>
            </span>
          </div>
          <h3 className="h5 my-0">{title || "N/A"}</h3>
          <p className="my-2">
            <small>{address}</small>
          </p>
          {confirmationPdfUrl && (
            <>
              {data?.isPending ? (
                <span rel="noreferrer" className="text-muted me-2">
                  <u>Pending Confirmation</u>
                </span>
              ) : (
                <a
                  href={confirmationPdfUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="text-green me-2"
                >
                  View Confirmation
                </a>
              )}
            </>
          )}
          {isUpcomingBookings && (
            <CancelBooking
              bookingId={id}
              start={start}
              worksimplyResId={worksimplyResId}
              type={type}
              booking={data}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default BookingBlock;

BookingBlock.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    start: PropTypes.string.isRequired,
    confirmationPdfUrl: PropTypes.string,
    worksimplyResId: PropTypes.string,
    type: PropTypes.string,
    isPending: PropTypes.bool,
    price: PropTypes.number,
  }).isRequired,
  isUpcomingBookings: PropTypes.bool,
};

BookingBlock.defaultProps = {
  isUpcomingBookings: true,
};
