import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';

const options = {
  hidePostalCode: true,
  style: {
    base: {
      border: '1px solid black',
      color: '#333333',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#333333',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

function CcInput() {
  return (
    <CardElement options={options} />
  );
}

export default CcInput;
