import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import get from "lodash/get";
import { addMinutes, differenceInHours, formatISO, parseISO } from "date-fns";
import { Button } from "react-bootstrap";
import cx from "classnames";
import { ImMap2 as MapIcon } from "react-icons/im";
import Spinner from "../../../../components/Spinner";
import { GoogleMap, Marker } from "../../../../components/GoogleMap";
import worksimplyApi from "../../../../services/worksimplyApi";
import useQueryParams from "../../../../utils/useQueryParams";
import { useAuth } from "../../../../contexts/authContext";
import getDefaultDate from "../../../../utils/getDefaultDate";
// import filterBookingsForTheLocation from "../../../../utils/FilterBookingsForTheLocation";
// import UsersList from "../UsersList";
// import getLatLngFromMeetingRoomLocation from "./getLatLngFromMeetingRoomLocation";
import getQueryParams from "../../../../utils/getQueryParams";
import convertDateTimeToUtc from "../../../../utils/convertDateTimeToUtc";
import getMeetingRoomLink from "./getMeetingRoomLink";
import { cities } from "../../../../layout/Header/CityFilter";
import formatCurrency from "../../../../utils/formatCurrency";
import PropTypes from "prop-types";
import s from "../index.module.scss";
import TypeBadge from "../TypeBadge";

const defaultMapCenter = { lat: 43.6508797, lng: -79.3834781 };

function MeetingRoomsSearch() {
  const { currentUserDoc } = useAuth();

  const lat = get(currentUserDoc, "lat", null);
  const lng = get(currentUserDoc, "lng", null);

  const [hoveredLocationId, setHoveredLocationId] = useState(null);

  const [showMapOnMobile, setShowMapOnMobile] = useState(false);

  const [center, setCenter] = useState(defaultMapCenter);

  useEffect(() => {
    if (!lat || !lng) {
      return;
    }
    setCenter({ lat: currentUserDoc.lat, lng: currentUserDoc.lng });
  }, [currentUserDoc]);

  const [list, setList] = useState([]);
  const [wsList, setWsList] = useState([]);

  const [filteredList, setFilteredList] = useState([]);

  const [inProgress, setInProgress] = useState(true);
  const [city] = useQueryParams("city", "Toronto");

  const [date] = useQueryParams("date", formatISO(getDefaultDate(1)));
  // const [endDate] = useQueryParams("endDate", "");
  const [isDaily] = useQueryParams("isDaily", "1");

  const [teamSize] = useQueryParams("teamSize", "1");
  const [category] = useQueryParams("category", "all");

  const handleSearch = async (searchParams) => {
    await worksimplyApi("worksimply-meeting-rooms/search", searchParams)
      .then((res) => {
        setWsList(res.meetingRooms);
        setFilteredList(res.meetingRooms);
        return true;
      })
      .catch(() => {
        return null;
      });
  };

  useEffect(() => {
    // fetch results
    setInProgress(true);

    let isActiveSession = true;

    setFilteredList([]);
    setList([]);
    setWsList([]);

    const params = getQueryParams();

    const start = params.get("date");
    const end = params.get("endDate");

    const fromDt = parseISO(start);
    const toDt = parseISO(end);

    const matchedCity = cities.find((c) => c.value === city);

    const tz = get(matchedCity, "tz", "America/Toronto") || "America/Toronto";

    let from = start
      ? convertDateTimeToUtc(addMinutes(fromDt, -15), tz).toISOString()
      : convertDateTimeToUtc(new Date(), tz).toISOString();
    let to = end
      ? convertDateTimeToUtc(addMinutes(toDt, 15), tz).toISOString()
      : null;

    const searchParams = {
      from,
      to,
      category,
      teamSize: params.get("teamSize") || 1,
      city: params.get("city") || "Toronto",
    };

    if (!isActiveSession) {
      return;
    }

    handleSearch(searchParams).then(() => {
      if (!isActiveSession) {
        return;
      }
      worksimplyApi("meeting-rooms/search-all", searchParams)
        .then((res) => {
          const allMeetingRooms = get(res, "meetingRooms", []).filter(
            (mr) => (mr?.name || "").toLowerCase().indexOf("office") === -1
          );

          const meetingRoomsInTheCity = allMeetingRooms.filter((mr) => {
            const { lat, lng, image } = mr;

            if (!image || !lat || !lng) {
              return false;
            }

            if (start) {
              if (Number(isDaily) === 1) {
                return !!get(mr, "rates.dailyRate.price", false);
              } else if (Number(isDaily) === 0) {
                return !!get(mr, "rates.hourlyRate.price", false);
              }
            }

            return lat && lng && image;
          });

          const formattedResults = meetingRoomsInTheCity.map((mr) => {
            const { lat, lng } = mr;
            return {
              ...mr,
              lat,
              lng,
            };
          });
          if (isActiveSession) {
            setList(formattedResults);
            setInProgress(false);
          }
        })
        .catch(() => {
          setInProgress(false);
        });
    });
    return () => {
      isActiveSession = "";
    };
  }, [date, teamSize, category]);

  useEffect(() => {
    const pickedCity = (city || "Toronto").toLowerCase();
    const filtered = [...wsList, ...list].filter(
      (mr) =>
        mr.capacity >= teamSize &&
        (get(mr, "location.city", "") || "").toLowerCase() === pickedCity
    );

    const firstItem = get(filtered, "[0]", {});
    if (firstItem?.lat && firstItem?.lng) {
      setCenter({ lat: Number(firstItem.lat), lng: Number(firstItem.lng) });
    } else {
      setCenter({
        lat: currentUserDoc?.lat || defaultMapCenter.lat,
        lng: currentUserDoc?.lng || defaultMapCenter?.lng,
      });
    }
    setFilteredList(
      filtered.sort(function (a, b) {
        if (
          !isDaily ||
          !a?.rates?.dailyRate?.price ||
          !b?.rates?.dailyRate?.price
        ) {
          return a?.rates?.hourlyRate?.price - b?.rates?.hourlyRate?.price;
        } else {
          return a?.rates?.dailyRate?.price - b?.rates?.dailyRate?.price;
        }
      })
    );
  }, [teamSize, city, list]);

  return (
    <>
      <div
        className={cx("col-md-7 col-lg-6 p-0 d-flex order-md-2", {
          "d-none d-md-block": !showMapOnMobile,
        })}
      >
        <div className={`w-100 sticky-top ${s.map_wrapper}`}>
          <GoogleMap center={center} className="something">
            {filteredList.map((mr) => (
              <Marker
                key={mr.meetingRoomId}
                lat={get(mr, "lat", "") || mr?.office_address?.lat}
                lng={get(mr, "lng", "") || mr?.office_address?.lng}
                isActive={hoveredLocationId === mr.meetingRoomId}
                id={mr.meetingRoomId}
                name={mr.name}
                link={getMeetingRoomLink(mr)}
              />
            ))}
          </GoogleMap>
        </div>
      </div>
      <div className="col-md-5 col-lg-6 p-0 order-md-1  results-list-container-fix-for-filters">
        {!inProgress && filteredList && filteredList.length === 0 && (
          <NoResultsMessage />
        )}

        {filteredList && filteredList.length > 0 && (
          <ul className="list-styled p-3">
            {filteredList.map((meetingRoom) => {
              return (
                <MeetingRoomBlock
                  key={meetingRoom.meetingRoomId}
                  meetingRoom={meetingRoom}
                  setHoveredLocationId={setHoveredLocationId}
                />
              );
            })}
          </ul>
        )}

        {inProgress && (
          <div className="position-relative py-5">
            <Spinner loadingText />
          </div>
        )}

        {!inProgress && filteredList && filteredList.length > 0 && (
          <div
            className={`position-sticky bottom-0 d-md-none text-center py-3 ${s.sticky_map_button}`}
          >
            <Button
              onClick={() => {
                setShowMapOnMobile(!showMapOnMobile);
                if (!showMapOnMobile) {
                  window.scroll(0, 0);
                }
              }}
              variant="default"
            >
              {showMapOnMobile ? "Hide" : "Map"}
              <MapIcon />
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

export default MeetingRoomsSearch;

function NoResultsMessage() {
  return (
    <div className="p-3">
      <p>No results available. Please change your filters and try again.</p>
    </div>
  );
}

const MeetingRoomBlock = ({
  meetingRoom = {},
  setHoveredLocationId = () => null,
}) => {
  if (!meetingRoom?.isWs) {
    return (
      <MeetingRoomRnd
        meetingRoom={meetingRoom}
        setHoveredLocationId={setHoveredLocationId}
      />
    );
  }
  return (
    <MeetingRoomWs
      meetingRoom={meetingRoom}
      setHoveredLocationId={setHoveredLocationId}
    />
  );
};

const MeetingRoomRnd = ({
  meetingRoom = {},
  setHoveredLocationId = () => null,
}) => {
  const [date] = useQueryParams("date", formatISO(getDefaultDate(1)));
  const [endDate] = useQueryParams("endDate", "");
  const [isDaily] = useQueryParams("isDaily", "1");

  const title = `${meetingRoom.name} at ${get(
    meetingRoom,
    "worksimplySoData.name",
    "N/A"
  )}`;

  const dailyRate = get(meetingRoom, "rates.dailyRate.price", null);
  const hourlyRate = get(meetingRoom, "rates.hourlyRate.price", null);

  let totalPrice = null;

  if (date && endDate) {
    if (Number(isDaily) === 1) {
      totalPrice = dailyRate;
    } else {
      const gap = differenceInHours(new Date(endDate), new Date(date));
      if (dailyRate && gap * hourlyRate >= dailyRate) {
        totalPrice = dailyRate;
      } else {
        totalPrice = gap * hourlyRate;
      }
    }
  }
  return (
    <li className={s.location_summary_block}>
      <Link
        to={getMeetingRoomLink(meetingRoom)}
        onMouseEnter={() => {
          if (window.innerWidth > 1200) {
            setHoveredLocationId(meetingRoom.meetingRoomId);
          }
        }}
        onMouseLeave={() => setHoveredLocationId(null)}
      >
        <div>
          <div className={"bg-light position-relative"}>
            <img
              src={get(
                meetingRoom,
                "image",
                "https://www.pulsecarshalton.co.uk/wp-content/uploads/2016/08/jk-placeholder-image.jpg"
              )}
              alt={meetingRoom.name}
            />
            <TypeBadge />
          </div>
        </div>
        <div>
          <span>{get(meetingRoom, "location.address", "")}</span>
          <h4 title={title} className={"my-1"}>
            {title}
          </h4>
          <span className={"mb-2 d-block"}>
            Up to {get(meetingRoom, "capacity", "2")} people
          </span>
        </div>
      </Link>
      <div className={`${s.price_block}`}>
        {totalPrice ? (
          <p className="m-0">{formatCurrency(totalPrice)} Credits</p>
        ) : (
          <p className="m-0">
            {hourlyRate
              ? `${formatCurrency(Math.ceil(hourlyRate))} credits /hour`
              : null}
            {dailyRate && hourlyRate ? <br /> : null}
            {dailyRate
              ? `${formatCurrency(Math.ceil(dailyRate))} credits /day`
              : null}
          </p>
        )}
      </div>
    </li>
  );
};

const MeetingRoomWs = ({
  meetingRoom = {},
  setHoveredLocationId = () => null,
}) => {
  const [date] = useQueryParams("date", formatISO(getDefaultDate(1)));
  const [endDate] = useQueryParams("endDate", "");
  const [isDaily] = useQueryParams("isDaily", "1");

  const title = `${meetingRoom.name} at ${get(
    meetingRoom,
    "location.name",
    "N/A"
  )}`;

  const dailyRate = get(meetingRoom, "price_per_day", null);
  const hourlyRate = get(meetingRoom, "price_per_hour", null);

  let totalPrice = null;

  if (date && endDate) {
    if (Number(isDaily) === 1) {
      totalPrice = dailyRate;
    } else {
      const gap = differenceInHours(new Date(endDate), new Date(date));
      if (dailyRate && gap * hourlyRate >= dailyRate) {
        totalPrice = dailyRate;
      } else {
        totalPrice = gap * hourlyRate;
      }
    }
  }
  return (
    <li className={s.location_summary_block}>
      <Link
        to={getMeetingRoomLink(meetingRoom)}
        onMouseEnter={() => {
          if (window.innerWidth > 1200) {
            setHoveredLocationId(meetingRoom.meetingRoomId);
          }
        }}
        onMouseLeave={() => setHoveredLocationId(null)}
      >
        <div>
          <div className={"bg-light position-relative"}>
            <img
              src={get(
                meetingRoom,
                "cover_image_url",
                "https://www.pulsecarshalton.co.uk/wp-content/uploads/2016/08/jk-placeholder-image.jpg"
              )}
              alt={meetingRoom.name}
            />
            <TypeBadge isRequestToBook />
          </div>
        </div>
        <div>
          <span>{get(meetingRoom, "location.address", "")}</span>
          <h4 title={title} className={"my-1"}>
            {title}
          </h4>
          <span className={"mb-2 d-block"}>
            Up to {get(meetingRoom, "capacity", "2")} people
          </span>
        </div>
      </Link>
      <div className={`${s.price_block}`}>
        {totalPrice ? (
          <p className="m-0">{formatCurrency(totalPrice)} Credits</p>
        ) : (
          <p className="m-0">
            {hourlyRate
              ? `${formatCurrency(Math.ceil(hourlyRate))} credits /hour`
              : null}
            {dailyRate && hourlyRate ? <br /> : null}
            {dailyRate
              ? `${formatCurrency(Math.ceil(dailyRate))} credits /day`
              : null}
          </p>
        )}
      </div>
    </li>
  );
};

MeetingRoomBlock.propTypes = {
  meetingRoom: PropTypes.shape({
    worksimplyLocationId: PropTypes.string,
  }),
  setHoveredLocationId: PropTypes.func.isRequired,
};

MeetingRoomRnd.propTypes = {
  meetingRoom: PropTypes.shape({
    worksimplyLocationId: PropTypes.string,
  }),
  setHoveredLocationId: PropTypes.func.isRequired,
};

MeetingRoomWs.propTypes = {
  meetingRoom: PropTypes.shape({
    id: PropTypes.string,
  }),
  setHoveredLocationId: PropTypes.func.isRequired,
};
