import React from 'react';
import Layout from '../../layout';

import { useAuth } from '../../contexts/authContext';
import Search from './Search';
import Admin from './Admin';

function HomePage() {
  const { claims } = useAuth();

  return (
    <Layout fullWidth={!claims.admin} noFooterTopMargin={!claims.admin} cityFilter={!claims.admin}>
      {claims.admin ? <Admin /> : <Search />}
    </Layout>
  );
}

export default HomePage;
