import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const getQuery = () => {
  if (typeof window !== 'undefined') {
    return new URLSearchParams(window.location.search);
  }
  return new URLSearchParams();
};

const getQueryStringVal = (key) => getQuery().get(key);

const useQueryParams = (key, defaultVal) => {
  const navigate = useNavigate();
  const [query, setQuery] = useState(getQueryStringVal(key) || defaultVal);

  const updateUrl = async (newVal) => {
    const formattedNewVal = newVal.toString();

    setQuery(formattedNewVal);

    const currentQuery = getQuery();

    if (formattedNewVal && formattedNewVal.trim() !== '') {
      currentQuery.set(key, formattedNewVal);
    } else {
      currentQuery.delete(key);
    }

    const { pathname } = window.location;
    const newUrl = `${pathname}?${currentQuery.toString()}`;
    await navigate(newUrl);
  };

  const locationString = window.location.search;
  // const locationString = typeof window === 'undefined' ? '' : window.location.search;

  useEffect(() => {
    const params = getQuery();
    if (params.get(key) && params.get(key) !== query) {
      setQuery(params.get(key));
    } else if (!params.get(key)) {
      setQuery(defaultVal);
    }
  }, [locationString]);

  return [query, updateUrl];
};

export default useQueryParams;
