import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import s from "./TeamSizeFilter.module.scss";
import useQueryParams from "../../utils/useQueryParams";
import PropTypes from "prop-types";

function TeamSizeFilter({ noDefaultTs, tsArray, defaultLabel }) {
  const [teamSize, setTeamSize] = useQueryParams(
    "teamSize",
    noDefaultTs ? "" : "1"
  );

  const [buttonLabel, setButtonLabel] = useState(defaultLabel);

  useEffect(() => {
    if (!teamSize) {
      setButtonLabel(defaultLabel);
      return;
    }
    if (Number(teamSize) === 1) {
      setButtonLabel(`1 person`);
      return;
    }
    setButtonLabel(`${teamSize} people`);
  }, [teamSize]);

  return (
    <Dropdown className={s.filter_wrapper}>
      <Dropdown.Toggle
        variant={!teamSize ? "default" : "primary"}
        id="dropdown-basic"
      >
        {buttonLabel}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <ul className={s.ts_array}>
          {tsArray.map((ts) => (
            <li key={ts}>
              <Dropdown.Item
                as="button"
                className={Number(teamSize) === ts ? s.active : ""}
                onClick={() => setTeamSize(ts)}
              >
                {ts}
              </Dropdown.Item>
            </li>
          ))}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default TeamSizeFilter;

TeamSizeFilter.propTypes = {
  noDefaultTs: PropTypes.bool,
  tsArray: PropTypes.arrayOf(PropTypes.number),
  defaultLabel: PropTypes.string,
};

TeamSizeFilter.defaultProps = {
  noDefaultTs: false,
  tsArray: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  defaultLabel: "Team Size",
};
