import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  format,
  formatISO,
  isValid,
  parseISO,
  setHours,
  setMinutes,
  setSeconds,
} from "date-fns";
import get from "lodash/get";
import Datepicker from "../components/Datepicker";
import TeamSizePicker from "../components/TeamSizePicker";
import ProgressButton from "../../../../../components/ProgressButton";
import worksimplyApi from "../../../../../services/worksimplyApi";
import useQueryParams from "../../../../../utils/useQueryParams";
import TentativeArrivalTime from "../components/TentativeArrivalTime";
import modifyHolidays from "../../../../../utils/modifyHolidays";

function OfficeBookingBlock({
  data,
  noButton,
  showReservation,
  readOnly,
  setOffice,
  office,
  showTentativeArrivalTime,
}) {
  const [inProgress, setInProgress] = useState(true);
  const [noMatches, setNoMatches] = useState(true);

  const [date] = useQueryParams(
    "date",
    formatISO(setMinutes(setHours(setSeconds(new Date(), 0), 0), 0))
  );

  const [teamSize] = useQueryParams("teamSize", "1");

  const search = () => {
    setOffice(null);
    setInProgress(true);
    setNoMatches(false);
    const params = {};
    if (Number(teamSize) > 0) {
      params.team_size = teamSize;
    }
    if (date && isValid(parseISO(date))) {
      params.start_date = format(parseISO(date), "yyyy-MM-dd");
      params.end_date = format(parseISO(date), "yyyy-MM-dd");
    }
    worksimplyApi("search/on-demand-spaces", {
      results_count: 1,
      location_id: data.id,
      params,
    })
      .then((res) => {
        const matchedOffice = get(res, "offices[0]", {});
        setOffice(matchedOffice);
        if (!matchedOffice.id) {
          setNoMatches(true);
        }
        setInProgress(false);
      })
      .catch(() => {
        setInProgress(false);
      });
  };

  useEffect(() => {
    search();
  }, []);

  useEffect(() => {
    setOffice(null);
  }, [date, teamSize]);

  const isOffice = get(office, "id", null);
  return (
    <div>
      {isOffice ? (
        <h4>{office.price_per_day} Credits/day</h4>
      ) : (
        <h4>Search Office</h4>
      )}

      <TeamSizePicker readOnly={readOnly} />
      <Datepicker
        readOnly={readOnly}
        isLast={!showTentativeArrivalTime}
        excludeDates={modifyHolidays(data?.customHolidays || [])}
      />
      {showTentativeArrivalTime && <TentativeArrivalTime data={data} />}

      {get(office, "id", null) && (
        <>
          <hr />
          <p className="d-flex">
            <span>{office.price_per_day} Credits x 1 Day</span>
            <span className="ms-auto">{office.price_per_day} Credits</span>
          </p>
          <hr />
          <p className="h5 d-flex">
            <span>Total</span>
            <span className="ms-auto">{office.price_per_day} Credits</span>
          </p>
          <br />
        </>
      )}
      {noMatches && !inProgress && (
        <p className="text-center">
          <small>
            No Results found. Please change the filters and try again.
          </small>
        </p>
      )}
      {!noButton && (
        <ProgressButton
          progress={inProgress}
          fullWidth
          onClick={get(office, "id", null) ? showReservation : search}
          buttonLabel={
            get(office, "id", null) ? "Reserve" : "Search Availability"
          }
        />
      )}
    </div>
  );
}

export default OfficeBookingBlock;

OfficeBookingBlock.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    customHolidays: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  showReservation: PropTypes.func,
  noButton: PropTypes.bool,
  readOnly: PropTypes.bool,
  setOffice: PropTypes.func,
  office: PropTypes.shape({
    id: PropTypes.string,
    price_per_day: PropTypes.number,
    price_per_hour: PropTypes.number,
  }),
  showTentativeArrivalTime: PropTypes.bool,
};

OfficeBookingBlock.defaultProps = {
  showReservation: false,
  noButton: false,
  readOnly: false,
  setOffice: () => null,
  office: {
    id: "",
    price_per_day: 0,
    price_per_hour: 0,
  },
  showTentativeArrivalTime: false,
};
