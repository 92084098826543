import React, { useEffect, useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import Layout from "../../layout";
import { updateCompany } from "../../services/firebase";
import ProgressButton from "../../components/ProgressButton";
import { useAuth } from "../../contexts/authContext";

function BudgetLimits() {
  const [progress, setProgress] = useState(false);

  const { company, fetchAndSetUsersCompany } = useAuth();

  const [totalCredits, setTotalCredits] = useState("");
  const [creditsPerEmployee, setCreditsPerEmployee] = useState("");

  useEffect(() => {
    if (!company) return;
    if (company.totalCredits) {
      setTotalCredits(company.totalCredits);
    }
    if (company.creditsPerEmployee) {
      setCreditsPerEmployee(company.creditsPerEmployee);
    }
  }, [company]);

  const save = async () => {
    if (!company || !company.id) return;
    setProgress(true);
    await updateCompany(company.id, { totalCredits, creditsPerEmployee });
    await fetchAndSetUsersCompany(company.id);
    toast("Updated", { type: "success" });
    setProgress(false);
  };
  return (
    <Layout
      title="Billing"
      pageLinks={[
        {
          label: "Payment Method",
          link: "/user/billing/payment-method",
        },
        {
          label: "Budget Limits",
          link: "/user/billing/budget-limits",
        },
        {
          label: "Invoices",
          link: "/user/billing/invoices",
        },
      ]}
      allowedRole="admin"
    >
      <div className="row">
        <div className="col-md-8 col-lg-6">
          <h5>Budget limit per employee per month</h5>
          <InputGroup className="mb-3">
            <InputGroup.Text id="per-employee">$</InputGroup.Text>
            <FormControl
              placeholder=""
              type="number"
              aria-label="per-employee"
              aria-describedby="per-employee"
              value={creditsPerEmployee}
              onChange={(e) => setCreditsPerEmployee(e.target.value)}
            />
          </InputGroup>
          <h5>Budget limit per month for the entire company</h5>
          <InputGroup className="mb-3">
            <InputGroup.Text id="per-month">$</InputGroup.Text>
            <FormControl
              placeholder=""
              type="number"
              aria-label="per-month"
              aria-describedby="per-month"
              value={totalCredits}
              onChange={(e) => setTotalCredits(e.target.value)}
            />
          </InputGroup>
          <ProgressButton
            buttonLabel="Save"
            progress={progress}
            onClick={save}
            disabled={!totalCredits || !creditsPerEmployee}
          />
        </div>
      </div>
    </Layout>
  );
}

export default BudgetLimits;
