import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import { format, formatISO, isValid, parseISO, setHours } from "date-fns";
import s from "./index.module.scss";
import useQueryParams from "../../utils/useQueryParams";
import getDefaultDate from "../../utils/getDefaultDate";
import { Button, ButtonGroup } from "react-bootstrap";
import MeetingRoomsTimePicker from "./MeetingRoomsTimePicker";
import cx from "classnames";
import { useNavigate } from "react-router-dom";

const defaultValue = "Start & End Time";

const isMobile = window.innerWidth < 992;

// eslint-disable-next-line react/display-name
const DateFilterButton = React.forwardRef(({ value, onClick }, ref) => {
  const [isDaily] = useQueryParams("isDaily", "1");
  const [date] = useQueryParams("date", "");
  const [endDate] = useQueryParams("endDate", "");

  const [label, setLabel] = useState(value || defaultValue);

  useEffect(() => {
    if (!date || !endDate) {
      setLabel(value || defaultValue);
      return;
    }
    if (Number(isDaily) === 1) {
      setLabel(format(parseISO(date), "MMMM d"));
      return;
    }

    if (date && endDate) {
      setLabel(
        `${format(parseISO(date), "MMMM d, hh:mm a")} - ${format(
          parseISO(endDate),
          "hh:mm a"
        )}`
      );
    }
  }, [date, endDate]);
  return (
    <button
      type="button"
      className={cx(s.filter_button, "btn btn-sm", {
        "btn-primary": value,
      })}
      onClick={onClick}
      ref={ref}
    >
      <strong>{label}</strong>
    </button>
  );
});

// eslint-disable-next-line react/display-name
const ProfileDateFilterButton = React.forwardRef(({ value, onClick }, ref) => {
  const [isDaily] = useQueryParams("isDaily", "1");
  const [date] = useQueryParams("date", "");
  const [endDate] = useQueryParams("endDate", "");

  const [label, setLabel] = useState(value || defaultValue);

  useEffect(() => {
    if (!date || !endDate) {
      setLabel(value || defaultValue);
      return;
    }
    if (Number(isDaily) === 1) {
      setLabel(format(parseISO(date), "MMMM d"));
      return;
    }

    if (date && endDate) {
      setLabel(
        `${format(parseISO(date), "MMMM d, hh:mm a")} - ${format(
          parseISO(endDate),
          "hh:mm a"
        )}`
      );
    }
  }, [date, endDate]);

  return (
    <button
      type="button"
      className={cx(s.meeting_room_profile_button, "btn", s.isLast)}
      onClick={onClick}
      ref={ref}
    >
      <small>Date</small>
      {label}
    </button>
  );
});

function DateFilterMeetingRooms({
  isProfile,
  readOnly,
  hasHourly,
  hasDaily,
  excludeDates,
}) {
  const datePickerRef = useRef();

  const [date] = useQueryParams("date", null);

  const [tempDate, setTempDate] = useState(null);

  const [daily, setIsDaily] = useQueryParams("isDaily", "1");

  const isDaily = Number(daily) === 1;

  useEffect(() => {
    if (hasHourly && !hasDaily) {
      setIsDaily("0");
    } else if (!hasHourly && hasDaily) {
      setIsDaily("1");
    }
  }, [hasDaily, hasHourly]);

  const [startTime, setStartTime] = useState(null);

  const navigate = useNavigate();

  const [fromStr] = useQueryParams("date", "");
  const [toStr] = useQueryParams("endDate", "");

  const [from, setFrom] = useState(fromStr);
  const [to, setTo] = useState(toStr);

  useEffect(() => {
    if (!from || !to) return;
    if (from === fromStr && to === toStr) return;
    datePickerRef.current.setOpen(false);
    const params = new URLSearchParams(window.location.search);
    params.set("date", from);
    params.set("endDate", to);
    navigate(`?${params.toString()}`);
  }, [to]);

  useEffect(() => {
    const td = tempDate ? formatISO(tempDate) : date;
    if (!td) return;
    if (!isValid(parseISO(td))) {
      return;
    }
    setStartTime(setHours(parseISO(td), 9));
    setFrom("");
    setTo("");
  }, [date, tempDate]);

  let placement = "bottom-start";

  if (isProfile) {
    placement = isMobile ? "bottom" : "left-start";
  }

  const handleDateChange = async (newDate, forceDaily = false) => {
    if (!newDate) {
      return;
    }
    if (isDaily || forceDaily) {
      const from = setHours(newDate, 9);
      const to = setHours(newDate, 17);
      const params = new URLSearchParams(window.location.search);
      params.set("date", formatISO(from));
      params.set("endDate", formatISO(to));
      navigate(`?${params.toString()}`);
      setTempDate(null);
      return;
    }
    setTempDate(newDate);
  };

  const handleCalendarClose = async () => {
    if (!tempDate) return;
    if (!from || !to) {
      setIsDaily("1");
      await handleDateChange(tempDate, true);
      setTempDate(null);
      return;
    }
  };

  // eslint-disable-next-line react/prop-types
  const MyContainer = ({ className, children }) => {
    return (
      <div className={className}>
        {hasHourly && hasDaily && (
          <div className="p-2">
            <ButtonGroup
              aria-label="Booking Type"
              className={"w-100 border border-primary"}
            >
              <Button
                variant={isDaily ? "primary" : "default"}
                size={"sm"}
                className={"w-50"}
                onClick={() => setIsDaily("1")}
              >
                <strong>Daily</strong>
              </Button>
              <Button
                variant={!isDaily ? "primary" : "default"}
                size={"sm"}
                className={"w-50"}
                onClick={() => setIsDaily("0")}
              >
                <strong>Hourly</strong>
              </Button>
            </ButtonGroup>
          </div>
        )}
        <div style={{ position: "relative" }}>{children}</div>
        {!isDaily && (
          <MeetingRoomsTimePicker
            from={from}
            setTo={setTo}
            setFrom={setFrom}
            to={to}
            startTime={startTime}
          />
        )}
      </div>
    );
  };

  return (
    <div
      className={cx({
        "d-inline-block my-3 py-1": !isProfile,
        [s.read_only_mr_filter]: readOnly,
        [s.read_only_mr_filter_with_opacity]: readOnly && !isProfile,
      })}
    >
      <DatePicker
        ref={datePickerRef}
        selected={tempDate || (date ? new Date(date) : null)}
        onChange={(newDate) => handleDateChange(newDate)}
        dateFormat="MMMM do"
        popperPlacement={placement}
        minDate={getDefaultDate(1)}
        onCalendarClose={handleCalendarClose}
        customInput={
          isProfile ? <ProfileDateFilterButton /> : <DateFilterButton />
        }
        excludeDates={excludeDates}
        calendarContainer={MyContainer}
        shouldCloseOnSelect={isDaily}
      />
    </div>
  );
}

export default DateFilterMeetingRooms;

DateFilterMeetingRooms.propTypes = {
  isProfile: PropTypes.bool,
  readOnly: PropTypes.bool,
  hasHourly: PropTypes.bool,
  hasDaily: PropTypes.bool,
  excludeDates: PropTypes.arrayOf(PropTypes.string),
};

DateFilterMeetingRooms.defaultProps = {
  isProfile: false,
  readOnly: false,
  hasHourly: false,
  hasDaily: true,
  excludeDates: [],
};

DateFilterButton.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
};

DateFilterButton.defaultProps = {
  value: "",
  onClick: () => null,
};

ProfileDateFilterButton.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
};

ProfileDateFilterButton.defaultProps = {
  value: "",
  onClick: () => null,
};
