import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { Button } from "react-bootstrap";
import { BsChevronLeft as BackIcon } from "react-icons/bs";
import get from "lodash/get";
import s from "./index.module.scss";
import useQueryParams from "../../../../utils/useQueryParams";
import ProgressButton from "../../../../components/ProgressButton";
import BookingBlock from "../SearchBlock";
import { useAuth } from "../../../../contexts/authContext";
import formatData from "./formatData";
import slackNotify from "../../../../services/slackNotify";

import { searchTypes } from "../../../HomePage/Search";
import { newDeskReservation } from "../../../../services/firebase";
import worksimplyApi from "../../../../services/worksimplyApi";
import getMeetingRoomReservationData from "./getMeetingRoomReservationData";
import sendNotificationToJaime from "./utils/sendNotificationToJaime";
import usePdfForMrIntegration from "./utils/usePdfForMrIntegration";
import sendNotificationToUser from "./utils/sendNotificationToUser";
import hasValidPaymentMethod from "../../../../utils/hasValidPaymentMethod";
import NoValidPaymentMethod from "../../../../components/ErrorMessages/NoValidPaymentMethod";

function ReservationPage({ data, back, mrData }) {
  const {
    company,
    currentUser,
    updateBookingsList,
    usedCredits,
    claims,
    currentUserDoc,
    mvpApi,
  } = useAuth();

  const [isWs] = useQueryParams("isWs", "0");
  const [date] = useQueryParams("date", "");
  const [endDate] = useQueryParams("endDate", "");
  const [type] = useQueryParams("type", "desk");
  const [isDaily] = useQueryParams("isDaily", "1");
  const [pdfUrl, setPdfUrl] = useState("");

  const [quantity, setQuantity] = useState(1);

  const isWorksimply = Number(isWs) === 1;

  const selectedSearchType = searchTypes.find((t) => type === t.slug);

  const [consent, setConsent] = useState(false);
  const [forSomeone] = useState(false);
  const [otherName] = useState("");
  const [progress, setProgress] = useState(false);
  const [success, setSuccess] = useState(false);

  let isBookable =
    get(company, "creditsPerEmployee", null) -
      usedCredits -
      data.price_per_day_pass >=
    0;

  const isInvalidOtherPerson = forSomeone ? !otherName : false;

  if (claims.role === "admin") {
    isBookable = true;
  }

  const handleReservation = async () => {
    if (!isBookable || !hasValidPaymentMethod(company)) return;
    // const parsedDate = isValid(parseISO(date)) ? parseISO(date) : new Date();
    // const selectedDate = setMinutes(setHours(setSeconds(parsedDate, 0), 0), 0);
    setProgress(true);

    const reqData = getMeetingRoomReservationData(
      {
        name: currentUser.displayName,
        email: currentUser.email,
        phone: currentUserDoc?.phoneNumber,
        uid: currentUser.uid,
        companyId: company.id,
      },
      data,
      mrData,
      quantity,
    );

    let res = {};

    if (isWorksimply) {
      // send to meeting room request to book endpoint
      res = await mvpApi("worksimply-meeting-room-bookings/create", reqData);
      await sendNotificationToJaime(res, company);
      await sendNotificationToUser(res, company);
    }

    if (!isWorksimply) {
      // create the PDF and get the URL
      const pdfUrl = await usePdfForMrIntegration(reqData, data, mvpApi);
      setPdfUrl(pdfUrl);
      res = await worksimplyApi("meeting-rooms/create-booking", {
        ...reqData,
        pdf_url: pdfUrl || null,
      });
    }

    const { reservation } = formatData(
      currentUser,
      currentUserDoc,
      company,
      data,
      res?.reservation?.reservationData?.id || res?.id || null,
      mrData,
      "",
      null,
      res?.reservation?.reservationData?.pdf_url || res?.pdf_url || null,
      isWorksimply,
      Math.ceil((res?.reservation?.reservationData?.price || 0) / 100),
    );

    const firebaseResId = await newDeskReservation(
      reservation,
      claims?.role === "user",
    );

    await worksimplyApi("meeting-rooms/update-booking-data", {
      id: res?.reservation?.reservationData?.id || res?.id || null,
      data: {
        ws_mvp_reservation_id: firebaseResId,
        ws_mvp_company_id: get(currentUserDoc, "companyId", null),
      },
    });

    const price = res?.total || res?.reservation?.reservationData?.total || 0;

    if (isWorksimply) {
      await slackNotify(
        `New request to book a meeting room from ${
          company.name
        } via app for $${(price / 100).toFixed(2)}`,
      );
    } else {
      await slackNotify(
        `New meeting room booking from ${company.name} via app for $${(
          price / 100
        ).toFixed(2)} `,
      );
    }

    setProgress(false);
    setSuccess(true);
    await updateBookingsList();
  };

  if (success) {
    return (
      <div className="container my-5">
        <div className="row">
          <div className="col-md-10 col-lg-9 col-xl-8 col-xxl-7">
            <h1>{isWorksimply ? "Booking requested" : "Booking confirmed!"}</h1>
            <p className="my-3">
              {isWorksimply
                ? "We still need confirm the availability of this booking with the" +
                  " Space Operator. You should receive an email within 1 business day.  If you" +
                  " have any questions please send us an email to hello@worksimply.com"
                : "You" +
                  " will receive a confirmation email with the details of your booking. If you have any questions please send us an email to hello@worksimply.com"}
            </p>
            {pdfUrl && (
              <a
                href={pdfUrl}
                target="_blank"
                className="btn btn-primary my-2 me-2"
                rel="noreferrer"
              >
                Download confirmation PDF
              </a>
            )}
            <Link
              to="/user/bookings/upcoming"
              className="btn btn-secondary my-2"
            >
              View my bookings
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <Button
        variant="default"
        className="p-0"
        onClick={back}
        style={{ marginLeft: "-8px" }}
      >
        <BackIcon size="30px" />
      </Button>

      <br />
      <br />

      <div className="row">
        <div className="col-lg-8 col-xl-7">
          <h1>{isWorksimply ? "Request to book" : "Confirm and book"}</h1>

          <div className="my-3 d-md-flex">
            {get(mrData, "image", false) && (
              <img
                className={s.thumbnail}
                src={get(mrData, "image", false)}
                alt=""
              />
            )}
            <div className="py-3">
              <h4>{get(mrData, "name", "")}</h4>
              <p>
                {get(mrData, "location.address", "") ||
                  data?.office_address?.address}
              </p>
            </div>
          </div>

          <hr />

          <ul className={s.summary_list}>
            <li>
              <span>Type</span>
              <span>{get(selectedSearchType, "label", "Day Passes")}</span>
            </li>
            <li>
              <span>Date</span>
              <span>
                {date
                  ? format(new Date(date), "MMMM d, yyyy")
                  : "Please select"}
              </span>
            </li>
            <li>
              <span>Check-in time</span>
              <span>
                {Number(isDaily) === 0
                  ? format(parseISO(date), "h:mm a")
                  : get(data, "so.opening_time", "9:00 AM")}
              </span>
            </li>
            <li>
              <span>Check-out time</span>
              <span>
                {Number(isDaily) === 0
                  ? format(parseISO(endDate), "h:mm a")
                  : get(data, "so.closing_time", "5:00 PM")}
              </span>
            </li>
          </ul>

          <hr />

          <div className="d-lg-none pt-4 pb-2">
            <BookingBlock
              noButton
              data={data}
              readOnly
              showTentativeArrivalTime
              isAvailable
              mrData={mrData}
              setQuantity={setQuantity}
            />
            <br />
          </div>

          <div className={s.cancellation_policy}>
            <h4>Cancellation policy</h4>
            <p>
              Cancel up to{" "}
              {get(data, "so.cancellation_deadline_in_hours", "48")} hours prior
              to the booking. You can cancel the bookings from your{" "}
              <Link to="/user/bookings/upcoming" target={"_blank"}>
                bookings
              </Link>{" "}
              page.
            </p>
          </div>

          <hr />

          <div className="form-check">
            <input
              type="checkbox"
              id="consent"
              className="form-check-input"
              checked={consent}
              onChange={() => setConsent(!consent)}
            />
            <label
              title="consent"
              htmlFor="consent"
              className="form-check-label"
            >
              By selecting the button below, I agree to the{" "}
              <a
                href="https://worksimply.com/terms-of-use"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Use
              </a>{" "}
              and Cancellation Policy.
            </label>
          </div>

          <br />

          {!isBookable && (
            <p className=" d-block mb-3 text-danger">
              You don&apos;t have enough credits for this booking.
            </p>
          )}

          <NoValidPaymentMethod />

          <ProgressButton
            buttonLabel={isWorksimply ? "Request to book" : "Confirm and book"}
            progress={progress}
            disabled={
              !consent ||
              !isBookable ||
              isInvalidOtherPerson ||
              !hasValidPaymentMethod(company)
            }
            onClick={handleReservation}
          />

          <br />
          <br />
          <br />
        </div>
        <div className="col-lg-4 offset-xl-1 d-none d-lg-block">
          <BookingBlock
            noButton
            data={data}
            readOnly
            showTentativeArrivalTime
            isAvailable
            mrData={mrData}
          />
        </div>
      </div>
    </div>
  );
}

export default ReservationPage;

ReservationPage.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    space_name: PropTypes.string.isRequired,
    hero_image_url: PropTypes.string.isRequired,
    space_description: PropTypes.string.isRequired,
    price_per_day_pass: PropTypes.number.isRequired,
    office_address: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
      address: PropTypes.string.isRequired,
    }),
    on_demand_instructions: PropTypes.string,
    so: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  back: PropTypes.func,
  office: PropTypes.shape({
    id: PropTypes.string,
    price_per_day: PropTypes.number,
    price_per_hour: PropTypes.number,
  }),
  isMeetingRoomCheckout: PropTypes.bool,
  mrData: PropTypes.shape({
    _id: PropTypes.string,
  }),
};

ReservationPage.defaultProps = {
  back: () => null,
  office: {
    id: "",
    price_per_day: 0,
    price_per_hour: 0,
  },
  isMeetingRoomCheckout: false,
};
