import axios from "axios";

const whitelistedEndpoints = [
  "search/day-passes",
  "search/on-demand-spaces",
  "view-sp",
  "list-space-operators",
  "meeting-rooms/search-all",
  "meeting-rooms/search",
  "meeting-rooms/get",
];

const worksimplyApi = (endpoint, data = {}) => {
  if (
    process.env.REACT_APP_STAGE === "demo" &&
    whitelistedEndpoints.indexOf(endpoint) === -1
  ) {
    const dummyPromise = new Promise((resolve, reject) => {
      resolve();
      reject();
    });

    return dummyPromise.then(() => {});
  }
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  return axios({
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/${endpoint}`,
    headers,
    data,
  }).then((r) => r.data);
};

export default worksimplyApi;
