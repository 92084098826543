import React, { useEffect, useState } from "react";
import Layout from "../../../layout";
import ProgressButton from "../../../components/ProgressButton";
import { useAuth } from "../../../contexts/authContext";
import { format } from "date-fns";

const TestAppNotifications = () => {
  const { mvpApi, currentUser } = useAuth();

  const [loading, setLoading] = useState(true);

  const [users, setUsers] = useState([]);
  const [hubs, setHubs] = useState([]);

  const [type, setType] = useState("");
  const [hub, setHub] = useState({});
  const [user, setUser] = useState({});

  const [progress, setProgress] = useState(false);

  let isDisabled = !user || !hub?.id;

  if (type === "wake-up-notification") {
    isDisabled = !user;
  }

  useEffect(() => {
    if (!currentUser?.uid) return;

    mvpApi("fetch-data-for-notifications-testing")
      .then((res) => {
        setHubs(res?.hubs ?? []);
        setUsers(res?.tokens ?? []);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentUser]);

  const sendNotification = async () => {
    setProgress(true);

    const notification = {
      to: user?.token,
      title: "",
      body: getNotification(type, user, hub).body,
      data: getNotification(type, user, hub).data,
      priority: "high",
    };

    await mvpApi("fetch-data-for-notifications-testing", {
      notification,
    });

    setProgress(false);
  };

  return (
    <Layout title={"Test App Notifications"} onlyAdmin loading={loading}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10 col-lg-8 col-xl-6">
            <div className="mb-3">
              <p className="mb-1">Type</p>
              <select
                id="type"
                name="type"
                className="form-select"
                value={type ?? ""}
                onChange={(e) => setType(e.target.value)}
              >
                <option value="">Please select</option>
                <option value="new-hub">New Hub Notification</option>
                <option value="upcoming-hub-booking-in-the-morning">
                  Booking Reminder in the morning
                </option>
                <option value="upcoming-hub-booking-tomorrow">
                  Booking Reminder on the day before
                </option>
                <option value="last-minute-hub-reminder">
                  Hub Reminder in the morning
                </option>
                <option value="wake-up-notification">Wakeup reminder</option>
              </select>
            </div>
            <div className="mb-3">
              <p className="mb-1">User</p>
              <select
                id="user"
                name="user"
                className="form-select"
                value={user?.id ?? ""}
                onChange={(e) => {
                  const user = users.find((u) => u.id === e.target.value);
                  setUser(user ?? {});
                }}
              >
                <option value="">Please select</option>
                {users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user?.user?.email ?? "N/A"} - {user.token}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <p className="mb-1">Hub</p>
              <select
                id="hub"
                name="hub"
                className="form-select"
                value={hub?.id ?? ""}
                onChange={(e) => {
                  const hub = hubs.find((h) => h.id === e.target.value);
                  setHub(hub ?? {});
                }}
              >
                <option value="">Please select</option>
                {hubs.map((hub) => (
                  <option key={hub.id} value={hub.id}>
                    {format(new Date(hub.startDateTime), "MMM d")} - {hub.title}
                  </option>
                ))}
              </select>
            </div>

            <ProgressButton
              buttonLabel={"Send Notification"}
              progress={progress}
              disabled={isDisabled}
              onClick={sendNotification}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TestAppNotifications;

const getNotification = (type, user, hub) => {
  let body = "";
  let data = {};

  switch (type) {
    case "new-hub":
      body = `Our next co-work meetup has been scheduled: ${
        hub?.title
      } on ${format(
        new Date(hub?.date),
        "EEEE MMMM d",
      )}. Book your work spot now!`;
      data = {
        type: "hub",
        locationId: hub?.spaceId,
        hubId: hub?.id,
        meetupDateFromNotification: new Date(hub?.startDateTime).toISOString(),
      };
      break;
    case "upcoming-hub-booking-in-the-morning":
      body = `Hi it’s time to check in soon at ${
        hub?.location?.space_name || ""
      }`;
      data = {
        type: "booking-reminder",
        wifiName: hub?.location?.restaurant_data?.wifiName || null,
        wifiPassword: hub?.location?.restaurant_data?.wifiPassword || null,
      };
      break;
    case "upcoming-hub-booking-tomorrow":
      body = `Hi this is a friendly reminder that you have an upcoming booking at ${
        hub?.location?.space_name || ""
      } tomorrow`;
      data = {
        type: "booking-reminder",
        wifiName: hub?.location?.restaurant_data?.wifiName || null,
        wifiPassword: hub?.location?.restaurant_data?.wifiPassword || null,
      };
      break;
    case "wake-up-notification":
      body = `Hi, imagine being able to work on your projects while enjoying delicious food and coffee. Book a work spot today!`;
      data = {
        type: "open-app",
      };
      break;
    case "last-minute-hub-reminder":
      body = `Last opportunity to attend today’s co-work meetup at ${hub?.location?.space_name}. Book your work spot now!`;
      data = {
        type: "hub",
        locationId: hub?.spaceId,
        hubId: hub?.id,
        meetupDateFromNotification: new Date(hub?.startDateTime).toISOString(),
      };
      break;
    default:
      body = "";
      data = {};
      break;
  }

  return { body, data };
};
