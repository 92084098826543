import { formatISO } from 'date-fns';
import useQueryParams from '../../../../../../utils/useQueryParams';
import { useAuth } from '../../../../../../contexts/authContext';

const useGenerateInvitationUrl = (selectedUsersList) => {
  const [date] = useQueryParams('date', '');

  const {
    currentUser,
  } = useAuth();

  if (!date) return '';

  const userData = {
    name: currentUser.displayName,
    email: currentUser.email,
    intendedFor: selectedUsersList.map((u) => u.uid),
  };

  const { pathname, origin } = window.location;
  const searchParams = new URLSearchParams();
  searchParams.set('date', formatISO(new Date(date)));
  searchParams.set('expressCheckout', '✓');
  searchParams.set('invitation', btoa(JSON.stringify(userData)));
  return `${origin}${pathname}?${searchParams.toString()}`;
};

export default useGenerateInvitationUrl;
