import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import get from "lodash/get";
import ErrorMessage from "./ErrorMessage";
import Images from "./Images";
import { GoogleMap, Marker } from "../../../components/GoogleMap";
import Amenities from "./Amenities";
import BookingBlock from "./BookingBlock";
import ReservationPage from "./ReservationPage";
import useQueryParams from "../../../utils/useQueryParams";
import ProfileDescription from "./ProfileDescription";
import GoogleReviews from "./GoogleReviews";

function Profile({ data, error }) {
  const [reservation, setReservation] = useState(false);

  const [expressCheckout] = useQueryParams("expressCheckout", "");
  const [type] = useQueryParams("type", "desk");

  const [resModal, setResModal] = useState(false);

  const handleModalClose = () => setResModal(false);

  const [office, setOffice] = useState({});

  useEffect(() => {
    if (reservation) window.scroll(0, 0);
  }, [reservation]);

  useEffect(() => {
    setReservation(expressCheckout && expressCheckout === "✓");
  }, [expressCheckout]);

  const handleBooking = () => {
    if (type === "desk") {
      setReservation(true);
    } else if (type === "office") {
      // display the modal
      setResModal(true);
    }
  };

  if (error) {
    return <ErrorMessage />;
  }
  if (reservation) {
    return (
      <ReservationPage
        data={data}
        back={() => setReservation(false)}
        office={office}
      />
    );
  }
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h1>{data.space_name}</h1>
            <br />
            <Button variant="primary" onClick={handleBooking}>
              {type === "desk" ? "Book a Desk" : "Book an Office"}
            </Button>

            <Images data={data} office={office} />
          </div>
        </div>
        <div className="row mt-5 pt-5">
          <div className="col-lg-8">
            <ProfileDescription data={data} />

            <div className="py-5" />

            <Amenities data={data} />

            <div className="py-5" />

            <GoogleReviews data={data} />

            <h2 className="mt-5">Location</h2>
            <p>{get(data, "office_address.address", null)}</p>
            <div
              className="position-relative w-100 mb-5"
              style={{ height: "350px" }}
            >
              {process.env.NODE_ENV === "production" && (
                <GoogleMap
                  center={{
                    lat: get(data, "office_address.lat", null),
                    lng: get(data, "office_address.lng", null),
                  }}
                >
                  <Marker
                    lat={get(data, "office_address.lat", null)}
                    lng={get(data, "office_address.lng", null)}
                  />
                </GoogleMap>
              )}
            </div>
            <div className="py-5" />
          </div>
          <div className="col-lg-4 d-none d-lg-block">
            <BookingBlock
              data={data}
              showReservation={() => setReservation(true)}
              office={office}
              setOffice={setOffice}
              withMessage
            />
          </div>
        </div>
      </div>

      <div className="position-sticky bottom-0 bg-white py-2 border-top">
        <div className="container">
          <div className="d-flex align-items-center">
            <div>
              {type === "desk" && (
                <p className="my-0">
                  <strong>{data.price_per_day_pass} Credits/day</strong>
                </p>
              )}
            </div>
            <Button
              variant="primary"
              className="ms-auto"
              onClick={handleBooking}
            >
              {type === "desk" ? "Book a Desk" : "Book an Office"}
            </Button>
          </div>
        </div>
      </div>
      <Modal show={resModal} onHide={handleModalClose} centered fullscreen>
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="container mt-5">
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6">
                <BookingBlock
                  data={data}
                  showReservation={() => setReservation(true)}
                  office={office}
                  setOffice={setOffice}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Profile;

Profile.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    space_name: PropTypes.string.isRequired,
    space_title: PropTypes.string.isRequired,
    price_per_day_pass: PropTypes.number.isRequired,
    space_description: PropTypes.string.isRequired,
    office_address: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
      address: PropTypes.string.isRequired,
    }),
    furnished: PropTypes.bool,
    pet_friendly: PropTypes.bool,
    full_kitchen_access: PropTypes.bool,
    coffee: PropTypes.bool,
    high_speed_internet: PropTypes.bool,
    printing: PropTypes.bool,
    mail_box: PropTypes.bool,
    common_areas: PropTypes.bool,
    breakout_rooms: PropTypes.number,
    meeting_rooms: PropTypes.number,
    phone_booths: PropTypes.number,
    ergonomic_chairs: PropTypes.bool,
  }).isRequired,
  error: PropTypes.bool.isRequired,
};
