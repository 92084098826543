import {
  addDoc, collection, deleteDoc, doc, getDocs, orderBy, query, serverTimestamp, where,
} from 'firebase/firestore';
import { subDays } from 'date-fns';
import { db } from './index';

const mainOfficeReservationsRef = collection(db, 'mainOfficeReservations');

export const createMainOfficeReservation = async (data) => addDoc(mainOfficeReservationsRef, {
  ...data,
  createdAt: serverTimestamp(),
  updatedAt: serverTimestamp(),
})
  .then(() => null).catch(() => null);

export const deleteMainOfficeReservation = async (reservationId) => deleteDoc(doc(db, 'mainOfficeReservations', reservationId)).then(() => true)
  .catch(() => true);

export const fetchMainOfficeReservations = async (companyId) => {
  const q = query(
    mainOfficeReservationsRef,
    orderBy('createdAt', 'asc'),
    where('createdAt', '>=', subDays(new Date(), 1)),
    where('companyId', '==', companyId),
  );

  return getDocs(q).then((snapshot) => {
    const data = [];
    snapshot.docs.forEach((snapDocument) => {
      data.push({ ...snapDocument.data(), id: snapDocument.id });
    });
    return data;
  });
};
