import React from 'react';
import PropTypes from 'prop-types';
import uniqBy from 'lodash/uniqBy';
import s from './UsersList.module.scss';

function UsersList({ list = [] }) {
  if (!list || list.length === 0) return null;

  const uniqueUsers = uniqBy(list, 'id');

  return (
    <div className={s.list}>
      <span>Teammates working here</span>
      <ul>
        {uniqueUsers.slice(0, 5).map((user) => <li key={user.id}>{user.image}</li>)}
      </ul>
    </div>
  );
}

export default UsersList;

UsersList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.node,
  })).isRequired,
};
