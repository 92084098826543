import React from "react";
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";

function DateViewToggle(props) {
  const { isActive, toggle } = props;
  return (
    <div className="ms-auto w-md-50 d-flex align-items-center">
      <label
        role="presentation"
        htmlFor="date-view-switch"
        className="pe-2 cursor-pointer"
        onClick={() => {
          toggle(isActive ? "" : "true");
        }}
      >
        Date View
      </label>
      <Form.Check
        type="switch"
        id="date-view-switch"
        name="date-view-switch"
        checked={isActive}
        onChange={(e) => {
          toggle(e.target.checked || "");
        }}
      />
    </div>
  );
}

export default DateViewToggle;

DateViewToggle.propTypes = {
  isActive: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};
