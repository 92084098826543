import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Layout from "../../layout";
import worksimplyApi from "../../services/worksimplyApi";
import get from "lodash/get";
import Profile from "./Profile";
import Spinner from "../../components/Spinner";
import isUUID from "validator/es/lib/isUUID";
import useQueryParams from "../../utils/useQueryParams";

function MeetingRoomProfilePage() {
  const { worksimplyLocationId, meetingRoomId, soId } = useParams();

  const [isWs] = useQueryParams("isWs", "0");

  const invalidProfile =
    !meetingRoomId || !soId || soId.length < 5 || meetingRoomId.length < 5;

  const invalidWorksimplyLocationId =
    !worksimplyLocationId ||
    worksimplyLocationId === "na" ||
    !isUUID(worksimplyLocationId, 4);

  const [mrData, setMrData] = useState({});
  const [profile, setProfile] = useState({});
  const [inProgress, setInProgress] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (invalidWorksimplyLocationId) {
      return;
    }
    worksimplyApi("view-sp", {
      id: worksimplyLocationId,
    })
      .then((res) => {
        setProfile(res.space);
      })
      .catch(() => {
        setError(true);
      });
  }, [worksimplyLocationId]);

  useEffect(() => {
    if (invalidProfile) {
      return;
    }
    // fetch meeting room data
    worksimplyApi("meeting-rooms/get", {
      id: meetingRoomId,
      soId,
      isWs: Number(isWs) === 1,
    })
      .then((res) => {
        // if (res?.data?.location) {
        //   setProfile(res.data.location);
        // }
        setMrData(get(res, "data", {}));
        setInProgress(false);
      })
      .catch(() => {
        setError(true);
        setInProgress(false);
      });
  }, [meetingRoomId]);

  if (invalidProfile || error) {
    return (
      <div className="container text-center mt-5">
        <h5>Invalid Profile</h5>
        <br />
        <Link to="/" className="btn btn-primary btn-sm">
          Back to home
        </Link>
      </div>
    );
  }

  return (
    <Layout title="" profilePage>
      {inProgress ? (
        <Spinner />
      ) : (
        <Profile data={profile} mrData={mrData} error={error} />
      )}
    </Layout>
  );
}

export default MeetingRoomProfilePage;
