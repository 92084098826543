import React from "react";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import { formatISO } from "date-fns";
import s from "./index.module.scss";
import useQueryParams from "../../utils/useQueryParams";
import getDefaultDate from "../../utils/getDefaultDate";

// eslint-disable-next-line react/display-name
const DateFilterButton = React.forwardRef(({ value, onClick }, ref) => (
  <button
    type="button"
    className={`btn btn-sm btn-primary ${s.filter_button}`}
    onClick={onClick}
    ref={ref}
  >
    <strong>{value}</strong>
  </button>
));

function DateFilter() {
  const [date, setDate] = useQueryParams("date", formatISO(getDefaultDate(1)));

  return (
    <div className="my-3 py-1 d-inline-block">
      <DatePicker
        selected={date ? new Date(date) : getDefaultDate(1)}
        onChange={(newDate) => setDate(formatISO(newDate))}
        dateFormat="MMMM do"
        minDate={getDefaultDate(1)}
        customInput={<DateFilterButton />}
      />
    </div>
  );
}

export default DateFilter;

DateFilterButton.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
};

DateFilterButton.defaultProps = {
  value: "",
  onClick: () => null,
};
