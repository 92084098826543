import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import get from "lodash/get";
import axios from "axios";
import { useAuth } from "../../contexts/authContext";
import { cities } from "../Header/CityFilter";
import ProgressButton from "../../components/ProgressButton";
import { updateCustomUserData } from "../../services/firebase";

function PostalCodeModal() {
  const { currentUserDoc, claims } = useAuth();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [checkingPostalCode, setCpc] = useState(false);
  const [postalCode, setPostalCode] = useState(
    get(currentUserDoc, "postalCode", "") || ""
  );
  const [city, setCity] = useState(get(currentUserDoc, "city", "") || "");
  const getDataForPostalCode = async () => {
    setCpc(true);
    const data = await axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&components=postal_code:${postalCode}`
      )
      .then((res) => {
        const lat = get(res, "data.results[0].geometry.location.lat", null);
        const lng = get(res, "data.results[0].geometry.location.lng", null);

        if (!lat || !lng) {
          return null;
        }
        return { lat, lng };
      })
      .catch(() => null);
    if (!get(data, "lat", null) || !get(data, "lng", null)) {
      return;
    }
    await updateCustomUserData(currentUserDoc.userId, {
      lat: data.lat,
      lng: data.lng,
      city,
      postalCode,
    });
    setCpc(false);
    handleClose();
    window.location.href = "/";
  };

  useEffect(() => {
    const { pathname } = window.location;
    if (
      !currentUserDoc ||
      !currentUserDoc.userId ||
      claims.admin ||
      pathname === "/user/account" ||
      pathname === "/slack-auth"
    )
      return;
    if (
      !get(currentUserDoc, "postalCode", "") ||
      !get(currentUserDoc, "city", "")
    ) {
      setShow(true);
    }
  }, [currentUserDoc]);
  return (
    <Modal show={show} onHide={handleClose} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>Update your location</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          The city and the postal code is used to display the locations that are
          closest to you.
        </p>
        <select
          className="form-select mb-3"
          value={city}
          onChange={(e) => setCity(e.target.value)}
        >
          <option value="" disabled>
            Please select
          </option>
          {cities.map(({ label, value }) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </select>
        <input
          type="text"
          className="form-control mb-4"
          placeholder="Postal code"
          value={postalCode}
          onChange={(e) => setPostalCode(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <ProgressButton
          buttonLabel="Save"
          onClick={getDataForPostalCode}
          progress={checkingPostalCode}
          disabled={!postalCode || !city}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default PostalCodeModal;
