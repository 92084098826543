import React, { useEffect, useState } from "react";
import Layout from "../layout";
import { useAuth } from "../contexts/authContext";
import Spinner from "../components/Spinner";

function AdminInvoices() {
  const { mvpApi } = useAuth();

  const [isLoading, setIsLoading] = useState(true);

  // const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    mvpApi("company/invoices")
      .then(() => {
        // setInvoices(res?.invoices || []);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Layout title="Invoices">
      {isLoading ? (
        <div className={"position-relative py-5"}>
          <Spinner />
        </div>
      ) : (
        <div>Loaded</div>
      )}
    </Layout>
  );
}

export default AdminInvoices;
