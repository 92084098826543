import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  getIdTokenResult,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updateEmail,
  updateProfile,
} from "firebase/auth";
import sumBy from "lodash/sumBy";
import get from "lodash/get";
import axios from "axios";
import { isSameMonth } from "date-fns";
import {
  auth,
  getCompanyDeskBookings,
  getCompanyDoc,
  getMyBookings,
  getMyPoints,
  getUserDoc,
  getUsersOfCompany,
} from "../services/firebase";
import Spinner from "../components/Spinner";
import { fetchMainOfficeReservations } from "../services/firebase/mainOfficeReservations";
import worksimplyApi from "../services/worksimplyApi";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState({});
  const [points, setPoints] = useState(0);
  const [redeemablePoints, setRedeemablePoints] = useState(0);
  const [mainOfficeReservations, setMainOfficeReservations] = useState([]);
  const [usedCredits, setUsedCredits] = useState(0);
  const [companyDeskBookings, setCompanyDeskBookings] = useState([]);
  const [searchCategories, setSearchCategories] = useState([]);
  const [companyDeskBookingsWithUser, setCompanyDeskBookingsWithUser] =
    useState([]);
  const [company, setCompany] = useState({});
  const [companyUsers, setCompanyUsers] = useState([]);
  const [currentUserDoc, setCurrentUserDoc] = useState({});
  const [claims, setClaims] = useState({});
  const [isReady, setIsReady] = useState(false);
  const [bookings, setBookings] = useState([]);

  const register = async (email, password) => {
    await createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setCurrentUser(userCredential.user || {});
        navigate("/");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        return { errorCode, errorMessage };
      });
  };

  const logout = async () => {
    await signOut(auth);
    window.location.href = "/login";
  };

  const login = async (email, password) =>
    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        setCurrentUser(userCredential.user);
        await getIdTokenResult(userCredential.user)
          .then((res) => setClaims(res.claims))
          .catch(() => null);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        return {
          errorCode,
          errorMessage,
        };
      });

  const mvpApi = (endpoint, data = {}) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    if (get(currentUser, "accessToken", null)) {
      headers.Authorization = `Bearer ${get(currentUser, "accessToken", null)}`;
    }

    return axios({
      method: "post",
      url: `${process.env.REACT_APP_MVP_API_URL}/${endpoint}`,
      headers,
      data,
    }).then((r) => r.data);
  };

  const resetPassword = async (email) =>
    sendPasswordResetEmail(auth, email)
      .then(() => true)
      .catch(() => false);

  const changeEmail = async (email) =>
    updateEmail(auth.currentUser, email)
      .then(() => true)
      .catch(() => null);

  const changeUserMetadata = async (displayName, phoneNumber) =>
    updateProfile(auth.currentUser, {
      displayName,
      phoneNumber,
    })
      .then(() => true)
      .catch(() => null);

  const updateCompanyDeskBookings = () => {
    getCompanyDeskBookings(company.id)
      .then((res) => {
        setCompanyDeskBookings(res);
      })
      .catch(() => null);
  };

  const updateBookingsList = (userId = currentUser.uid) => {
    if (!userId) return;
    getMyBookings(userId).then((res) => {
      setBookings(res);
      const thisMonthBookings = res.filter((b) =>
        isSameMonth(new Date(), new Date(b?.createdAt?.seconds * 1000)),
      );
      const total = sumBy(thisMonthBookings, (n) => Math.ceil(n.price || 0));
      setUsedCredits(total);
    });
    updateCompanyDeskBookings();

    // update the points for the user
    getMyPoints(userId).then((points) => {
      setRedeemablePoints(points.redeemablePoints);
      setPoints(points.allPoints);
    });
  };

  const updateMainOfficeReservations = async (companyId = company.id) => {
    await fetchMainOfficeReservations(companyId).then((res) =>
      setMainOfficeReservations(res),
    );
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        getIdTokenResult(user)
          .then((res) => {
            setClaims(res.claims);
            setCurrentUser(user);
            updateBookingsList(user.uid);
          })
          .catch(() => null);
      } else {
        setCurrentUser({});
        setIsReady(true);
      }
    });
  }, []);

  useEffect(() => {
    if (companyDeskBookings.length === 0) return;
    const u = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < companyDeskBookings.length; i++) {
      const reservation = companyDeskBookings[i];
      const user = companyUsers.find((usr) => usr.uid === reservation.userId);
      if (user) {
        u.push({
          ...reservation,
          userData: user,
        });
      }
    }
    setCompanyDeskBookingsWithUser(u);
  }, [companyDeskBookings, companyUsers]);

  useEffect(() => {
    worksimplyApi("categories")
      .then((res) => {
        setSearchCategories(res?.categories || []);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const fetchCompanyUsers = (companyId = company.id) => {
    if (!companyId) return;
    getUsersOfCompany(companyId).then((res) => {
      mvpApi("users/get").then((fbRes) => {
        const fbUsers = fbRes.users;
        const cu = fbUsers.filter((fbUser) => {
          const { uid } = fbUser || {};
          return res.find((userDoc) => userDoc.userId === uid);
        });
        setCompanyUsers(cu);
      });
    });
  };

  useEffect(() => {
    if (!company || !company.id) return;

    updateCompanyDeskBookings();
    fetchCompanyUsers(company.id);
    updateMainOfficeReservations(company.id);
    // getUsersOfCompany(company.id).then((res) => {
    //   mvpApi('users/get').then((fbRes) => {
    //     const fbUsers = fbRes.users;
    //     const cu = fbUsers.filter((fbUser) => {
    //       const { uid } = fbUser || {};
    //       return res.find((userDoc) => userDoc.userId === uid);
    //     });
    //     setCompanyUsers(cu);
    //   });
    // });
  }, [company]);

  useEffect(() => {
    if (!currentUser || !currentUser.uid) return;
    getUserDoc(currentUser.uid)
      .then((userDoc) => {
        setCurrentUserDoc(userDoc);
        setIsReady(true);
      })
      .catch(() => null);
  }, [currentUser]);

  const fetchAndSetUsersCompany = (companyId) => {
    getCompanyDoc(companyId)
      .then((companyDoc) => {
        if (process.env.NODE_ENV !== "development") {
          delete companyDoc.slackBotToken;
        }
        setCompany(companyDoc);
      })
      .catch(() => null);
  };

  useEffect(() => {
    if (!currentUserDoc || !currentUserDoc.companyId) return;
    fetchAndSetUsersCompany(currentUserDoc.companyId);
  }, [currentUserDoc]);

  const value = useMemo(
    () => ({
      claims,
      searchCategories,
      currentUser,
      currentUserDoc,
      company,
      bookings,
      companyDeskBookings,
      companyUsers,
      companyDeskBookingsWithUser,
      usedCredits,
      points,
      redeemablePoints,
      register,
      mainOfficeReservations,
      fetchCompanyUsers,
      updateCompanyDeskBookings,
      updateBookingsList,
      fetchAndSetUsersCompany,
      login,
      logout,
      resetPassword,
      changeEmail,
      changeUserMetadata,
      updateMainOfficeReservations,
      mvpApi,
    }),
    [
      currentUser,
      currentUserDoc,
      claims,
      company,
      bookings,
      companyDeskBookings,
      companyDeskBookingsWithUser,
      companyUsers,
      usedCredits,
      mainOfficeReservations,
      points,
    ],
  );

  return (
    <AuthContext.Provider value={value}>
      {isReady ? children : <LoadingState />}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function LoadingState() {
  return (
    <div className="mt-3 px-3 py-5">
      <div className="position-relative">
        <Spinner />
      </div>
    </div>
  );
}
