import { formatISO, isSameDay, parseISO } from 'date-fns';
import getDefaultDate from './getDefaultDate';
import getProfileImage from './getProfileImage';

const filterBookingsForTheLocation = (currentUser, companyDeskBookingsWithUser, locationId) => {
  const params = new URLSearchParams(window.location.search);

  const date = params.get('date') || formatISO(getDefaultDate(1));
  const bookings = companyDeskBookingsWithUser.filter((b) => {
    const isSameLocation = b.locationId === locationId;
    // const isSameUser = b.userId === currentUser.uid;
    const inDateRange = isSameDay(parseISO(date), parseISO(b.start));

    return isSameLocation && inDateRange;
  });

  return bookings.map(({ userData }) => ({
    name: `${userData.name}${currentUser.uid === userData.uid ? ' (Me)' : ''}`,
    id: userData.uid,
    email: userData.email,
    image: getProfileImage(userData),
  }));
};

export default filterBookingsForTheLocation;
