import get from "lodash/get";
import { format, isValid, parseISO } from "date-fns";

const formatData = (
  currentUser,
  currentUserDoc,
  company,
  data,
  reservationId = null,
  mrData = {},
  otherPersonName = "",
  invitation = {},
  pdfUrl = null,
  isPending = false,
  price = 0
) => {
  const params = new URLSearchParams(window.location.search);
  const instructions = get(data, "on_demand_instructions", "") || "";
  const date = params.get("date");
  const endDate = params.get("endDate");
  const type = params.get("type") || "desk";
  const teamSize = params.get("teamSize");

  const isDaily = params.get("isDaily");

  const arrivalTime =
    Number(isDaily) === 1
      ? get(data, "so.opening_time", "9:00 AM")
      : format(parseISO(date), "h:mm a");

  const parsedDate = isValid(parseISO(date)) ? parseISO(date) : new Date();
  const selectedDate = parsedDate;

  const reservation = {
    userId: currentUser.uid,
    companyId: company.id,
    locationId: get(data, "id", ""),
    start: date,
    end: endDate,
    price,
    type,
    image: mrData?.image || mrData?.cover_image_url,
    title: get(mrData, "name", ""),
    address:
      mrData?.location?.address ||
      mrData?.location?.office_address?.address ||
      "N/A",
    otherPersonName,
    arrivalTime,
    worksimplyResId: reservationId,
    isPending,
  };
  if (type === "meeting-room") {
    reservation.meetingRoomId = mrData._id || mrData?.id || "";
  }
  if (pdfUrl) {
    reservation.confirmationPdfUrl = pdfUrl;
  }
  const mvpApiData = {
    pdfData: {
      logo: company.logo,
      reservationId,
      list1: [
        { value: data.space_name },
        { value: get(data, "office_address.address", "") },
        { value: type === "desk" ? "Day Pass (Desk)" : "Office" },
        {
          value:
            !teamSize || Number(teamSize) === 1
              ? "1 person"
              : `${teamSize} people`,
        },
      ],
      list2: [
        {
          label: "Full name",
          value:
            otherPersonName ||
            get(currentUser, "displayName", "Not provided") ||
            "Not provided",
        },
        { label: "Email", value: get(currentUser, "email", "") },
        {
          label: "Phone number",
          value:
            get(currentUserDoc, "phoneNumber", "Not provided") ||
            "Not provided",
        },
        { label: "Date", value: format(selectedDate, "MMMM d, yyyy") },
        {
          label: "Arrival time",
          value: arrivalTime || get(data, "so.opening_time", "9:00 AM"),
        },
        {
          label: "Checkout time",
          value: get(data, "so.closing_time", "5:00 PM"),
        },
      ],
      instructions: instructions.replace(/(\r\n|\n|\r)/gm, "<br>"),
      hasInstructions: !!instructions,
    },
    reservationData: {
      id: reservationId,
      date: format(selectedDate, "MMMM d, yyyy"),
      phone:
        get(currentUserDoc, "phoneNumber", "Not provided") || "Not provided",
      name: get(currentUser, "displayName", "Not provided") || "Not provided",
      email: get(currentUser, "email", ""),
      total: price,
      otherPersonName,
      type,
    },
    userEmail: currentUser.email,
    location: data,
    office: {},
    price,
    invitation,
  };

  return { reservation, mvpApiData };
};

export default formatData;
