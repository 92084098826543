import React, { useState } from 'react';
import isEmail from 'validator/lib/isEmail';
import PropTypes from 'prop-types';
import ProgressButton from '../../components/ProgressButton';
import { useAuth } from '../../contexts/authContext';

function LoginForm({ setMessage = () => null }) {
  const { login } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [progress, setProgress] = useState(false);

  const handleSubmit = async () => {
    setProgress(true);
    setMessage('');
    const res = await login(email, password);
    if (res && res.errorCode) {
      setMessage({
        type: 'danger',
        text: 'Login failed. Please check your credentials and retry.',
      });
      setProgress(false);
    }
  };

  return (

    <form
      action="#"
      onSubmit={async (e) => {
        e.preventDefault();
        await handleSubmit();
      }}
    >
      <div className="mb-3">
        <input
          type="email"
          className="form-control"
          placeholder="Email"
          required
          onChange={(e) => {
            setEmail(e.target.value);
            setMessage('');
          }}
        />
      </div>
      <div className="mb-3">
        <input
          type="password"
          className="form-control"
          placeholder="Password"
          required
          onChange={(e) => {
            setPassword(e.target.value);
            setMessage('');
          }}
        />
      </div>
      <ProgressButton
        progress={progress}
        disabled={!email || !password || !isEmail(email)}
        buttonLabel="Sign In"
        onClick={handleSubmit}
        fullWidth
      />

    </form>
  );
}

export default LoginForm;

LoginForm.propTypes = {
  setMessage: PropTypes.func.isRequired,
};
