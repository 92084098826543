import React, { useState } from 'react';
import isEmail from 'validator/lib/isEmail';
import get from 'lodash/get';
import { toast } from 'react-toastify';
import Layout from '../../layout';
import { useAuth } from '../../contexts/authContext';
import { addUser } from '../../services/firebase';
import ProgressButton from '../../components/ProgressButton';
import { userTypes } from '../Admin/AddUser';
import UserTable from './Table';

function Members() {
  const {
    company, fetchCompanyUsers, mvpApi,
  } = useAuth();

  const [email, setEmail] = useState('');
  const [type, setType] = useState('');

  const [message, setMessage] = useState('');

  const [progress, setProgress] = useState(false);
  const [success, setSuccess] = useState(false);

  const isDisabled = !type || !email || !isEmail(email);

  const handleInvite = async () => {
    if (isDisabled) {
      return;
    }
    setMessage('');
    setProgress(true);
    setSuccess(false);
    const res = await mvpApi('users/add', { email, type });
    const newUserId = get(res, 'user.uid', null);
    if (newUserId) {
      await addUser(newUserId, {
        phoneNumber: '',
        companyId: company.id,
        userId: res.user.uid,
      });
    }
    await fetchCompanyUsers(company.id);
    if (!res.success) {
      setMessage(res.message);
    }
    setSuccess(res.success);
    setProgress(false);
    setEmail('');
    setType('');
    toast(`Sent invitation to ${email}`, { type: 'success' });
  };
  return (
    <Layout
      title="Manage Members"
      pageLinks={[{
        label: 'Accounts',
        link: '/user/members',
      }]}
      allowedRole="admin"
    >

      <div className="row">
        <div className="col-sm-4">
          <input
            type="text"
            className="form-control mb-3"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="col-sm-4">
          <select
            className="form-select mb-3"
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <option value="">User type</option>
            {userTypes.map((userType) => (
              <option
                value={userType.slug}
                key={userType.slug}
              >
                {userType.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-sm-12">
          <ProgressButton
            variant="primary"
            progress={progress}
            disabled={isDisabled}
            buttonLabel="Invite User"
            onClick={handleInvite}
          />
          {!success && message && <small className="text-danger ms-3">{message}</small>}
        </div>
      </div>

      <UserTable />

    </Layout>
  );
}

export default Members;
