import React from "react";
import Layout from "../../layout";
import { useAuth } from "../../contexts/authContext";

const slackProductionApp =
  "https://slack.com/oauth/v2/authorize?client_id=180122155616.4043360241207&scope=chat:write,im:read,im:write,users:read,users:read.email,users.profile:read,channels:read&user_scope=users.profile:read,users:read,users:read.email";

const slackDevApp =
  "https://slack.com/oauth/v2/authorize?client_id=180122155616.3995508365991&scope=chat:write,im:read,im:write,users:read,users:read.email,users.profile:read,channels:read&user_scope=users.profile:read,users:read,users:read.email";

function IntegrationsPage() {
  const { company } = useAuth();

  const handleClick = () => {
    if (process.env.REACT_APP_STAGE === "production") {
      window.open(slackProductionApp);
    } else {
      window.open(slackDevApp);
    }
  };

  return (
    <Layout
      title="Integrations"
      pageLinks={[
        {
          label: "Profile",
          link: "/user/account",
        },
        {
          label: "Integrations",
          link: "/user/integrations",
        },
      ]}
      allowedRole="admin"
    >
      {company?.slackTeamId && (
        <p>
          Slack is already installed to your workspace. Click the button again
          to re-install it.
        </p>
      )}
      <button onClick={handleClick} className={"border-0 p-0 bg-transparent"}>
        <img
          alt="Add to Slack"
          height="40"
          width="139"
          src="https://platform.slack-edge.com/img/add_to_slack.png"
          srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
        />
      </button>
    </Layout>
  );
}

export default IntegrationsPage;
