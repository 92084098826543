import React from "react";
import styles from "./MeetingRoomsSearch/index.module.scss";
import InstantBookingIcon from "../../../images/icons/instant-booking.svg";
import PropTypes from "prop-types";

const TypeBadge = ({ isRequestToBook }) => {
  if (isRequestToBook) {
    return <span className={styles.booking_type_badge}>Request to book</span>;
  }
  return (
    <span className={styles.booking_type_badge}>
      <img src={InstantBookingIcon} alt="Instant Booking" />
      Instant booking
    </span>
  );
};

export default TypeBadge;

TypeBadge.propTypes = {
  isRequestToBook: PropTypes.bool,
};

TypeBadge.defaultProps = {
  isRequestToBook: false,
};
