const formatCurrency = (price) => {
  if (!price) return "N/A";

  if (isNaN(Number(price))) {
    return "N/A";
  }

  const value = Number(price);

  if (value - Math.floor(value) !== 0) {
    return value.toFixed(2).replace(/(\d)(?=(\d{3})+\.\d\d$)/g, "$1,");
  }
  return value.toLocaleString();
};

export default formatCurrency;
