import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import get from 'lodash/get';
import s from './index.module.scss';
import useQueryParams from '../../../../../../utils/useQueryParams';
import getTimeSlots from '../../../../../../utils/getTimeSlots';

function TentativeArrivalTime({ readOnly, data }) {
  const [arrivalTime, setArrivalTime] = useQueryParams('arrivalTime', '');
  return (
    <div className={cx({ [s.time_picker_main_wrapper]: true, [s.readOnly]: readOnly })}>
      <small>Tentative Arrival Time</small>
      <select
        className={cx('form-select', s.select_input)}
        onChange={(e) => setArrivalTime(e.target.value)}
        value={arrivalTime}
      >
        {getTimeSlots(
          get(data, 'so.opening_time', '8:00 AM'),
          get(data, 'so.closing_time', '5:00 PM'),
          format(new Date(), 'yyyy-MM-dd'),
          30,
        ).slice(0, 10).map((time) => (
          <option value={format(time, 'hh:mm a')} key={time}>
            {format(time, 'hh:mm a')}
          </option>
        ))}
      </select>
    </div>
  );
}

export default TentativeArrivalTime;

TentativeArrivalTime.propTypes = {
  readOnly: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

TentativeArrivalTime.defaultProps = {
  readOnly: false,
};
