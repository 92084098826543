import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  // addRewardPointsToUser,
  // getAllCompanyDeskBookings,
  getAllRewardPoints,
  getCompanies,
  getUsers,
} from "../../services/firebase";
import { useAuth } from "../../contexts/authContext";
// import { rewardPointsRate } from "../../config";

function Admin() {
  const { mvpApi } = useAuth();
  const [companies, setCompanies] = useState([]);
  const [dbUsers, setDbUsers] = useState([]);
  const [firebaseUsers, setFirebaseUsers] = useState([]);

  const [allPoints, setAllPoints] = useState([]);

  // const [allBookings, setAllBookings] = useState([]);
  useEffect(() => {
    getCompanies().then((res) => {
      setCompanies(res);
    });
    getUsers().then((res) => {
      setDbUsers(res);
    });

    // getAllCompanyDeskBookings().then((res) => {
    //   setAllBookings(res);
    // });

    getAllRewardPoints().then((res) => {
      setAllPoints(res);
    });

    mvpApi("users/get").then((res) => {
      setFirebaseUsers(res.users);
    });
  }, []);

  // const addPoints = () => {
  //   allBookings.forEach(
  //     ({ userId, id: reservationId, price, start: effectiveDate }) => {
  //       addRewardPointsToUser(
  //         userId,
  //         reservationId,
  //         price * rewardPointsRate,
  //         effectiveDate
  //       );
  //     }
  //   );
  // };

  if (companies.length === 0) {
    return <p className="text-center">Loading...</p>;
  }

  return (
    <div>
      <Accordion>
        {companies.map((company) => {
          const usersFromDb = dbUsers.filter(
            (user) => user.companyId === company.id
          );

          const matchingUsersEmails = firebaseUsers.filter((fbUser) => {
            const fbUserId = fbUser.uid;
            return usersFromDb.find(({ userId }) => userId === fbUserId);
          });

          return (
            <Accordion.Item eventKey={company.id} key={company.id}>
              <Accordion.Header>
                {company.name} ({usersFromDb.length} user
                {usersFromDb.length === 1 ? "" : "s"})
                <Link
                  to={`/admin/companies/edit/${company.id}`}
                  className="btn btn-primary btn-sm ms-3"
                >
                  Edit
                </Link>
              </Accordion.Header>
              <Accordion.Body>
                <ul className="m-0">
                  {matchingUsersEmails.map(({ email, role, uid }) => {
                    let points = 0;
                    allPoints
                      .filter((res) => res.userId === uid)
                      .map((item) => {
                        points += Number(item.points || 0);
                      });
                    return (
                      <li key={email}>
                        {email} ({role}){" "}
                        {role === "user" && (
                          <Link to={`/admin/user-rewards/${uid}`}>
                            Manage Rewards ({points})
                          </Link>
                        )}{" "}
                        {uid}
                      </li>
                    );
                  })}
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>

      {/*<br />*/}
      {/*<button*/}
      {/*  onClick={addPoints}*/}
      {/*  disabled={allBookings.length === 0}*/}
      {/*  className={"btn btn-primary"}*/}
      {/*>*/}
      {/*  Add Rewards*/}
      {/*</button>*/}
    </div>
  );
}

export default Admin;
