import React from 'react';
import { isAfter, isSameDay, parseISO } from 'date-fns';
import Layout from '../../layout';
import NoBookings from './NoBookings';
import Bookings from './Bookings';
import { useAuth } from '../../contexts/authContext';
import getDefaultDate from '../../utils/getDefaultDate';

function UserBookingsPage() {
  const { bookings } = useAuth();

  const upcomingBookings = bookings.filter((booking) => {
    const bookingDate = parseISO(booking.end);
    return isAfter(bookingDate, getDefaultDate())
      || isSameDay(bookingDate, getDefaultDate());
  });

  return (
    <Layout
      title="Bookings"
      pageLinks={[{
        label: 'Upcoming',
        link: '/user/bookings/upcoming',
      }, {
        label: 'Past',
        link: '/user/bookings/past',
      }]}
    >

      {upcomingBookings.length === 0 ? <NoBookings title="You don’t have any upcoming bookings." /> : (
        <Bookings
          bookings={upcomingBookings}
          isUpcomingBookings
        />
      )}
    </Layout>
  );
}

export default UserBookingsPage;
