import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { format, subMonths } from "date-fns";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import get from "lodash/get";
import TextInput from "../../../components/TextInput";
import { updateMonthlyData } from "../../../services/firebase";
import ProgressButton from "../../../components/ProgressButton";
import { useAuth } from "../../../contexts/authContext";

function ManualData({ data, refresh }) {
  const { mvpApi } = useAuth();
  const [month, setMonth] = useState(format(new Date(), "yyyy_MM"));
  const [show, setShow] = useState(false);
  const [progress, setProgress] = useState(false);

  const [updateMonth, setUpdateMonth] = useState("0");

  const [mrr, setMrr] = useState("");
  const [forecastedMrr, setForecastedMrr] = useState("");
  const [monthlyRevenue, setMonthlyRevenue] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const save = async () => {
    setProgress(true);
    await updateMonthlyData(month, { mrr, forecastedMrr, monthlyRevenue });
    refresh();
    toast.success("Data updated");
    setProgress(false);
    handleClose();
  };

  const handleUpdate = async () => {
    setProgress(true);
    await mvpApi("data-room", {
      saveToDb: true,
      month: Number(updateMonth) + 1,
    });
    window.location.reload();
  };

  const updateCurrentMonthData = async () => {
    setProgress(true);
    await mvpApi("data-room", { saveToDb: true, month: 0 });
    window.location.reload();
  };

  const currentMonthData = data.find((d) => d.id === month);

  useEffect(() => {
    setMrr(get(currentMonthData, "mrr", ""));
    setForecastedMrr(get(currentMonthData, "forecastedMrr", ""));
    setMonthlyRevenue(get(currentMonthData, "newSpaceOperators", ""));
  }, [currentMonthData]);
  return (
    <>
      <div className="d-flex">
        <Button onClick={handleShow}>Add Data</Button>
        <div className="ms-auto d-flex">
          <select
            name=""
            id=""
            className="form-select me-2"
            value={updateMonth}
            onChange={(e) => setUpdateMonth(e.target.value)}
          >
            {[0, 1, 2].map((number) => (
              <option value={number} key={number}>
                {format(subMonths(new Date(), number + 1), "MMMM")}
              </option>
            ))}
          </select>
          <Button
            onClick={handleUpdate}
            variant="secondary"
            disabled={progress}
            className="ms-auto"
          >
            Update Summary
          </Button>
        </div>
        <Button
          onClick={updateCurrentMonthData}
          variant="secondary"
          disabled={progress}
          className="ms-auto"
        >
          Update Current Month
        </Button>
      </div>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Add Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-12">
              <select
                value={month}
                onChange={(e) => setMonth(e.target.value)}
                className="form-select mb-3"
              >
                <option value={format(new Date(), "yyyy_MM")}>
                  {format(new Date(), "MMMM")}
                </option>
                <option value={format(subMonths(new Date(), 1), "yyyy_MM")}>
                  {format(subMonths(new Date(), 1), "MMMM")}
                </option>
                <option value={format(subMonths(new Date(), 2), "yyyy_MM")}>
                  {format(subMonths(new Date(), 2), "MMMM")}
                </option>
                <option value={format(subMonths(new Date(), 3), "yyyy_MM")}>
                  {format(subMonths(new Date(), 3), "MMMM")}
                </option>
                <option value={format(subMonths(new Date(), 4), "yyyy_MM")}>
                  {format(subMonths(new Date(), 4), "MMMM")}
                </option>
              </select>

              <TextInput value={mrr} setValue={setMrr} label="MRR" />
              <TextInput
                value={forecastedMrr}
                setValue={setForecastedMrr}
                label="Forecasted MRR"
              />
              <TextInput
                value={monthlyRevenue}
                setValue={setMonthlyRevenue}
                label="Monthly Revenue"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={handleClose} className="me-auto">
            Close
          </Button>
          <ProgressButton
            onClick={save}
            buttonLabel="Save"
            disabled={!mrr && !forecastedMrr && !monthlyRevenue}
            progress={progress}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ManualData;

ManualData.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ).isRequired,
  refresh: PropTypes.func.isRequired,
};
