import get from "lodash/get";
import getQueryParams from "../../../../utils/getQueryParams";

const getMeetingRoomLink = (meetingRoom = {}) => {
  if (!meetingRoom || !meetingRoom.meetingRoomId) return "/";

  const params = getQueryParams();

  const from = params.get("date");
  const to = params.get("endDate");
  const type = params.get("type");
  const teamSize = params.get("teamSize");
  const isDaily = params.get("isDaily");

  let link = `/meeting-room/${meetingRoom.meetingRoomId}/${get(
    meetingRoom,
    "worksimplySoData.soId",
    "na"
  )}/${get(meetingRoom, "worksimplyLocationId", "na")}`;

  const newParams = new URLSearchParams();

  newParams.set("isDaily", isDaily || 1);

  if (meetingRoom.isWs) {
    newParams.set("isWs", "1");
  }

  if (type) {
    newParams.set("type", type);
  }
  if (teamSize) {
    newParams.set("teamSize", teamSize);
  }
  if (from) {
    newParams.set("date", from);
  }
  if (to) {
    newParams.set("endDate", to);
  }

  return link + "?" + newParams.toString();
};

export default getMeetingRoomLink;
