import React from "react";
import nl2br from "../../../utils/nl2br";
import PropTypes from "prop-types";

const ProfileDescription = ({ data }) => {
  return (
    <div className="row">
      <div className="col-lg-11">
        <h2 className="mb-4">{data.space_title}</h2>
        <p>{nl2br(data.space_description)}</p>
      </div>
    </div>
  );
};

export default ProfileDescription;

ProfileDescription.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    space_title: PropTypes.string.isRequired,
    space_description: PropTypes.string.isRequired,
  }).isRequired,
};
