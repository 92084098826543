import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { isBefore, isValid, parseISO, subDays } from "date-fns";
import ProgressButton from "../../../components/ProgressButton";
import { cancelDeskBooking } from "../../../services/firebase";
import { useAuth } from "../../../contexts/authContext";
import worksimplyApi from "../../../services/worksimplyApi";
import sendNotificationEmailForWorksimplyBooking from "./utils/sendNotificationEmailForWorksimplyBooking";

function CancelBooking({
  bookingId = "",
  start = "",
  worksimplyResId = null,
  type = null,
  booking = {},
}) {
  const { updateBookingsList, currentUser } = useAuth();

  const bookingDate = isValid(parseISO(start)) ? parseISO(start) : new Date();

  const [show, setShow] = useState(false);

  const [progress, setProgress] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const cancel = async () => {
    if (!bookingId || false) return;
    setProgress(true);
    if (worksimplyResId) {
      await worksimplyApi("cancel-reservation", {
        id: worksimplyResId,
        type,
        note: `Cancelled by user - ${currentUser.email || "N/A"}`,
      });
    }
    await sendNotificationEmailForWorksimplyBooking(booking);
    await cancelDeskBooking(bookingId);
    await updateBookingsList();
    setProgress(true);
    handleClose();
  };

  if (!start || isBefore(subDays(bookingDate, 2), new Date())) return null;
  return (
    <>
      <a
        href="#cancel-booking"
        className="text-green"
        onClick={(e) => {
          e.preventDefault();
          handleShow();
        }}
      >
        Cancel booking
      </a>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
        fullscreen={window.innerWidth < 768}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <h5 className="mb-4">
            Are you sure you want to cancel this booking?
          </h5>

          <ProgressButton
            buttonLabel="Yes, cancel"
            progress={progress}
            onClick={cancel}
            className="mb-2"
          />

          <Button
            variant="secondary"
            className="ms-2 mb-2"
            onClick={handleClose}
          >
            No, never mind
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CancelBooking;

CancelBooking.propTypes = {
  bookingId: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
  worksimplyResId: PropTypes.string,
  type: PropTypes.string,
  booking: PropTypes.shape({
    meetingRoomId: PropTypes.string,
  }),
};

CancelBooking.defaultProps = {
  worksimplyResId: null,
  type: "desk",
};
